/**Code added by Unnati on 05-10-2024
 * Reason-To have a sale products page
 */
import React, { useState, useContext, useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import SaleProductStyle from "./SaleProduct.module.css";
import NavigationPath from "../../components/NavigationPath/NavigationPath";
import { GlobalContext } from "../../context/Context";
import config from "../../Api/config";
import { FaLongArrowAltDown, FaLongArrowAltUp } from "react-icons/fa";
import { addToWishListAPI, getSaleProducts, getSortedProductsAPI, removeProductFromWishListAPI } from "../../Api/services";
import { calculateDiscountFromProduct } from "../../utils/discountedPrices";
import ReactStars from "react-stars";
import Rating from "../../components/Rating/Rating";
import notificationObject from "../../components/Widgets/Notification/Notification";
import { MdFavorite, MdFavoriteBorder } from "react-icons/md";
const SaleProduct = () => {
  const location = useLocation();
  const id = location.state;
  const [viewType, setViewType] = useState("grid");
  const [saleProduct, setSaleProduct] = useState([]);
  const [sortOrder, setSortOrder] = useState("default");
  {
    /*
     *Code added by Unnati on 05-10-2024
     * Reason-Added navigation link path
     */
  }
  const { navigationPath, setNavigationPath,
    // Added by - Ashlekh on 18-11-2024
    // Reason - To import variables from context
    selectedColors,
    setSelectedColors,
    selectedBrands,
    setSelectedBrands,
    priceRange,
    setPriceRange,
    minPrice,
    maxPrice,
    setMinPrice,
    setMaxPrice,
    // End of code - Ashlekh on 18-11-2024
    // Reason - To import variables from context
    // Added by - Ashlekh on 27-11-2024
    // Reason - To import wishlist & user from context
    user,
    setUser,
    wishListData,
    setWishListData,
    // End of code - Ashlekh on 27-11-2024
    // Reason - To import wishlist & user from context
   } = useContext(GlobalContext);
  const [listPerPage, setListPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  // Added by - Ashlekh on 27-11-2024
  // Reason - useState for wishlist
  const [wishlistStatus, setWishlistStatus] = useState({});
  const navigate = useNavigate();
  // End of code - Ashlekh on 27-11-2024
  // Reason - useState for wishlist
  useEffect(() => {
    window.scrollTo(0, 0);
    setNavigationPath([
      { name: "Home", path: "/" },
      { name: "SaleProduct", path: "/saleproducts" },
    ]);
  }, []);
  /**Code added by Unnati on 05-10-2024
   * Reason-To scroll to the top
   */
  const scrollDoc = () => {
    window.scrollTo(0, 0);
  };
  /**End of code addition by Unnati on 05-10-2024
   * Reason-To scroll to the top
   */
  {
    /**End of code addition by Unnati on 05-10-2024
     * Reason-Added navigation link path
     */
  }

  /**Code added by Unnati Bajaj on 05-10-2024
   * Reason -To handle sort change
   */
  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
  };
  const getIcon = () => {
    switch (sortOrder) {
      case "lowToHigh":
        return <FaLongArrowAltUp />;
      case "highToLow":
        return <FaLongArrowAltDown />;
      default:
        return null;
    }
  };
  /**End of code addition by Unnati Bajaj on 05-10-2024
   * Reason -To handle sort change
   */
  /**Code added by Unnati on 05-10-2024
   * Reason-To get saleProducts when components loads
   */
  useEffect(() => {
    const fetchSaleProducts = async () => {
      try {
        /**Code added by Unnati on 05-12-2024
         * Reason-Added sortorder
         */
        const response = await getSaleProducts(id,sortOrder);
         /**End of code additon by Unnati on 05-12-2024
         * Reason-Added sortorder
         */
        setSaleProduct(response.sale_product);
      } catch (error) {
        console.error("Error fetching product:", error.message);
      }
    };

    fetchSaleProducts();
     /**Code added by Unnati on 05-12-2024
         * Reason-Added sortorder
         */
  }, [id,sortOrder]);
   /**End of code additon by Unnati on 05-12-2024
         * Reason-Added sortorder
         */
  /**End of code addition by Unnati on 05-10-2024
   * Reason-To get saleProducts when components loads
   */
  /**
   * Added by - Ashlekh on 18-11-2024
   * Reason - For applying sorting
   */
  useEffect(() => {
    let sort_by = "default";
    let sort_order = "asc";

    if (sortOrder === "lowToHigh") {
      sort_by = "sales_rate";
      sort_order = "asc";
    } else if (sortOrder === "highToLow") {
      sort_by = "sales_rate";
      sort_order = "desc";
    }
  }, [sortOrder]);
  /**
   * End of code - Ashlekh on 18-11-2024
   * Reason - For applying sorting
   */

  /**
   * Added by - Ashlekh on 18-11-2024
   * Reason - API for sorting products
   */
   /**code comment by Unnati on 05-12-2024
         * Reason-Not in use
         */
  // useEffect(() => {
  //   const fetchSortedProduct = async() => {
  //     try{
  //       const response = await getSortedProductsAPI(
  //         id, // This is banner id
  //         sortOrder,
  //         currentPage,
  //         listPerPage,
  //       );
  //       setSaleProduct(response.product);
  //     } catch(error) {
  //       console.error("Error in fetching product:", error.message);
  //     }
  //   };
  //   fetchSortedProduct();
  // }, [
  //   sortOrder,
  //   currentPage,
  //   listPerPage,
  // ]);
    /*End of code comment by Unnati on 05-12-2024
         * Reason-Not in use
         */
  /**
   * End of code - Ashlekh on 18-11-2024
   * Reason - API for sorting products
   */

  /**
   * Added by - Ashlekh on 27-11-2024
   * Reason - To add/remove products in wishlist
   */
  useEffect(() => {
    const newWishlistStatus = {};
    wishListData?.forEach((item) => {
      const key = `${item.product_id}-${item.color}`;
      newWishlistStatus[key] = true; 
    });
    setWishlistStatus(newWishlistStatus);
  }, [wishListData]);
  const handleToggleWishList = async (user, product) => {

    if (user.id == null || user.id == undefined) {
      navigate("/login");
      return;
    } else {
      const productKey = `${product.product_id}-${product.color}`;
      try {
        if (wishlistStatus[productKey]) {
          const response = await removeProductFromWishListAPI(
            user.id,
            product?.product_id,
            product?.color
          );
          localStorage.setItem(
            "wishListData",
            JSON.stringify(response.wishlist)
          );
          setWishListData(response.wishlist);
          if (response?.message) {
            setWishlistStatus((prev) => ({
              ...prev,
              [productKey]: false,
            }));
            notificationObject.success(
              "Product successfully removed from wishlist"
            );
          }
        } else {
          const response = await addToWishListAPI(
            user.id,
            product?.product_id,
            product?.color
          );
          localStorage.setItem(
            "wishListData",
            JSON.stringify(response?.wishlist_data)
          );
          setWishListData(response?.wishlist_data);
          if (response?.message == "Success") {
            setWishlistStatus((prev) => ({
              ...prev,
              [productKey]: true,
            }));
            notificationObject.success(
              "Product successfully added to wishlist"
            );
          } else if (response?.message == "Product Already in Wishlist") {
            notificationObject.success("Product is already in your wishlist");
          } else {
            notificationObject.error("Failed to add product to wishlist");
          }
        }
      } catch (error) {
        console.error("Error updating wishlist:", error);
      }
    }
  };
  /**
   * End of code - Ashlekh on 27-11-2024
   * Reason - To add/remove products in wishlist
   */
  return (
    <div className={SaleProductStyle.pageFrame}>
      <div className={SaleProductStyle.coloredBackground}>
        <div className={`${SaleProductStyle.pageContainer}`}>
          <NavigationPath navigationPathArray={navigationPath} />

          {/* Added by jhamman on 14-10-2024
          Reason - Show message when product are not available*/}
          {saleProduct?.length === 0 ? (
            <div className={SaleProductStyle.noProductsFound}>
              <h4>No product available</h4>
            </div>
          ) : (
            /* End of addition by jhamman on 14-10-2024
          Reason - Show message when product are not available*/
            <div className={SaleProductStyle.productContainer}>
              {/* <div className={SaleProductStyle.viewToggleButtons}>
              <div className={SaleProductStyle.viewButtons}>
                <p onClick={() => setViewType("grid")}></p>
              </div> */}
              {/**Code added by Unnati on 26-10-2024
               *Reason-Added condition to show sort by */}
              {saleProduct?.length > 1 ? (
                <div className={SaleProductStyle.sortContainer}>
                  <p className={SaleProductStyle.sortTitle}>Sort By</p>
                  <select value={sortOrder} onChange={handleSortChange}>
                    <option value="default">Default</option>
                    <option value="lowToHigh">Price: Low to High</option>
                    <option value="highToLow">Price: High to Low</option>
                  </select>
                  <div className={SaleProductStyle.iconWrapper}>
                    {" "}
                    {getIcon()}{" "}
                  </div>
                </div>
              ) : (
                " "
              )}
              {/**End of code addition by Unnati on 26-10-2024
               *Reason-Added condition to show sort by */}
              {/* </div> */}
              <div
                className={
                  viewType === "grid"
                    ? SaleProductStyle.gridContainer
                    : SaleProductStyle.listContainer
                }>
                {/**Code added by Unnati 05-10-2024
                 *Reason-To have map sale products */}
                {saleProduct &&
                  saleProduct.map((product) => (
                    <div
                      key={product.id}
                      className={
                        viewType === "grid"
                          ? SaleProductStyle.gridCard
                          : SaleProductStyle.listCard
                      }>
                        {/* Code commented by - Ashlekh on 27-11-2024
                        Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                      {/* <Link
                        to={`/productdetail/${product.product_id}`}
                        className={SaleProductStyle.productLink}> */}
                          <Link
                             to={`/productdetail/${product.product_id}`}
                             /**Code added by Unnati on 05-12-2024
                    *Reason-Send color through state */
                  state={product.color}
                    /**End of code addition by Unnati on 05-12-2024
                    *Reason-Send color through state */
                          >
                            {/* End of code - Ashlekh on 27-11-2024
                            Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                            <div
                              className={
                                viewType === "grid"
                                  ? SaleProductStyle.gridImageContainer
                                  : SaleProductStyle.listImageContainer
                              }>
                              {/* Added by Jhamman on 06-10-2024
                                Reason - Added offer logo */}
                              <div
                                className={`${SaleProductStyle.imageAndOfferLogoContainer}`}>
                                {product.sale_percentage ? (
                                  <div className={SaleProductStyle.offerContainer}>
                                    <p className={SaleProductStyle.offerPercentage}>
                                      {product.sale_percentage}% off
                                    </p>
                                  </div>
                                ) : null}
                                {/* End of addition by Jhamman on 06-10-2024
                                    Reason - Added offer logo */}
                                <img
                                  src={`${config.baseURL}${product.image1}`}
                                  alt={product.name}
                                  className={
                                    viewType === "grid"
                                      ? SaleProductStyle.gridImage
                                      : SaleProductStyle.listImage
                                  }
                                />
                              </div>
                              <button
                                className={
                                  viewType === "grid"
                                    ? SaleProductStyle.addToCartButton
                                    : SaleProductStyle.listaddToCartButton
                                }>
                                Add to Cart
                              </button>
                            </div>
                          </Link>
                        <div
                          className={
                            viewType === "grid"
                              ? SaleProductStyle.gridContent
                              : SaleProductStyle.listContent
                          }>
                            {/* Added by - Ashlekh on 27-11-2024
                            Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                          <Link
                            to={`/productdetail/${product.product_id}`}
                            /**Code added by Unnati on 05-12-2024
                    *Reason-Send color through state */
                  state={product.color}
                    /**End of code addition by Unnati on 05-12-2024
                    *Reason-Send color through state */
                          >
                            {/* End of code - Ashlekh on 27-11-2024
                            Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page)To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                              {/* Code changed by - Ashlekh on 28-11-2024
                              Reason - To change h3 to div */}
                              {/* <h3
                                className={
                                  viewType === "grid"
                                  ? SaleProductStyle.brandName
                                  : SaleProductStyle.listBrandName
                                  }> */}
                              {/* End of code - Ashlekh on 27-11-2024
                              Reason - To change h3 to div */}
                              <div
                                className={
                                  viewType === "grid"
                                    ? SaleProductStyle.brandName
                                    : SaleProductStyle.listBrandName
                              }>
                              {product.name.length > 50
                                ? `${product.name.substring(0, 50)}...`
                                : product.name}
                                {/* Code changed by - Ashlekh on 28-11-2024
                                Reason - To change h3 to div */}
                                {/* </h3> */}
                                {/* End of code - Ashlekh on 28-11-2024
                                Reason - To change h3 to div */}
                            </div>
                          </Link>
                          {/* Added by - Ashlekh on 28-11-2024
                            Reason - To add rating */}
                            <div className={`${SaleProductStyle.rating}`}>
                              {product.rating > 0 && (
                                <Rating value={product.rating} />
                              )}
                            </div>
                            {/* End of code - Ashlekh on 28-11-2024
                            Reason - To add rating */}
                          {/* Added by - Ashlekh on 27-11-2024
                          Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                          {/* Commented by - Ashlekh on 28-11-2024
                          Reason - To remove description */}
                          {/* <Link
                            to={`/productdetail/${product.product_id}`}
                          > */}
                            {/* End of code - Ashlekh on 27-11-2024
                            Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                            {/* <h4
                              className={
                                viewType === "grid"
                                  ? SaleProductStyle.cardTitle
                                  : SaleProductStyle.listCardTitle
                              }>
                              {product.description.length > 50
                                ? `${product.description.substring(0, 50)}...`
                                : product.description}
                            </h4>
                          </Link> */}
                          {/* End of comment - Ashlekh on 28-11-2024
                          Reason - To remove description */}
                          {/* Modified by Jhamman on 10-10-2024
                          Reason - Added mrp price and price after discount*/}
                          {/* <p
                          className={
                            viewType === "grid"
                              ? SaleProductStyle.cardPrice
                              : SaleProductStyle.listCardPrice
                          }>
                          ${product.sales_rate}
                          </p> */}
                          {/* Added by - Ashlekh on 27-11-2024
                          Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                          <Link
                            to={`/productdetail/${product.product_id}`}
                            /**Code added by Unnati on 05-12-2024
                    *Reason-Send color through state */
                  state={product.color}
                    /**End of code addition by Unnati on 05-12-2024
                    *Reason-Send color through state */
                          >
                            {/* End of code - Ashlekh on 27-11-2024
                            Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                            <div className={SaleProductStyle.priceContainer}>
                              {product.sale_percentage ? (
                                <div
                                  className={
                                    viewType === "grid"
                                      ? SaleProductStyle.cardPrice
                                      : SaleProductStyle.listCardPrice
                                  }>
                                  <p
                                    className={
                                      SaleProductStyle.discountedPriceText
                                    }>
                                    $
                                    {calculateDiscountFromProduct(
                                      product.sales_rate,
                                      product.sale_percentage
                                    )}
                                  </p>
                                </div>
                              ) : null}

                              <div
                                className={
                                  viewType === "grid"
                                    ? SaleProductStyle.cardPrice
                                    : SaleProductStyle.listCardPrice
                                }>
                                <p
                                  className={SaleProductStyle.mrpPriceText}
                                  style={
                                    product.sale_percentage
                                      ? {
                                          textDecoration: "line-through",
                                          // Code changed by - Ashlekh on 28-11-2024
                                          // Reason - To change color
                                          // textDecorationColor: "#000",
                                          // color: "red",
                                          textDecorationColor: "#888888",
                                          color: "#888888",
                                          // End of code - Ashlekh on 28-11-2024
                                          // Reason - To change color
                                        }
                                      : { 
                                        // Code changed by - Ashlekh on 28-11-2024
                                        // Reason - To change color
                                        // color: "green"
                                        color: "red"
                                        // End of code - Ashlekh on 28-11-2024
                                        // Reason - To change color 
                                        }
                                  }>
                                  ${product.sales_rate}
                                </p>
                              </div>
                            </div>
                          </Link>
                          {/**Code added by Unnati on 17-10-2024
                           *Reason-Added star rating
                           */}
                          <div
                            className={SaleProductStyle.productDetailContainer}>
                              {/* Added by - Ashlekh on 27-11-2024
                              Reason - To display wishlist icon */}
                              {user.id != undefined && (
                                <div className={`${SaleProductStyle.wishListIconContainer}`}>
                                  {wishlistStatus[`${product.product_id}-${product.color}`] ? (
                                    <MdFavorite
                                      onClick={() => handleToggleWishList(user, product)}
                                      className={`${SaleProductStyle.wishListIcon1}`}
                                    />
                                  ) : (
                                    <MdFavoriteBorder
                                      onClick={() => handleToggleWishList(user, product)}
                                      className={`${SaleProductStyle.wishListIcon2}`}
                                    />
                                  )}
                                </div>
                              )}
                              {/* End of code - Ashlekh on 27-11-2024
                              Reason - To display wishlist icon */}
                              {/* Commented by - Ashlekh on 28-11-2024
                              Reason - To remove rating */}
                              {/* <div>
                                {product.rating > 0 && (
                                  <Rating value={product.rating} />
                                )}
                              </div> */}
                              {/**Code commented by Unnati on 18-10-2024
                                   *Reason-Commented star component*/
                                  /* <ReactStars
                                      className={CategoryStyle.star}
                                      count={5}
                                      value={product.rating}
                                      size={12}
                                      color2={"#ffd700"}
                                      edit={false}
                                    /> */
                                  /**End of code comment by Unnati on 18-10-2024
                                   *Reason-Commented star component*/
                                  /**Code added by Unnati on 18-10-2024
                                   *Reason-Calling component*/}
                              {/**End of code addition by Unnati on 18-10-2024
                               *Reason-Calling component*/}
                               {/* End of code - Ashlekh on 28-11-2024
                               Reason - To remove rating */}
                          </div>
                          {/**End of code addition by Unnati on 17-10-2024
                           *Reason-Added star rating
                           */}
                          {/* End of modification by Jhamman on 10-10-2024
                        Reason - Added mrp price and price after discount*/}
                        </div>
                        {/* Commented by - Ashlekh on 27-11-2024
                        Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                      {/* </Link> */}
                      {/* End of code - Ashlekh on 27-11-2024
                      Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                    </div>
                  ))}
                {/**End of code addition by Unnati 05-10-2024
                 *Reason-To have map sale products */}
              </div>
            </div>
          )}
        </div>
      </div>
      {scrollDoc()}
    </div>
  );
};

export default SaleProduct;
/**End of code addition by Unnati on 05-10-2024
 * Reason-To have sale products page
 */
