/**Code added by Unnati on 01-06-2024
 *Reason -To have header
 */
import React, { useEffect, useState, useContext, useRef } from "react";
import { FiSearch } from "react-icons/fi";
import {
  getCategory,
  getSearchedProduct,
  removeItem,
  getCartItem,
  userDetails,
} from "../../Api/services";
import styles from "./header1.module.css";
import { Link } from "react-router-dom";
import config from "../../Api/config";
import { RxHamburgerMenu } from "react-icons/rx";
import { GiBasket } from "react-icons/gi";

import { FaLock } from "react-icons/fa";
import { GlobalContext } from "../../context/Context";
import { useLocation, useNavigate } from "react-router-dom";
import notificationObject from "../../components/Widgets/Notification/Notification";
import { BiSolidRightArrow } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import Marquee from "react-fast-marquee";
import { calculateDiscountFromProduct } from "../../utils/discountedPrices";
import { MdFavoriteBorder } from "react-icons/md";

import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import "./slider.css";
import { IoBagCheckOutline } from "react-icons/io5";

const Header1 = () => {
  const [category, setCategory] = useState([]);
  const [dropdown, setDropdown] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [SearchProduct, setSearchProduct] = useState([]);
  const { cartData, setCartData, settingInfo } = useContext(GlobalContext);
  const [cartItem, setCartItem] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("0");
  const navigate = useNavigate();
  const { isUserLoading, social } = useContext(GlobalContext);
  const [loginWithCheckoutButton, setLoginWithCheckoutButton] = useState(false);
  /**Code commented by Unnati on 24-06-2024
   * Reason-These variables are not in use
   */
  // Addition by Om Shrivastava on 03-12-2024
  // Reason : Add the loader 
  const [isLoading, setIsLoading] = useState(true);
  // End of addition by Om Shrivastava on 03-12-2024
  // Reason : Add the loader 
  const [productCount, setProductCount] = useState();

  const { user, setUser } = useContext(GlobalContext);
  const [loggedOut, setLoggedOut] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isEnter, setIsEnter] = useState(false);

  const dropdownRef = useRef(null);
  // const [isModalVisible, setIsModalVisible] = useState(false);
  // const [isProfileModalVisible, setIsProfileModalVisible] = useState(false);

  /**End of code commented by Unnati on 24-06-2024
   * Reason-These variables are not in use
   */
  /**Code added by Unnati on 25-10-2024
   * Reason-To handle checkout button click
   */
  const handleCheckoutClick = async () => {
    if (user.id) {
      try {
        const response = await userDetails(localStorage.getItem("access"));
        if (response.code == "user_inactive") {
          localStorage.removeItem("access");
          localStorage.removeItem("refresh");
          setUser({});
          /**Code added by Unnati on 26-10-2024
           * Reason-Added notification
           */
          notificationObject.error("User is inactive.Please contact admin.");
          /**End of code addition by Unnati on 26-10-2024
           * Reason-Added notification
           */
          navigate("/login");
        } else {
          navigate("/checkout");
        }
      } catch (error) {
        console.error("Error fetching user details:", error.message);
      }
    }
  };
  /**End of code addition by Unnati on 25-10-2024
   * Reason-To handle checkout button click
   */
  /**Code added by Unnati on 30-10-2024
   * Reason-Added function to handle checkout when user is not logged in
   */
  const handleLoginWithCheckout = async () => {
    setLoginWithCheckoutButton(true);
    navigate("/login", { state: { loginWithCheckoutButton: true } });
  };
  /**End of code addition by Unnati on 30-10-2024
   * Reason-Added function to handle checkout when user is not logged in
   */

  /**Code commented by Unnati on 24-06-2024
   * Reason-Profile section is not displayed in header
   */
  // const handleProfileModalOk = () => {
  //   setIsProfileModalVisible(false);
  // };

  // const handleProfileModalCancel = () => {
  //   setIsProfileModalVisible(false);
  // };
  /**End of code commented by Unnati on 24-06-2024
   * Reason-Profile section is not displayed in header
   */

  /**Code commented by Unnati on 24-06-2024
   * Reason-Logout option is not displayed in the header
   */
  // const showModal = () => {
  //   setIsModalVisible(!isModalVisible);
  // };
  // const handleOk = () => {
  //   setIsModalVisible(!isModalVisible);
  //   handleLogout();
  // };

  // const handleCancel = () => {
  //   setIsModalVisible(!isModalVisible);
  // };
  /**End of code commented by Unnati on 24-06-2024
   * Reason-Logout option is not displayed in the header
   */

  /**Code commented by Unnati on 24-06-2024
   * Reason-Logout option is not displayed in the header
   */

  /* Added by jhamman on 17-10-2024
  Reason - to clear search term*/
  useEffect(() => {
    setSearchTerm("");
  }, [navigate]);
  /* End of addition by jhamman on 17-10-2024
  Reason - to clear search term*/

  const handleLogout = () => {
    localStorage.clear();
    setUser({});
    notificationObject.success("Successfully logged out");
    setLoggedOut(true);
    setCartData([]);
  };

  // useEffect(() => {
  //   if (loggedOut) {
  //     navigate("/");
  //   }
  // }, [loggedOut]);
  /**End of code comment by Unnati on 24-06-2024
   * Reason-Logout option is not displayed in the header
   */

  /* Added by jhamman on 30-10-2024
  Reason - Added breakpoint for category item as per screen size*/

  const [sliderRef, instanceRef] = useKeenSlider({
    breakpoints: {
      "(min-width: 300px)": {
        slides: { perView: 3 },
      },
      "(min-width:400px)": {
        slides: { perView: 4 },
      },
      "(min-width:500px)": {
        slides: { perView: 5 },
      },
      "(min-width:600px)": {
        slides: { perView: 6 },
      },
      "(min-width: 720px)": {
        slides: { perView: 6 },
      },
      "(min-width: 800px)": {
        slides: { perView: 7 },
      },
      "(min-width: 900px)": {
        slides: { perView: 7 },
      },
      "(min-width: 1000px) and (max-width: 1050px)": {
        slides: { perView: 8 },
      },
      "(min-width: 1120px)": {
        slides: { perView: 10 },
      },
      "(min-width: 1200px)": {
        slides: { perView: 10 },
      },
      "(min-width: 1300px)": {
        slides: { perView: 11 },
      },
      "(min-width: 1400px)": {
        slides: { perView: 12 },
      },
    },
    loop: false,
  });

  /* End of addition by jhamman on 30-10-2024
  Reason - Added breakpoint for category item as per screen size*/

  const performSearclickOnSearchModelButton = () => {};

  /**Code added by Unnati on 01-06-2024
   * Reason- To map categories and their sub categories
   */
  const SubCategories = ({ subcategories }) => {
    return (
      /**Code added by Unnati on 09-08-2024
       * Reason-Added classname in ul tag
       */
      <ul className={styles.allCategories}>
        {/**End of code addition by Unnati on 09-08-2024
         * Reason-Added classname in ul tag
         */}
        {subcategories.map((subcat) => (
          <li className={styles.dropdown} key={subcat.id}>
            <Link
              to={`/category/`}
              state={subcat.id}
              className={styles.categoryLink}
            >
              {subcat.name}

              {subcat.children && subcat.children.length > 0 && (
                <BiSolidRightArrow className={styles.arrowIcon} />
              )}
            </Link>
            {subcat.children && (
              <ul>
                {subcat.children?.map((subItem) => (
                  <li className={styles.dropdown} key={subItem.id}>
                    <Link
                      to={`/category/${subItem.id}`}
                      className={styles.SubCategorycategoryLink}
                    >
                      {subItem.name}

                      {subItem.children && subItem.children.length > 0 && (
                        <BiSolidRightArrow className={styles.arrowIcon} />
                      )}
                    </Link>
                    {subItem.children && (
                      <ul>
                        {subItem.children?.map((subSubItem) => (
                          <li key={subSubItem.id}>
                            <Link
                              to={`/category/${subSubItem.id}`}
                              className={styles.subSubCategorycategoryLink}
                            >
                              {subSubItem.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    );
  };
  /**End of code addition by Unnati on 01-06-2024
   * Reason- To map categories and their sub categories
   */

  /**Code added by Unnati Bajaj on 01-06-2024
   * Reason -To get categories when the component loads
   */
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const data = await getCategory();
        setCategory(data.categoryList);
        setDropdown(data.dropdown);
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchCategories();
  }, []);
  /**End of code addition by Unnati Bajaj on 01-06-2024
   * Reason -To get categories when the component loads
   */

  /**Code commented by Unnati Bajaj on 24-06-2024
   * Reason -To get socialLinks when the component loads
   */
  // useEffect(() => {
  //   const fetchSocialLinks = async () => {
  //     try {
  //       const data = await getSocialLinks();
  //       setSocial(data.social);
  //     } catch (error) {
  //       console.error(error.message);
  //     }
  //   };
  //   fetchSocialLinks();
  // }, []);
  /**End of code commented by Unnati Bajaj on 24-06-2024
   * Reason -To get socialLinks when the component loads
   */
  /**Code commented by Unnati Bajaj on 26-07-2024
   * Reason -As we do not need to get whole product list in frontend
   */
  // useEffect(() => {
  //   const fetchProductList = async () => {
  //     try {
  //       const data = await getProductList();
  //       setProductList(data.product);
  //     } catch (error) {
  //       console.error(error.message);
  //     }
  //   };
  //   fetchProductList();
  // }, []);
  /**End of code comment by Unnati Bajaj on 26-07-2024
   * Reason --As we do not need to get whole product list in frontend
   */
  /**Code added by Unnati Bajaj on 24-07-2024
   * Reason -To get searched product
   */
  /**Code modified by Unnati on 18-09-2024
   * Reason-Added is enter value
   */
  const fetchSearchProduct = async (isEnterValue) => {
    try {
      const data = await getSearchedProduct(
        selectedCategoryId,
        searchTerm,
        isEnterValue
      );
      setSearchProduct(data.products);
      setProductCount(data.product_count);
    } catch (error) {
      console.error(error.message);
    } finally {
      setIsEnter(false);
    }
  };
  /**End of code modification by Unnati on 18-09-2024
   * Reason-Added is enter value
   */
  /**Code modified by Unnati on 18-09-2024
   * Reason-Added is enter
   */
  useEffect(() => {
    if (searchTerm.length >= 3 || isEnter) {
      fetchSearchProduct(isEnter);
    }
  }, [selectedCategoryId, searchTerm, isEnter]);
  /**End of code modification by Unnati on 18-09-2024
   * Reason-Added is enter
   */
  /**End of code addition by Unnati Bajaj on 24-07-2024
   * Reason -To get searched product
   */
  /**Code added by Unnati Bajaj on 25-06-2024
   * Reason -To map the categories in the search bar dropdown
   */
  const renderOptions = (category, depth = 0) => {
    const prefix = "--".repeat(depth);
    return category?.flatMap((categories) => {
      const nestedOptions = categories.children
        ? renderOptions(categories.children, depth + 1)
        : [];

      return [
        <option key={categories.id} value={categories.id}>
          {prefix}
          {categories.name}
        </option>,
        ...nestedOptions,
      ];
    });
  };
  /**Code added by Unnati on 24-07-2024
   * Reason-To handle category change
   */
  const handleCategoryChange = (event) => {
    setSelectedCategoryId(event.target.value);
  };

  /**End of code addition Unnati on 24-07-2024
   * Reason-To handle category change
   */
  /**End of code addition by Unnati Bajaj on 25-06-2024
   * Reason -To map the categories in the search bar dropdown
   */

  /**Code commented by Unnati Bajaj on 01-06-2024
   * Reason -To get logo when the component loads
   */
  // useEffect(() => {
  //   const fetchlogo = async () => {
  //     try {
  //       const data = await getSettings();
  //       setLogo(data.setting);
  //     } catch (error) {
  //       console.error(error.message);
  //     }
  //   };
  //   fetchlogo();
  // }, []);
  /**End of code commented by Unnati Bajaj on 01-06-2024
   * Reason -To get logo when the component loads
   */
  /**Code commented by Unnati Bajaj on 25-06-2024
   * Reason -To get brands when the component loads
   */
  // useEffect(() => {
  //   const fetchBrand = async () => {
  //     try {
  //       const data = await getbrand();
  //       setBrand(data.brand);
  //     } catch (error) {
  //       console.error(error.message);
  //     }
  //   };
  //   fetchBrand();
  // }, []);
  /**End of code comment by Unnati Bajaj on 25-06-2024
   * Reason -To get brands when the component loads
   */

  /**Code added by Unnati on 07-07-2024
   * Reason-To filter not null sizes and map it with quantity
   */
  const getFilteredSizes = (item) => {
    const sizeKeys = ["XS", "S", "M", "L", "XL", "XXL", "XXXL"];
    const filterSizeKeys = sizeKeys.filter((key) => item[key] !== null || 0);
    const mapSize = filterSizeKeys.map((key) => [key, item[key]]);
    return mapSize;
  };
  /**End of code addition by Unnati on 07-07-2024
   * Reason-To filter not null sizes and map it with quantity
   */
  /**Code added by Unnati on 12-08-2024
   * Reason-To get total item count in the cart
   */
  const getTotalItemCount = (cartData) => {
    let totalCount = 0;

    /**
     * Modified by - Ashish Dewangan on 01-12-2024
     * Reason - Counting total items in cart differently according to new schema
     */
    // for (let i = 0; i < cartData.length; i++) {
    //   const item = cartData[i];
    //   totalCount += item.XS || 0;
    //   totalCount += item.S || 0;
    //   totalCount += item.M || 0;
    //   totalCount += item.L || 0;
    //   totalCount += item.XL || 0;
    //   totalCount += item.XXL || 0;
    //   totalCount += item.XXXL || 0;
    // }
    for (let i = 0; i < cartData.length; i++) {
      const item = cartData[i];
      totalCount += parseInt(item.quantity ? item.quantity : 0);
    }
    /**
     * End of modification by - Ashish Dewangan on 01-12-2024
     * Reason - Counting total items in cart differently according to new schema
     */

    return totalCount;
  };

  const totalItemCount = getTotalItemCount(cartData);
  /**End of code addition by Unnati on 12-08-2024
   * Reason-To get total item count in the cart
   */

  /**Code commented by Unnati on 10-07-2024
   * Reason -To update cart data and set it in local storage
   */
  // const updateCartData = async (data) => {
  //   try {
  //     const updatedCartData = await updateCart(data);
  //     setCart(updatedCartData);
  //     localStorage.setItem("cartData", JSON.stringify(updatedCartData));
  //   } catch (err) {
  //     console.error("Error updating cart:", err);
  //   }
  // };
  /**End of code comment by Unnati on 10-07-2024
   * Reason -To update cart data and set it in local storage
   */
  /**Code added by Unnati on 07-07-2024
   * Reason-To delete product from my cart and local storage
   */
  const handleDelete = (
    product,
    size,
    logo,
    patches,
    security_batches,
    embroider,
    color
  ) => {
    /**Code added by Unnati on 27-07-2024
     * Reason-To delete item from backend when user is logged in
     */
    if (user.id) {
      const deleteProduct = async () => {
        try {
          const data = await removeItem(
            user.id,
            product,
            size,
            logo,
            patches,
            security_batches,
            embroider,
            color
          );
          localStorage.setItem("cartData", JSON.stringify(data.cartItem));
          setCartData(data.cartItem);
        } catch (error) {
          console.error(error.message);
        }
      };
      deleteProduct();
      /**End of code addition by Unnati on 27-07-2024
       * Reason-To delete item from backend when user is logged in
       */
    } else {
      /**
       * Modified by - Ashish Dewangan on 01-12-2024
       * Reason - Updated the code according to the customization functionality
       */
      // const updatedData = cartData
      //   .map((item) => {
      //     if (item.product === product) {
      //       if (item[size] !== null) {
      //         const updatedItem = { ...item, [size]: null };
      //         const remainingSizes = Object.values(updatedItem);
      //         const filterRemainingSizes = remainingSizes.filter(
      //           (value) => value !== null
      //         );
      //         const remainingSizesLength = filterRemainingSizes.length;
      //         return remainingSizesLength > 1 ? updatedItem : null;
      //       }
      //     }

      //     return item;
      //   })
      //   .filter((item) => item !== null);
      // localStorage.setItem("cartData", JSON.stringify(updatedData));
      // setCartData(updatedData);
      const updatedData = cartData
        .map((item) => {
          if (
            item.product === product &&
            item.logo === logo &&
            item.patches === patches &&
            item.security_batches === security_batches &&
            item.embroider === embroider &&
            item.size === size &&
            item.color === color 

          ) {
            return null;
          } else {
            return item;
          }
        })
        .filter((item) => item !== null);
      localStorage.setItem("cartData", JSON.stringify(updatedData));
      setCartData(updatedData);
      /**
       * End of modification by - Ashish Dewangan on 01-12-2024
       * Reason - Updated the code according to the customization functionality
       */
    }
  };
  /**End of code addition by Unnati on 07-07-2024
   * Reason-To delete product from my cart and local storage
   */
  /**Code commented by Unnati on 12-07-2024
   * Reason-To filter the product according to the search term
   */
  // useEffect(() => {
  //   const filtered = ProductList.filter((product) =>
  //     product.name.toLowerCase().includes(searchTerm.toLowerCase())
  //   );
  //   setFilteredProduct(filtered);
  // }, [searchTerm, ProductList]);
  /**End of code comment by Unnati on 12-07-2024
   * Reason-To filter the product according to the search term
   */
  /**Code modified by Unnati on 24-07-2024
   * Reason-To handle search change
   */
  const handleSearchChange = (e) => {
    /**Code added by Unnati on 05-09-2024
     * Reason-To replace double space by single space
     */
    const value = e.target.value.replace(/\s{2,}/g, " ");
    setSearchTerm(value);
    /**End of code addition by Unnati on 05-09-2024
     * Reason-To replace double space by single space
     */
    // const trimmedValue = e.target.value.trim();
    // setSearchTerm(trimmedValue);
    setIsDropdownOpen(value?.trim()?.length >= 3);
  };
  /**Code added by Unnati on 05-09-2024
   * Reason-To handle serach when user intentionally press enter
   */
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      {
        /**Code added by Unnati on 18-09-2024
         *Reason-To setIsEnter value */
      }
      if (searchTerm.trim() === "") {
        e.preventDefault();
        return;
      }
      setIsEnter(true);
      {
        /**End of code addition by Unnati on 18-09-2024
         *Reason-To setIsEnter value */
      }
      setIsDropdownOpen(true);
    }
  };
  const performSearch = () => {
    if (searchTerm.trim().length > 0) {
      setIsDropdownOpen(true);
    } else {
      setIsDropdownOpen(false);
    }
  };

  /**End of code addition by Unnati on 05-09-2024
   * Reason-To handle serach when user intentionally press enter
   */
  /**End of code modification by Unnati on 24-07-2024
   * Reason-To handle search change
   */

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  /**Code added by Unnati on 20-09-2024
   * Reason-To close search dropdown when going to another page
   */
  const location = useLocation();
  useEffect(() => {
    setIsDropdownOpen(false);
  }, [location]);
  /**End of code addition by Unnati on 20-09-2024
   * Reason-To close search dropdown when going to another page
   */
  /**Code commented by Unnati on 27-07-2024
   * Reason-This code is not in use
   */
  // const getProductDetails = (productId) => {
  //   return cartItem.find((product) => product.id === productId);
  // };
  /**End of code comment by Unnati on 27-07-2024
   * Reason-This code is not in use
   */
  /**Code commented by Unnati on 18-07-2024
   * Reason-To calculate cart total
   */
  // const calculateCartTotal = (cartData) => {
  //   const total =
  //     cartData &&
  //     cartData.reduce((total, item) => {
  //       const product = getProductDetails(item.productId || item.product) || {};
  //       const sizes = getFilteredSizes(item);
  //       const itemTotal = sizes.reduce((subtotal, [size, quantity]) => {
  //         const salesRate = product.sales_rate || 0;
  //         return subtotal + salesRate * quantity;
  //       }, 0);
  //       return total + itemTotal;
  //     }, 0);

  //   return total;
  // };
  /** End of Code comment by Unnati on 18-07-2024
   * Reason-To calculate cart total
   */

  /**Code commented by Unnati on 26-07-2024
   * Reason-This code is not in use
   */
  // const cartDataToPass =
  //   Array.isArray(cartData) &&
  //   cartData.map((item) => ({
  //     productId: item.productId || item.product,
  //     sizeQuantity: getFilteredSizes(item),
  //     productDetails: getProductDetails(item.productId || item.product),
  //   }));
  /**End of code commented by Unnati on 26-07-2024
   * Reason-This code is not in use
   */
  /**Code added by Unnati on 19-07-2024
   * Reason-To handle viewcart button
   */
  const handleViewCart = () => {
    /**Code added by Unnati on 03-10-2024
     * Reason-Sending totalItemCount and subtotal through state in navigate
     */
    const subtotal = calculateSubtotal();
    navigate("/viewcart", { state: { totalItemCount, subtotal } });
    /**End of code addition by Unnati on 03-10-2024
     * Reason-Sending totalItemCount and subtotal through state in navigate
     */
  };
  /**End of code addition by Unnati on 19-07-2024
   * Reason-To handle viewcart button
   */
  /**Code added by Unnati on 28-07-2024
   * Reason-To get cart details when hovered in my cart
   */
  const fetchCartDetails = async (e) => {
    try {
      e.preventDefault();
      const product = cartData.map((item) => item.product);
      const response = await getCartItem(product);
      // setCartItem(response.products);
      
      localStorage.setItem("cartData",JSON.stringify(response.products))
      setCartData(response.products);
      // Addition by Om Shrivastava on 03-12-2024
      // Reason : Add the loader 
      setIsLoading(false);
      // End of addition by Om Shrivastava on 03-12-2024
      // Reason : Add the loader 
    } catch (error) {
      console.error("Error fetching cart details:", error);
    }
  };

  useEffect(() => {
    fetchCartDetails();
  }, [cartData]);
  /**End of code addition by Unnati on 28-07-2024
   * Reason-To get cart details when hovered in my cart
   */
  /**Code added by Unnati on 01-09-2024
   * Reason-To calculate subtotal
   */
  const calculateSubtotal = () => {
    /* Added by jhamman om 09-10-2024
    Reason - showing error cartData.reduce is not a function*/
    if (!Array.isArray(cartData)) {
      return 0;
    }
    /* End of addition by jhamman om 09-10-2024
    Reason - showing error cartData.reduce is not a function*/
    var total = 0;
    cartData.forEach((item) => {
      const price = item.sales_rate
        ? item.sale_percentage
          ? calculateDiscountFromProduct(item.sales_rate, item.sale_percentage)
          : item.sales_rate
        : 0;

      const finalPrice =
        item.logo || item.patches || item.security_batches || item.embroider
          ? item.after_customization_product_price || price
          : price;

      total += finalPrice * item.quantity;
    });

    return total;
  };
  console.log("context",cartData)
  /*End of code addition by Unnati on 01-09-2024
   * Reason-To calculate subtotal
   */

  /**
   * Added by - Ashlekh on 07-11-2024
   * Reason - To naviagte in wishlist
   */
  const wishListNavigation = () => {
    navigate("/wishlist");
  };
  /**
   * End of code - Ashlekh on 07-11-2024
   * Reason - To navigate in wishlist
   */
  /**
   * Added by - Ashlekh on 02-12-2024
   * Reason - To naviagte in order history and to send orders in state. This will be used to directly open My Order tab
   */
  const navigateMyOrder = () => {
    navigate("/myaccount", { state: { myOrder: "orders" } });
  };
  /**
   * End of code - Ashlekh on 02-12-2024
   * Reason - To naviagte in order history and to send orders in state. This will be used to directly open My Order tab
   */

  return (
    <>
      {/**Code added by Unnati on 24-0-2024
       *Reason-To have a header top */}
      <div className={styles.pageFrame}>
        <header className={styles.header}>
          <div className={styles.headerTop}>
            <div className={styles.headerTopLeftMost}>
              <span className={styles.iconContainer}>
                {social?.map((socialItem, index) => (
                  <Link
                    to={socialItem.social_link}
                    key={index}
                    className={styles.socialIcon}
                    target="_blank"
                    style={{ cursor: "auto" }}
                  >
                    <img
                      className={styles.img}
                      src={config.baseURL + socialItem.icon}
                      alt={socialItem.name}
                    />
                  </Link>
                ))}
              </span>
              {/**Code added by Unnati on 17-07-2024
               *Reason -To add telephone number in the header top that is coming from backend */}
              {/**Code added by Unnati on 04-10-2024
               *Reason-Added marque into headerTop */}
              <Marquee>
                {/**Code added by Unnati on 14-10-2024
                 *Reason-Changed label */}
                <div className={styles.contactNumber}>
                  Contact Number : {settingInfo?.contact_number}
                </div>
                <div className={styles.contactNumber}>
                  Email : {settingInfo?.email}
                </div>
                {/**End of code addition by Unnati on 14-10-2024
                 *Reason-Changed label */}
              </Marquee>
              {/**End of code addition by Unnati on 04-10-2024
               *Reason-Added marque into headerTop */}
            </div>
            {/**End of code addition by Unnati on 17-07-2024
             *Reason -To add telephone number in the header top that is coming from backend */}
            <div className={styles.icons}>
              {/**Code commented by Unnati on 25-06-2024
               *Reason- Profile option will not be displayed at the header */}
              {/* {user && user.email ? (
             {/* {user && user.email ? (
                <div className={styles.profile}>
                  <ul>
                    <li className={styles.profileIcon}>
                      <Link>
                        <CgProfile style={{ color: "black" }} size={30} />
                      </Link>
  
                      <ul>
                      <li>
                          <Link onClick={showModal}>
                            Logout
                            <Modal
                              title="Confirm Logout"
                              visible={isModalVisible}
                              onOk={handleOk}
                              onCancel={handleCancel}
                              okText="Yes"
                              cancelText="No"
                            >
                              <p>Are you sure you want to logout?</p>
                            </Modal>
                          </Link>
                        </li>
                        <li>
                          <Link onClick={() => setIsProfileModalVisible(true)}>
                            Hello! {user.first_name}
                          </Link>
                          <Modal
                            title="Profile Edit"
                            visible={isProfileModalVisible}
                            onOk={handleProfileModalOk}
                            onCancel={handleProfileModalCancel}
                            footer={null}
                          >
                            <ProfileEdit
                              onCancel={() => setIsProfileModalVisible(false)}
                            />{" "} */}
              {/* Pass onCancel function to close modal */}
              {/* </Modal>
                        </li> */}
              {/**Code commented by Unnati on 17-06-2024
               *Reason-Already had a logout button
               */}
              {/* <li><Link onClick={handleLogout}>Logout</Link></li> */}
              {/**End of code commented by Unnati on 17-06-2024
               *Reason-Already had a logout button
               */}

              {/* </ul>
                    </li> */}
              {/* <li>
                      <Link>
                        <FiShoppingCart style={{ color: "black" }} size={30} />
                      </Link>
                    </li> */}
              {/* </ul> */}
              {/* </div> */}
              {/* ) : ( */}
              {/**End of code commented by Unnati on 25-06-2024
               *Reason- Profile option will not be displayed at the header */}

              {/* )} */}
              {/**Code modified by Unnati on 14-09-2024
               *Reason-To check whether user is logged in or not */}
              {isUserLoading ? (
                <></>
              ) : user && user.email ? (
                /**Code added by Unnati on 18-09-2024
                 *Reason-Added profile section (name,email and logout) */
                <div className={styles.profileContainer}>
                  <div className={styles.profileIconContainer}>
                    <CgProfile
                      size={30}
                      className={styles.profileIcon}
                      color="white"
                    />
                    <ul className={styles.dropdownContent}>
                      <li>{user.first_name}</li>
                      <li>{user.email}</li>
                      <li>
                        <Link
                          onClick={handleLogout}
                          className={styles.logoutButton}
                        >
                          Logout
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              ) : (
                /**End of code addition by Unnati on 18-09-2024
                 *Reason-Added profile section (name,email and logout) */
                <div className={styles.signInContainer}>
                  <div className={styles.lockContainer}>
                    <FaLock size={10} className={styles.signInContainer} />
                  </div>
                  <div>
                    <Link className={styles.signIn} to="/login">
                      {/* Sign In /{" "} */}
                      Log In /{" "}
                    </Link>
                    <Link className={styles.signIn} to="/signup">
                      {/* Create an account */}
                      Sign Up
                    </Link>
                  </div>
                </div>
              )}

              {/**End of code modification by Unnati on 14-09-2024
               *Reason-To check whether user is logged in or not */}
            </div>
          </div>
          {/**End of code addition by Unnati on 24-06-2024
           *Reason-To have a header top */}

          {/**Code added by Unnati on 24-06-2024
           *Reason-To have a header middle */}
          <div className={styles.headerMiddle}>
            <div className={styles.container}>
              <div className={styles.row}>
                <div className={styles.col3Logo}>
                  {/**Code modified by Unnati on 09-09-2024
                   *Reason-To remove image icon when there is no logo */}

                  <Link to="/">
                    {settingInfo?.logo ? (
                      <img
                        className={styles.logo}
                        src={`${config.baseURL}${settingInfo?.logo}`}
                      />
                    ) : (
                      <div className={styles.logo}></div>
                    )}
                  </Link>
                  {/**End of code modification by Unnati on 09-09-2024
                   *Reason-To remove image icon when there is no logo */}
                </div>

                <div className={styles.col9Search}>
                  <div className={styles.container}>
                    <input
                      type="checkbox"
                      id="menu-bar"
                      className={styles.menuBar}
                    />
                    <label htmlFor="menu-bar" className={styles.label}>
                      <RxHamburgerMenu />
                    </label>
                    <nav className={styles.navbar}>
                      <ul>
                        {category && <SubCategories subcategories={category} />}
                      </ul>
                    </nav>
                  </div>
                  <div className={styles.middleRightContainer}>
                    <div className={styles.searchWrapper}>
                      <div className={styles.searchContainer}>
                        <div className={styles.dropdownSearch}>
                          <select
                            className={styles.selectCategory}
                            onChange={handleCategoryChange}
                            value={selectedCategoryId}
                          >
                            {/**Code modified by Unnati on 24-07-2024
                             *Reason-To set value all categories value as 0*/}
                            <option
                              className={styles.selectCategoryValue}
                              value="0"
                            >
                              <div className={styles.allCategoryText}>
                                All Categories
                              </div>
                            </option>
                            {renderOptions(category)}
                            {/**End of code modification by Unnati on 24-07-2024
                             *Reason-To set value all categories value as 0*/}
                          </select>
                        </div>
                        <div className={styles.searchBarInput}>
                          <input
                            type="text"
                            className={styles.searchBar}
                            placeholder="Search Products..."
                            value={searchTerm}
                            onChange={handleSearchChange}
                            /**Code added by Unnati on 05-09-2024
                             * Reason-To handle enter key press
                             */
                            onKeyPress={handleKeyPress}
                            /**End of code addition by Unnati on 05-09-2024
                             * Reason-To handle enter key press
                             */
                          />
                          {isDropdownOpen && (
                            <div
                              className={`${styles.filteredProduct} ${
                                SearchProduct?.length === 0
                                  ? styles.noScroll
                                  : styles.show
                              }`}
                              ref={dropdownRef}
                            >
                              {Array.isArray(SearchProduct) &&
                              SearchProduct.length > 0 ? (
                                <>
                                  <p className={styles.productCount}>
                                    Products ({productCount})
                                  </p>
                                  {SearchProduct.slice(0, 3).map((product) => (
                                    <Link
                                      to={`/productdetail/${product.product_id}`}
                                      /**Code added by Unnati on 05-12-2024
                                        *Reason-Send color through state */
                                      state={product.color}
                                        /**End of code addition by Unnati on 05-12-2024
                                        *Reason-Send color through state */
                                      key={product.id}
                                      onClick={() => setIsDropdownOpen(false)}
                                    >
                                      <div className={styles.productContainer}>
                                        {/* Added by jhamman on 11-10-2024
                                      Reason - to show offer percentage*/}
                                        <div
                                          className={`${styles.imageAndOfferLogoContainer}`}
                                        >
                                          {product.sale_percentage ? (
                                            <div
                                              className={styles.offerContainer}
                                            >
                                              <p
                                                className={
                                                  styles.offerPercentage
                                                }
                                              >
                                                -{product.sale_percentage}%
                                              </p>
                                            </div>
                                          ) : null}
                                          {/* End of addition by jhamman on 11-10-2024
                                          Reason - to show offer percentage*/}
                                          <img
                                            src={`${config.baseURL}${product.image1}`}
                                            alt={product.name}
                                            className={styles.imageSearchSize}
                                          />
                                        </div>
                                        <div className={styles.productInfo}>
                                          {/**Code added by Unnati on 18-11-2024
                                           *Reason-Added condition for handling long name */}
                                          <h4 className={styles.searchName}>
                                            {product.name.length > 50
                                              ? `${product.name.substring(
                                                  0,
                                                  50
                                                )}...`
                                              : product.name}
                                          </h4>
                                          {/**End of code addition by Unnati on 18-11-2024
                                           *Reason-Added condition for handling long name */}
                                          <p>
                                            {product.description.substring(
                                              0,
                                              100
                                            )}
                                          </p>
                                        </div>
                                        {/* Modified by Jhamman on 11-10-2024
                                        Reason- calculate sale price */}
                                        {/* <div className={styles.productPrice}>
                                          <h4>${product.sales_rate}</h4>
                                        </div> */}
                                        <div className={styles.productPrice}>
                                          {product.sale_percentage ? (
                                            <div className={styles.cardPrice}>
                                              <p
                                                className={
                                                  styles.discountedPriceText
                                                }
                                              >
                                                $
                                                {calculateDiscountFromProduct(
                                                  product.sales_rate,
                                                  product.sale_percentage
                                                )}
                                              </p>
                                            </div>
                                          ) : null}
                                          <div
                                            className={`${styles.cardPrice}`}
                                          >
                                            <p
                                              className={styles.mrpPriceText}
                                              style={
                                                product.sale_percentage
                                                  ? {
                                                      textDecoration:
                                                        "line-through",
                                                      textDecorationColor:
                                                        "#000",
                                                      color: "red",
                                                    }
                                                  : { color: "green" }
                                              }
                                            >
                                              ${product.sales_rate}
                                            </p>
                                          </div>
                                        </div>
                                        {/* End of modification by Jhamman on 11-10-2024
                              Reason- calculate sale price */}
                                      </div>
                                    </Link>
                                  ))}
                                  {SearchProduct.length > 3 && (
                                    <div
                                      className={styles.viewMore}
                                      onClick={() => {
                                        navigate("/searchresults", {
                                          state: {
                                            products: SearchProduct,
                                            query: searchTerm,
                                          },
                                        });
                                        setIsDropdownOpen(false);
                                      }}
                                    >
                                      View More
                                    </div>
                                  )}
                                </>
                              ) : (
                                <p className={styles.noProductFound}>
                                  No products found
                                </p>
                              )}
                            </div>
                          )}

                          <button
                            className={styles.searchButton}
                            /**Code added by Unnati on 05-09-2024
                             * Reason-To handle serach
                             */
                            onClick={() => performSearch()}
                            /**End of code addition by Unnati on 05-09-2024
                             * Reason-To handle serach
                             */
                          >
                            <FiSearch />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Addition by jhamman on 30-10-2024
                  Reason - Added icon for wishlist  */}
                <div className={styles.wishlistAndcart}>
                  {/* Added by - Ashlekh on 02-12-2024
                  Reason - To add My order */}
                  {user.id != undefined && (
                    <div
                      className={`${styles.myOrderContainer}`}
                    >
                      <IoBagCheckOutline 
                        className={`${styles.myOrderIcon}`}
                        onClick={navigateMyOrder}
                      />
                      <div>My Orders</div>
                    </div>
                  )}
                  {/* End of code - Ashlekh on 02-12-2024
                  Reason - To add My order */}
                  {/* Code changed by - Ashlekh on 07-11-2024
                  Reason - To add onClick function for navigation in wishlist */}
                  {/* <div className={styles.wishlistContainer}> */}
                  {/* Added by - Ashlekh on 20-11-2024
                Reason - To apply condition for displaying wishlist icon*/}
                  {user.id != undefined && (
                    // End of code - Ashlekh on 20-11-2024
                    // Reason - To apply condition for displaying wishlist icon
                    <div
                      className={styles.wishlistContainer}
                      onClick={wishListNavigation}
                    >
                      {/* End of code - Ashlekh on 07-11-2024
                  Reason - To add onClick function for navigation in wishlist */}
                      <MdFavoriteBorder
                        className={styles.wishlistIcon}
                        style={{ height: "20px", width: "20px" }}
                      />
                      <div>Wishlist</div>
                    </div>
                  )}

                  {/* End of addition by jhamman on 30-10-2024
                  Reason - Added icon for wishlist  */}

                  <div className={styles.cartcontainer}>
                    <div className={styles.cartContainer}>
                      <Link className={styles.cart}>
                        <GiBasket className={styles.cartImage} />
                        {/**Code added by Unnati on 12-08-2024
                         *Reason-Added cart badge to show no of items in the cart */}
                        <div className={styles.cartBadge}>{totalItemCount}</div>
                        {/**End of code addition by Unnati on 12-08-2024
                         *Reason-Added cart badge to show no of items in the cart */}
                      </Link>

                      <div className={styles.cartText}>
                        <p
                          /**Code added by Unnati on 28-07-2024
                           *Reason-To fetch cart details when hover on my cart
                           */
                          onMouseEnter={(e) => fetchCartDetails(e)}
                          /**End of code addition by Unnati on 28-07-2024
                           *Reason-To fetch cart details when hover on my cart
                           */
                        >
                          My Cart:
                        </p>

                        <p className={styles.dollar}>$.{calculateSubtotal()}</p>
                      </div>
                      {/**Code added by Unnati on 14-07-2024
                       *Reason-To design My cart dropdown */}
                      <div
                        className={`${styles.cartDropdown} ${
                          cartData &&
                          cartData.length > 0 &&
                          cartData.some(
                            (item) => getFilteredSizes(item).length > 0
                          )
                            ? styles.hasItems
                            : styles.empty
                        }`}
                      >
                        {cartData &&
                        cartData.length > 0 &&
                        cartData.some(
                          (item) => getFilteredSizes(item).length > 0
                        ) ? (
                          <>
                            <ul
                              /* Added by jhamman on 19-10-2024
                          Reason - show cart data according to no. of product*/
                              style={{
                                height:
                                  cartData.some(
                                    (item) =>
                                      getFilteredSizes(item).length === 1
                                  ) &&
                                  cartData.length === 1 &&
                                  "120px",

                                overflowY:
                                  cartData.length === 1 &&
                                  cartData
                                    .map(
                                      (item) => getFilteredSizes(item).length
                                    )
                                    .some((size) => size === 1 || size === 2)
                                    ? "hidden"
                                    : cartData.length === 2 &&
                                      cartData
                                        .map(
                                          (item) =>
                                            getFilteredSizes(item).length
                                        )
                                        .every((size) => size === 1)
                                    ? "hidden"
                                    : "scroll",
                              }}
                              /* End of addition by jhamman on 19-10-2024
                          Reason - show cart data according to no. of product*/

                              /* Commented by jhamman on 19-10-2024
                            Reason - data are not properly rendered in cart*/
                              /**Code added by Unnati  on 12-10-2024
                               *Reason-Added styles  */
                              // style={{
                              //   height: cartData.some(
                              //     (item) => getFilteredSizes(item).length === 1
                              //   )
                              //     ? "120px"
                              //     : "250px",

                              // overflowY: cartData.some(
                              //   (item) => getFilteredSizes(item).length > 2 ? "scroll" : "",
                              // ),
                              // overflowY:
                              //   cartData.length > 2 ? "scroll" : "hidden",
                              // }}
                              /**End of code addition by Unnati  on 12-10-2024
                               *Reason-Added styles  */
                              /* End of commentation by jhamman on 19-10-2024
                            Reason - data are not properly rendered in cart*/
                            >
                            {/* Addition by Om Shrivastava on 03-12-2024
                            Reason : Add the loader  */}
                              {isLoading == true ? (
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      minHeight: "30vh",
                                    }}
                                  >
                                    <img
                                      style={{ height: "30vh" }}
                                      src="adyant_loader.gif"
                                    />
                                    {/* Added by - Ashish Dewangan on 27-11-2024
                                     * Reason - TO show stripe's payment UI */}
                                  </div>
                                </>
                              ) : (
                                <>
                                  {cartData.map((item) => {
                                    return (
                                      <li
                                        key={`${item.product}-${item.size}`}
                                        className={styles.cartItem}
                                      >
                                        {/* Added by jhamman on 23-10-2024
                                      Reason - to show offer percentage*/}
                                        <div
                                          className={`${styles.imageAndOfferLogoContainer}`}
                                        >
                                          {item.sale_percentage ? (
                                            <div
                                              className={styles.offerContainer}
                                            >
                                              <p
                                                className={
                                                  styles.offerPercentage
                                                }
                                              >
                                                -{item.sale_percentage}%
                                              </p>
                                            </div>
                                          ) : null}
                                          {/* End of addition by jhamman on 23-10-2024
                                          Reason - to show offer percentage*/}
                                          <div className={styles.productImage}>
                                            {item && item.image1 ? (
                                              // Added by - Ashlekh on 16-11-2024
                                              // Reason - When user clicks on image then to navigate in product detail page
                                              <Link
                                                to={
                                                  item.is_active
                                                    ? `/productdetail/${
                                                        item.product_id
                                                          ? item.product_id
                                                          : ""
                                                      }`
                                                    : "#"
                                                }
                                                /**Code added by Unnati on 28-11-2024
                                                 *Reason-Sending color through state */
                                                state={item.color}
                                                /**End of code addition by Unnati on 28-11-2024
                                                 *Reason-Sending color through state */
                                              >
                                                {/* End of code - Ashlekh on 16-11-2024
                                                Reason - When user clicks on image then to navigate in product detail page */}
                                                <img
                                                  src={`${config.baseURL}${item.image1}`}
                                                  alt={item.name}
                                                  className={
                                                    styles.productImage
                                                  }
                                                />
                                              </Link>
                                            ) : (
                                              /**Code added by Unnati on 20-10-2024
                                               *Reason-To show availability message */ <p>
                                                Product not available
                                              </p>
                                            )}

                                            {item.is_active ? (
                                              " "
                                            ) : (
                                              <div
                                                className={
                                                  styles.unavailableMessage
                                                }
                                              >
                                                Not available
                                              </div>
                                            )}

                                            {/**End of code addition by Unnati on 20-10-2024
                                             *Reason-To show availability message */}
                                          </div>
                                        </div>

                                        <div className={styles.productInfo}>
                                          {/* Added by - Ashlekh on 16-11-2024
                                          Reason - When user clicks on name then to navigate in product detail page */}
                                          <Link
                                            to={
                                              item.is_active
                                                ? `/productdetail/${
                                                    item.product_id
                                                      ? item.product_id
                                                      : ""
                                                  }`
                                                : "#"
                                            }
                                          >
                                            {/* End of code - Ashlekh on 16-11-2024
                                            Reason - When user clicks on name then to navigate in product detail page */}
                                            <p>{item.name?.substring(0, 60)}</p>
                                            {/* Addition by Om Shrivastava on 21-11-2024
                                            Reason : Add text of customized product  */}
                                            {item.logo ||
                                            item.patches ||
                                            item.security_batches ||
                                            item.embroider ? (
                                              <div
                                                style={{
                                                  color: "#008000",
                                                  fontSize: "10px",
                                                }}
                                              >
                                                Customized product
                                              </div>
                                            ) : null}
                                            {/* End of addition by Om Shrivastava on 21-11-2024
                                            Reason : Add text of customized product  */}
                                          </Link>

                                          <div className={styles.productDetail}>
                                            <div
                                              className={styles.sizeAndQuantity}
                                            >
                                              {/* <p>SIZE: {size}</p>
                                              <p>QTY: {quantity}</p> */}
                                              <p>SIZE: {item.size}</p>
                                              <p>QTY: {item.quantity}</p>
                                              {/**Code added by Unnati on 01-09-2024
                                               *Reason-Added remove button */}
                                              <button
                                                onClick={() =>
                                                  handleDelete(
                                                    item.product,
                                                    item.size,
                                                    item.logo,
                                                    item.patches,
                                                    item.security_batches,
                                                    item.embroider,
                                                    item.color
                                                  )
                                                }
                                              >
                                                {/**End of code addition by Unnati on 01-09-2024
                                                 *Reason-Added remove button */}
                                                Remove
                                              </button>
                                            </div>

                                            {/* Comment by Jhamman on 10-10-2024
                                            Reason - Added discounted price*/}
                                            {/* <div className={styles.price}>
                                              <p>
                                                {CartItem.sales_rate
                                                  ? `$${CartItem.sales_rate}`
                                                  : "Price not available"}
                                              </p>
                                            </div> */}
                                            {/* End of commentation by Jhamman on 10-10-2024
                                            Reason - Added discounted price*/}

                                            {/* Addition by Jhamman on 10-10-2024
                                            Reason- calculate sale price */}

                                            {/* Modified by jhamman on 23-10-2024
                                            Reason - Added diffrent code beacuse we have to show mrp also now */}
                                            {/* {CartItem.sales_rate ? (
                                          <div className={styles.price}>
                                            {CartItem.sale_percentage ? (
                                              <div>
                                                $
                                                {calculateDiscountFromProduct(
                                                  CartItem.sales_rate,
                                                  CartItem.sale_percentage
                                                )}
                                              </div>
                                            ) : (
                                              <div
                                                className={`${styles.price}`}
                                              >
                                                ${CartItem.sales_rate}
                                              </div>
                                            )}
                                          </div>
                                        ) : (
                                          "Price not available"
                                        )} */}
                                            <div className={styles.price}>
                                              {item.sale_percentage ? (
                                                <div
                                                  className={
                                                    styles.discountedPriceText
                                                  }
                                                >
                                                  {/* Modification and addition by Om Shrivastava on 21-11-2024
                                                  Reason : Show the customized price  */}
                                                  {item.logo ||
                                                  item.patches ||
                                                  item.security_batches ||
                                                  item.embroider ? (
                                                    <>
                                                      $
                                                      {
                                                        item?.after_customization_product_price
                                                      }
                                                    </>
                                                  ) : (
                                                    <>
                                                      $
                                                      {calculateDiscountFromProduct(
                                                        item.sales_rate,
                                                        item.sale_percentage
                                                      )}
                                                    </>
                                                  )}
                                                  {/* End of modification and addition by Om Shrivastava on 21-11-2024
                                                  Reason : Show the customized price  */}
                                                </div>
                                              ) : (
                                                <div
                                                  className={
                                                    styles.discountedPriceText
                                                  }
                                                >
                                                  {item.logo ||
                                                  item.patches ||
                                                  item.security_batches ||
                                                  item.embroider ? (
                                                    <>
                                                      $
                                                      {
                                                        item?.after_customization_product_price
                                                      }
                                                    </>
                                                  ) : (
                                                    <>${item.sales_rate}</>
                                                  )}
                                                </div>
                                              )}
                                              {/* End of modification and addition by Om Shrivastava on 21-11-2024
                                                  Reason : Show the customized price  */}
                                              {/* {CartItem.sale_percentage ? (
                                                <div
                                                  className={
                                                    styles.discountedPriceText
                                                  }
                                                >
                                                  $
                                                  {calculateDiscountFromProduct(
                                                    CartItem.sales_rate,
                                                    CartItem.sale_percentage
                                                  )}
                                                </div>
                                              ) : CartItem.show_patches_and_embroider_on_UI ? (
                                                <div
                                                  className={
                                                    styles.discountedPriceText
                                                  }
                                                >
                                                  $
                                                  {calculateDiscountFromProduct(
                                                    CartItem.sales_rate,
                                                    CartItem.sale_percentage
                                                  )}
                                                </div>
                                              ) : (
                                                <div
                                                  className={styles.priceText}
                                                >
                                                  $
                                                  {
                                                    CartItem.after_customization_product_price
                                                  }
                                                </div>
                                              )} */}

                                              {/* End of modification by jhamman on 23-10-2024
                                              Reason - Added diffrent code beacuse we have to show mrp also now */}

                                              {/* /* Added by jhamman on 23-10-2024
                                             Reason - added mrp price*/}
                                              {item.sale_percentage ? ( // Modification by Om Shrivastava on 21-11-2024, Change the sales_rate to sale_perecentage
                                                <div
                                                  className={
                                                    styles.mrpPriceText
                                                  }
                                                  style={
                                                    item.sale_percentage
                                                      ? {
                                                          textDecoration:
                                                            "line-through",
                                                          textDecorationColor:
                                                            "#000",
                                                          color: "red",
                                                        }
                                                      : { color: "green" }
                                                  }
                                                >
                                                  ${item.sales_rate}
                                                </div>
                                              ) : null}
                                            </div>
                                            {/* End of addition by jhamman on 23-10-2024
                                        Reason - added mrp price*/}
                                            {/* End of addition by Jhamman on 10-10-2024
                                            Reason- calculate sale price */}
                                          </div>
                                        </div>
                                      </li>
                                    );
                                  })}
                                </>
                              )}
                              {/* End of addition by Om Shrivastava on 03-12-2024
                            Reason : Add the loader  */}
                            </ul>
                            <div className={styles.cartTotal}></div>
                            <div className={styles.cartActions}>
                              <button
                                className={styles.viewCartButton}
                                onClick={handleViewCart}
                              >
                                View Cart
                              </button>
                              {user.id ? (
                                <Link to="/checkout">
                                  <button
                                    className={styles.checkoutButton}
                                    /**Code added by Unnati on 26-10-2024
                                     *Reason-To add onclick functionality */
                                    onClick={handleCheckoutClick}
                                    /*End of code addition by Unnati on 26-10-2024
                                     *Reason-To add onclick functionality */
                                  >
                                    Checkout
                                  </button>
                                </Link>
                              ) : (
                                // Code changed by - Ashlekh on 25-11-2024
                                // Reason - For guest user, if clicks on Checkout then first user will navigate in login page, after login user will directly navigate to checkout page
                                // <Link to="/login">
                                //   <button className={styles.checkoutButton}>
                                //     Checkout
                                //   </button>
                                // </Link>
                                <button
                                  className={styles.checkoutButton}
                                  onClick={handleLoginWithCheckout}
                                >
                                  Checkout
                                </button>
                                // End of code - Ashlekh on 25-11-2024
                                // Reason - For guest user, if clicks on Checkout then first user will navigate in login page, after login user will directly navigate to checkout page
                              )}
                            </div>
                          </>
                        ) : (
                          <p>No items in the cart.</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/**End of code addition by Unnati on 24-06-2024
           *Reason-To have a header middle */}

          {/**Code added by Unnati on 24-06-2024
           *Reason-To have a header bottom */}
          <div className={styles.headerBottom}>
            <div className={styles.container}>
              <input type="checkbox" id="menu-bar" className={styles.menuBar} />
              <label htmlFor="menu-bar" className={styles.label}>
                <RxHamburgerMenu />
              </label>
              {/**Code added by Unnati on 12-07-2024
               *Reason-To change header styling according to the path */}
              <nav className={`${styles.navbar}`}>
                {/**End of code addition by Unnati on 12-07-2024
                 *Reason-To change header styling according to the path */}

                <ul>
                  {/**Code commented by Unnati on 05-10-2024
                   *Reason-We have these menu already */}
                  {/* <li className={styles.col3Category}>
                    <Link
                      className={styles.allCatBurger}
                      style={{ color: "black", display: "flex" }}
                    >
                      <div className={styles.text}>All Categories</div>
                      <div className={styles.iconWrapper}>
                        <GiHamburgerMenu />
                      </div>
                    </Link>
                    {category && <SubCategories subcategories={category} />}
                  </li> */}
                  {/**End of code comment by Unnati on 05-10-2024
                   *Reason-We have these menu already */}
                  {/**Code added by Unnati on 04-10-2024
                   *Reason-Adding category image along with name */}
                  {/**Code modified by Unnati on 05-10-2024
                   *Reason-To map category*/}

                  {/*Addition by jhamman on 30-10-2024
                   Reason - Added a slider to slide category items */}
                  <div ref={sliderRef} className="keen-slider">
                    {/* End of Addition by jhamman on 30-10-2024
                   Reason - Added a slider to slide category items */}
                    {category?.map((cat, index) => {
                      return (
                        <div key={cat.id} className={`${styles.categories}`}>
                          {/**Code added by Unnati on 05-10-2024
                           *Reason-Added link to product*/}
                          {/**Code added by Unnati on 16-11-2024
                           *Reason-Added condition for coming soon */}
                          {cat.is_coming_soon ? (
                            <Link
                              to={`/coming-soon/`}
                              state={cat.id}
                              className={styles.categoryLink}
                            >
                              <div
                                className="keen-slider__slide"
                                style={{ minWidth: "0px" }}
                              >
                                <div className={`${styles.category}`}>
                                  <div className={`${styles.ImageContainer}`}>
                                    <img
                                      src={`${config.baseURL}${cat.image}`}
                                      alt={cat.name}
                                      className={`${styles.categoryImage}`}
                                    />
                                  </div>
                                  <div className={`${styles.categoryTitle}`}>
                                    <span className={`${styles.Title}`}>
                                      {cat.name}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          ) : (
                            /**End of code addition by Unnati on 16-11-2024
                             *Reason-Added condition for coming soon */
                            <Link
                              to={`/category/`}
                              state={cat.id}
                              className={styles.categoryLink}
                            >
                              <div
                                className="keen-slider__slide"
                                style={{ minWidth: "0px" }}
                              >
                                <div className={`${styles.category}`}>
                                  <div className={`${styles.ImageContainer}`}>
                                    <img
                                      src={`${config.baseURL}${cat.image}`}
                                      alt={cat.name}
                                      className={`${styles.categoryImage}`}
                                    />
                                  </div>
                                  <div className={`${styles.categoryTitle}`}>
                                    <span className={`${styles.Title}`}>
                                      {cat.name}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          )}
                          {/**End of code addition by Unnati on 05-10-2024
                           *Reason-Added link to product*/}
                        </div>
                      );
                    })}
                  </div>

                  {/**End of code modification by Unnati on 05-10-2024
                   *Reason-To map category*/}

                  {/**Code commented by Unnati on 05-10-2024
                   *Reason-Because menu is coming from backend */}
                  {/* <div className={`${styles.category}`}>
                      <div className={`${styles.ImageContainer}`}>
                        <img
                          src="/category3.jpg"
                          alt="category3"
                          className={`${styles.categoryImage}`}
                        />
                      </div>
                      <div className={`${styles.categoryTitle}`}>
                        <span>Women</span>
                      </div>
                    </div>
                    <div className={`${styles.category}`}>
                      <div className={`${styles.ImageContainer}`}>
                        <img
                          src="/category4.jpg"
                          alt="category4"
                          className={`${styles.categoryImage}`}
                        />
                      </div>
                      <div className={`${styles.categoryTitle}`}>
                        <span>Kids</span>
                      </div>
                    </div>

                    <div className={`${styles.category}`}>
                      <div className={`${styles.ImageContainer}`}>
                        <img
                          src="/category6.jpg"
                          alt="category6"
                          className={`${styles.categoryImage}`}
                        />
                      </div>
                      <div className={`${styles.categoryTitle}`}>
                        <span>Shoes</span>
                      </div>
                    </div>
                    <div className={`${styles.category}`}>
                      <div className={`${styles.ImageContainer}`}>
                        <img
                          src="/category7.png"
                          alt="category7"
                          className={`${styles.categoryImage}`}
                        />
                      </div>
                      <div className={`${styles.categoryTitle}`}>
                        <p>Uniform</p>
                      </div>
                    </div>
                    <div className={`${styles.category}`}>
                      <div className={`${styles.ImageContainer}`}>
                        <img
                          src="/category5.jpg"
                          alt="category5"
                          className={`${styles.categoryImage}`}
                        />
                      </div>
                      <div className={`${styles.categoryTitle}`}>
                        <span>Home Decor</span>
                      </div>
                    </div>
                    <div className={`${styles.category}`}>
                      <div className={`${styles.ImageContainer}`}>
                        <img
                          src="/category5.jpg"
                          alt="category5"
                          className={`${styles.categoryImage}`}
                        />
                      </div>
                      <div className={`${styles.categoryTitle}`}>
                        <span>Home Decor</span>
                      </div>
                    </div>
                    <div className={`${styles.category}`}>
                      <div className={`${styles.ImageContainer}`}>
                        <img
                          src="/category5.jpg"
                          alt="category5"
                          className={`${styles.categoryImage}`}
                        />
                      </div>
                      <div className={`${styles.categoryTitle}`}>
                        <span>Home Decor</span>
                      </div>
                    </div>
                    <div className={`${styles.category}`}>
                      <div className={`${styles.ImageContainer}`}>
                        <img
                          src="/category5.jpg"
                          alt="category5"
                          className={`${styles.categoryImage}`}
                        />
                      </div>
                      <div className={`${styles.categoryTitle}`}>
                        <span>Home Decor</span>
                      </div>
                    </div>
                    <div className={`${styles.category}`}>
                      <div className={`${styles.ImageContainer}`}>
                        <img
                          src="/salebanner.PNG"
                          alt="category5"
                          className={`${styles.categoryImage}`}
                        />
                      </div>
                      <div className={`${styles.categoryTitle}`}>
                        <span>Sale</span>
                      </div>
                    </div>
                    <div className={`${styles.category}`}>
                      <div className={`${styles.ImageContainer}`}>
                        <img
                          src="/watch.jpg"
                          alt="category5"
                          className={`${styles.categoryImage}`}
                        />
                      </div>
                      <div className={`${styles.categoryTitle}`}>
                        <span>Watch</span>
                      </div>
                    </div>
                    <div className={`${styles.category}`}>
                      <div className={`${styles.ImageContainer}`}>
                        <img
                          src="/sunglasses.jpg"
                          alt="category5"
                          className={`${styles.categoryImage}`}
                        />
                      </div>
                      <div className={`${styles.categoryTitle}`}>
                        <span>Sunglasses</span>
                      </div>
                    </div>
                    <div className={`${styles.category}`}>
                      <div className={`${styles.ImageContainer}`}>
                        <img
                          src="/bag.jpg"
                          alt="category5"
                          className={`${styles.categoryImage}`}
                        />
                      </div>
                      <div className={`${styles.categoryTitle}`}>
                        <span>Bag</span>
                      </div>
                    </div>
                  </div> */}
                  {/**End of code comment by Unnati on 05-10-2024
                   *Reason-Because menu is coming from backend */}
                  {/**End of code addition by Unnati on 04-10-2024
                   *Reason-Adding category image along with name */}
                  {/**Code commented by Unnati on 04-10-2024
                   *Reason-This part of the header is not in use */}
                  {/* <div className={styles.productType}>
                    <li className={styles.brands}> */}
                  {/**Code added by Unnati on 12-09-2024
                   *Reason-Added css to increase padding */}
                  {/* <Link className={styles.brandLink}> */}
                  {/**End of code addition by Unnati on 12-09-2024
                   *Reason-Added css to increase padding */}
                  {/* BRANDS */}
                  {/**Code added by Unnati on 03-09-2024
                   *Reason-To showdropdown icon only when it has sub menus */}
                  {/**Code added by Unnati on 12-09-2024
                   *Reason-Added css  */}
                  {/* {brand && brand.length > 0 && (
                          <FaChevronDown
                            size={10}
                            className={styles.dropdownArrow}
                          />
                        )} */}
                  {/**End of code additionby Unnati on 12-09-2024
                   *Reason-Added css  */}
                  {/**End of code addition by Unnati on 03-09-2024
                   *Reason-To showdropdown icon only when it has sub menus */}
                  {/* </Link> */}

                  {/**Code commented by Unnati on 03-07-2024
                   *Reason-This code is not in use currently due to some backend error */}

                  {/* <ul className={styles.brandDropdown}>
                        {brand.map((brand) => (
                          <li key={brand.id}>
                            <Link to={`/brand/${brand.id}`}>
                              <img
                                src={config.baseURL + brand.image}
                                alt={brand.name}
                                className={styles.brandImage}
                              />
                              <span className={styles.brandName}>
                                {brand.text}
                              </span>
                            </Link>
                          </li>
                        ))}
                      </ul> */}

                  {/**End of code comment by Unnati on 03-07-2024
                   *Reason-This code is not in use currently due to some backend error */}
                  {/* </li> */}
                  {/**Code added by Unnati on 10-08-2024
                   *Reason-Added security dropdown */}
                  {/* <li> */}
                  {/**Code added by Unnati on 12-09-2024
                   *Reason-Added css to increase padding */}
                  {/* <Link className={styles.brandLink}> */}
                  {/**End of code addition by Unnati on 12-09-2024
                   *Reason-Added css to increase padding */}
                  {/* SECURITY */}
                  {/**Code added by Unnati on 03-09-2024
                   *Reason-To showdropdown icon only when it has sub menus */}
                  {/* {dropdown.security && dropdown.security.length > 0 && (
                          <FaChevronDown
                            className={styles.dropdownArrow}
                            size={10}
                          />
                        )} */}
                  {/**End of code addition by Unnati on 03-09-2024
                   *Reason-To showdropdown icon only when it has sub menus */}
                  {/* </Link>
                      <ul className={styles.securityDropdown}>
                        {dropdown.security &&
                          dropdown.security.map((security) => (
                            <li key={security.id}>
                              <Link
                                to={`/category/${security.id}?item_type=security`}
                              >
                                <span className={styles.brandName}>
                                  {security.name}
                                </span>
                              </Link>
                            </li>
                          ))}
                      </ul>
                    </li> */}
                  {/**End of code addition by Unnati on 10-08-2024
                   *Reason-Added security dropdown */}
                  {/**Code added by Unnati on 10-08-2024
                   *Reason-Added Police dropdown */}
                  {/* <li>
                      <Link className={styles.brandLink}>
                        POLICE */}
                  {/**Code added by Unnati on 03-09-2024
                   *Reason-To showdropdown icon only when it has sub menus */}
                  {/* {dropdown.police && dropdown.police.length > 0 && (
                          <FaChevronDown
                            size={10}
                            className={styles.dropdownArrow}
                          />
                        )} */}
                  {/**End of code addition by Unnati on 03-09-2024
                   *Reason-To showdropdown icon only when it has sub menus */}
                  {/* </Link> */}
                  {/* <ul className={styles.securityDropdown}>
                        {dropdown.police &&
                          dropdown.police.map((police) => (
                            <li key={police.id}>
                              <Link
                                to={`/category/${police.id}?item_type=police`}
                              >
                                <span className={styles.brandName}>
                                  {police.name}
                                </span>
                              </Link>
                            </li>
                          ))}
                      </ul>
                    </li> */}
                  {/**End of code addition by Unnati on 10-08-2024
                   *Reason-Added Police dropdown */}
                  {/**Code added by Unnati on 10-08-2024
                   *Reason-Added Sheriff dropdown */}
                  {/* <li>
                      <Link className={styles.brandLink}>
                        SHERIFF */}
                  {/**Code added by Unnati on 03-09-2024
                   *Reason-To showdropdown icon only when it has sub menus */}
                  {/* {dropdown.sheriff && dropdown.sheriff.length > 0 && (
                          <FaChevronDown
                            size={10}
                            className={styles.dropdownArrow}
                          />
                        )} */}
                  {/**End of code addition by Unnati on 03-09-2024
                   *Reason-To showdropdown icon only when it has sub menus */}
                  {/* </Link>
                      <ul className={styles.securityDropdown}>
                        {dropdown.sheriff &&
                          dropdown.sheriff.map((sheriff) => (
                            <li key={sheriff.id}>
                              <Link
                                to={`/category/${sheriff.id}?item_type=sheriff`}
                              >
                                <span className={styles.brandName}>
                                  {sheriff.name}
                                </span>
                              </Link>
                            </li>
                          ))}
                      </ul>
                    </li> */}
                  {/**End of code addition by Unnati on 10-08-2024
                   *Reason-Added Sheriff dropdown */}
                  {/**Code added by Unnati on 10-08-2024
                   *Reason-Added fire dropdown */}
                  {/* <li>
                      <Link className={styles.brandLink}>
                        FIRE */}
                  {/**Code added by Unnati on 03-09-2024
                   *Reason-To showdropdown icon only when it has sub menus */}
                  {/* {dropdown.fire && dropdown.fire.length > 0 && (
                          <FaChevronDown
                            size={10}
                            className={styles.dropdownArrow}
                          />
                        )} */}
                  {/**End of code addition by Unnati on 03-09-2024
                   *Reason-To showdropdown icon only when it has sub menus */}
                  {/* </Link> */}
                  {/* <ul className={styles.securityDropdown}>
                        {dropdown.fire &&
                          dropdown.fire.map((fire) => (
                            <li key={police.id}>
                              <Link to={`/category/${fire.id}`}>
                                <span className={styles.brandName}>
                                  {fire.name}
                                </span>
                              </Link>
                            </li>
                          ))}
                      </ul>
                    </li> */}
                  {/**End of code addition by Unnati on 10-08-2024
                   *Reason-Added fire dropdown */}
                  {/**Code added by Unnati on 10-08-2024
                   *Reason-Added help dropdown */}
                  {/* <li className={styles.help}>
                      <Link className={styles.brandLink}>
                        HELP
                        <FaChevronDown
                          size={10}
                          className={styles.dropdownArrow}
                        />
                      </Link>
                      <ul className={styles.helpDropdown}>
                        <li>
                          <span className={styles.linkName}>
                            <Link to="/contactus">Contact Us</Link>
                          </span>
                        </li>
                        <li>
                          <span className={styles.linkName}>
                            <Link to="/faq">FAQ</Link>
                          </span>
                        </li>
                        <li>
                          <span className={styles.linkName}>
                            <Link to="/shipping">Shipping</Link>
                          </span>
                        </li>

                        <li>
                          <span className={styles.linkName}>
                            <Link to="/help">Help</Link>
                          </span>
                        </li>

                        <li>
                          <span className={styles.linkName}>
                            <Link to="/bigAndTall">Big and Tall</Link>
                          </span>
                        </li>
                      </ul>
                    </li>
                  </div> */}
                  {/**End of code addition by Unnati on 10-08-2024
                   *Reason-Added help dropdown */}
                  {/**End of code comment by Unnati on 04-10-2024
                   *Reason-This part of the header is not in use */}
                </ul>
              </nav>
            </div>
          </div>

          {/**End of code addition by Unnati on 24-06-2024
           *Reason-To have a header bottom */}
        </header>
      </div>
    </>
  );
};

export default Header1;
/**End of code addition Unnati on 01-06-2024
 *Reason -To have header
 */
