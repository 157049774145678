/**Code added by Unnati on 13-07-2024
 * Reason-To have view cart page
 */
import React, { useEffect, useState, useContext } from "react";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { Link } from "react-router-dom";
import "react-country-state-city/dist/react-country-state-city.css";
import styles from "./ViewCart.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../context/Context";
import config from "../../Api/config";
import { MdEdit, MdInfo } from "react-icons/md";
import { removeItem, clearCart, getCartItem, updateCartQuantityAPI } from "../../Api/services";
import NavigationPath from "../../components/NavigationPath/NavigationPath";
import { calculateDiscountFromProduct } from "../../utils/discountedPrices";
import { FaEye } from "react-icons/fa";
import { Modal } from "antd";

const ViewCart = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedCountry, setSelectedCountry] = useState("US");
  const [selectedState, setSelectedState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const { cartData, user, setCartData } = useContext(GlobalContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [cartItem, setCartItem] = useState([]);
  // Added by - Om Shrivastava on 05-12-2024
  // Reason - To have useState for storing quantity
  const [quantities, setQuantities] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  // End of code - Om Shrivastava on 05-12-2024
  // Reason - To have useState for storing quantity
  /**Code added by Unnati on 03-10-2024
   * Reason-To get totalItemCount and subtotal through location
   */
  // Modification and addition by Om Shrivastava on 05-12-2024
  // Reason : Show the total no of products and grand total amount
  // const { totalItemCount, subtotal } = location.state || {
  //   totalItemCount: 0,
  //   subtotal: 0,
  // };
  const getTotalItemCount = (cartData) => {
    let totalCount = 0;

    for (let i = 0; i < cartData.length; i++) {
      const item = cartData[i];
      totalCount += parseInt(item.quantity ? item.quantity : 0);
    }

    return totalCount;
  };

  const totalItemCount = getTotalItemCount(cartData);

  const calculateSubtotal = () => {
    if (!Array.isArray(cartData)) {
      return 0;
    }
    var total = 0;
    cartData.forEach((item) => {
      const price = item.sales_rate
        ? item.sale_percentage
          ? calculateDiscountFromProduct(item.sales_rate, item.sale_percentage)
          : item.sales_rate
        : 0;

      const finalPrice =
        item.logo || item.patches || item.security_batches || item.embroider
          ? item.after_customization_product_price || price
          : price;

      total += finalPrice * item.quantity;
    });

    return total;
  };

  const grand_total = calculateSubtotal();
  // End of modification and addition by Om Shrivastava on 05-12-2024
  // Reason : Show the total no of products and grand total amount

  /**End of code addition by Unnati on 03-10-2024
   * Reason-To get totalItemCount and subtotal through location
   */

  /**Code added by Unnati on 19-07-2024
   * Reason-To get cart items from header(Mycart dropdown)
   */
  const cart = location.state?.cart;
  /**End of code addition by Unnati on 19-07-2024
   * Reason-To get cart items from header(Mycart dropdown)
   */
  const openModal = () => {
    setIsModalOpen(true);
  };
  const confirmClear = () => {
    handleClearButton();
    setIsModalOpen(false);
  };
  const cancelClear = () => {
    setIsModalOpen(false);
  };
  /**Code added by Unnati on 28-08-2024
   * Reason-To have navigation path
   */
  const { navigationPath, setNavigationPath } = useContext(GlobalContext);
  useEffect(() => {
    setNavigationPath([
      { name: "Home", path: "/" },
      { name: "View Cart", path: "/viewcart" },
    ]);
    window.scrollTo(0, 0);
  }, [setNavigationPath]);
  /**End of code addition by Unnati on 28-08-2024
   * Reason-To have navigation path
   */
  /**Code added by Unnati Bajaj on 14-07-2024
   * Reason -To scroll to the top when component loads
   */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  /**End of code addition by Unnati Bajaj on 14-07-2024
   * Reason -To scroll to the top when component loads
   */
  /**Code commented by Unnati on 15-07-2024
   * Reason-To handle add discount coupon dropdown
   */
  // const handleDiscountDropdown = () => {
  //   setIsDropdownOpen(!isDropdownOpen);
  // };
  /**End of code comment by Unnati on 15-07-2024
   * Reason-To handle add discount coupon dropdown
   */
  /**Code commented by Unnati on 15-07-2024
   * Reason-To handle shipping details dropdown
   */
  // const handleShippingDropdown = () => {
  //   setIsOpen(!isOpen);
  // };
  /**End of code comment by Unnati on 15-07-2024
   * Reason-To handle shipping details dropdown
   */
  /**Code commenetd by  Unnati on 14-07-2024
   * Reason-This code is not in use as i am getting filtered products from header
   */
  // const cartProducts = Array.isArray(cartData)
  //   ? cartData.map((item) => {
  //       const { productId, product, ...sizes } = item;
  //       const sizeQuantity = Object.entries(sizes).filter(
  //         ([key, value]) => key !== 'id' && key !== 'user' && value !== null
  //       );
  //       const productDetails = productList.find(
  //         (p) => p.id === (productId || product)
  //       );
  //       return {
  //         productDetails,
  //         sizeQuantity,
  //       };
  //     })
  //   : [];
  /**End of code commented by  Unnati on 14-07-2024
   * Reason-This code is not in use as i am getting filtered products from header
   */

  /**Code commented by Unnati on 15-07-2024
   * Reason-This code is not in use currently
   */
  // const handleZipChange = (e) => {
  //   setZip(e.target.value);
  // };
  /**End of code commented by Unnati on 15-07-2024
   * Reason-This code is not in use currently
   */
  /**Code commented by Unnati on 19-07-2024
   * Reason-To make state dropdown
   */
  // const StateDropdown = ({ countryCode = "US", onStateChange }) => {
  //   const options = State.getStatesOfCountry(countryCode).map((state) => ({
  //     value: state.isoCode,
  //     displayValue: `${state.name} - ${state.isoCode}`,
  //   }));

  //   const handleStateChange = (event) => {
  //     onStateChange(event.target.value);
  //   };

  //   return (
  //     <select className={styles.dropdown} onChange={handleStateChange}>
  //       {options.map((option, index) => (
  //         <option key={index} value={option.value}>
  //           {option.displayValue}
  //         </option>
  //       ))}
  //     </select>
  //   );
  // };
  /**End of code comment by Unnati on 19-07-2024
   * Reason-To make state dropdown
   */
  /**Code commented by Unnati on 19-07-2024
   * Reason-To handle state dropdown
   */
  // const handleStateChange = (state) => {
  //   setSelectedState(state);
  // };

  /*End of code comment by Unnati on 19-07-2024
   * Reason-To handle state dropdown
   */
  /**Code added by Unnati on 19-07-2024
   * Reason-To assign variable with country code and name
   */
  const countries = [{ code: "US", name: "United States" }];
  /**end of code addition by Unnati on 19-07-2024
   * Reason-To assign variable with country code and name
   */
  /**Code added by Unnati on 22-07-2024
   * Reason-To handle checkout button and send data from viewcart page to checkout page
   */
  const handleCheckout = (e) => {
    e.preventDefault();
    /**Code added by Unnati on 14-08-2024
     * Reason-Added condition to check whether user is logged in or not
     */
    if (user.id) {
      navigate("/checkout", {
        /**Code added by Unnati on 04-09-2024
         * Reason-Added replace
         */
        state: {
          cart,
          selectedCountry,
          selectedState,
          zipCode,
          totalItemCount,
          // Modification and addition by Om Shrivastava on 05-12-2024
          // Reason : Show the grand total amount
          // subtotal,
          grand_total
          // End of modification and addition by Om Shrivastava on 05-12-2024
          // Reason : Show the grand total amount
        },

        /**End of code addition by Unnati on 04-09-2024
         * Reason-Added replace
         */
      });
    } else {
      navigate("/login", {
        state: {
          from: "/checkout",
          cart,
          selectedCountry,
          selectedState,
          zipCode,
        },
      });
    }
    /**End of code addition by Unnati on 14-08-2024
     * Reason-Added condition to check whether user is logged in or not
     */
  };
  /**End of code addition by Unnati on 22-07-2024
   * Reason-To handle checkout button and send data from viewcart page to checkout page
   */
  /**Code added by Unnati on 22-07-2024
   * Reason-To initialise subtotal variable as 0
   */
  let calculatedSubtotal = 0;
  /*End of code addition by Unnati on 22-07-2024
   * Reason-To initialise subtotal variable as 0
   */
  /**Code commented by Unnati on 22-07-2024
   * Reason-To handle country field
   */
  // const handleCountryChange = (event) => {
  //   setSelectedCountry(event.target.value);
  // };
  /**End of code comment by Unnati on 22-07-2024
   * Reason-To handle country field
   */
  /**Code commented by Unnati on 22-07-2024
   * Reason-To handle zipcode field
   */
  // const handleZipCodeChange = (event) => {
  //   setZipCode(event.target.value);
  // };
  /*End of code comment by Unnati on 22-07-2024
   * Reason-To handle zipcode field
   */
  /**Code added by Unnati on 25-07-2024
   * Reason-To get filtered sizes from cart items
   */
  const getFilteredSizes = (item) => {
    const sizeKeys = ["XS", "S", "M", "L", "XL", "XXL", "XXXL"];
    const filterSizeKeys = sizeKeys.filter((key) => item[key] !== null || 0);
    const mapSize = filterSizeKeys.map((key) => [key, item[key]]);
    return mapSize;
  };
  /**End of code addition by Unnati on 25-07-2024
   * Reason-To get filtered sizes from cart items
   */
  /**Code added by Unnati on 25-08-2024
   * Reason-To handle delete button in shopping cart
   */
  const handleDelete = (product, size,
    // Addition by Om Shrivastava on 04-12-2024
    // Reason : Add the customized field also 
    logo,
    patches,
    security_batches,
    embroider,
    color,
    // End of addition by Om Shrivastava on 04-12-2024
    // Reason : Add the customized field also
  ) => {
    if (user.id) {
      const deleteProduct = async () => {
        try {
          const data = await removeItem(user.id, product, size,
            // Addition by Om Shrivastava on 04-12-2024
            // Reason : Add the customized field also 
            logo,
            patches,
            security_batches,
            embroider,
            color,
            // End of addition by Om Shrivastava on 04-12-2024
            // Reason : Add the customized field also
          );
          localStorage.setItem("cartData", JSON.stringify(data.cartItem));
          setCartData(data.cartItem);
        } catch (error) {
          console.error(error.message);
        }
      };
      deleteProduct();
      /**End of code addition by Unnati on 27-07-2024
       * Reason-To delete item from backend when user is logged in
       */
    } else {
      // Modification and addition by Om Shrivastava on 04-12-2024
      // Reason : Handle the customized functionality 
      // const updatedData = cartData
      //   .map((item) => {
      //     if (item.product === product) {
      //       if (item[size] !== null) {
      //         const updatedItem = { ...item, [size]: null };
      //         const remainingSizes = Object.values(updatedItem);
      //         const filterRemainingSizes = remainingSizes.filter(
      //           (value) => value !== null
      //         );
      //         const remainingSizesLength = filterRemainingSizes.length;
      //         return remainingSizesLength > 1 ? updatedItem : null;
      //       }
      //     }

      //     return item;
      //   })
      //   .filter((item) => item !== null);
      // localStorage.setItem("cartData", JSON.stringify(updatedData));
      // setCartData(updatedData);
      const updatedData = cartData
        .map((item) => {
          if (
            item.product === product &&
            item.logo === logo &&
            item.patches === patches &&
            item.security_batches === security_batches &&
            item.embroider === embroider &&
            item.size === size &&
            item.color === color
          ) {
            return null;
          } else {
            return item;
          }
        })
        .filter((item) => item !== null);
      localStorage.setItem("cartData", JSON.stringify(updatedData));
      setCartData(updatedData);
    }
    // End of modification and addition by Om Shrivastava on 04-12-2024
    // Reason : Handle the customized functionality
  };
  /**End of code addition by Unnati on 25-08-2024
   * Reason-To handle delete button in shopping cart
   */

  /**Code added by Unnati on 30-08-2024
   * Reason-To handle clear button
   */
  const handleClearButton = async () => {
    if (user.id) {
      const response = await clearCart(user.id);
      localStorage.removeItem("cartData");
      setCartData([]);
      if (response.message) {
        setCartData([]);
      } else {
        console.error("Failed to clear the cart:", response);
      }
    } else {
      localStorage.removeItem("cartData");
      setCartData([]);
    }
  };
  /**End of code addition by Unnati on 30-08-2024
   * Reason-To handle clear button
   */
  /**Code added by Unnati on 30-08-2024
   * Reason-To handle delete button
   */
  const handleDeleteClick = (product, size,
    // Addition by Om Shrivastava on 04-12-2024
    // Reason : Add the customized field also 
    logo,
    patches,
    security_batches,
    embroider,
    color,
    // End of addition by Om Shrivastava on 04-12-2024
    // Reason : Add the customized field also
  ) => {
    setItemToDelete({ product, size,
      // Addition by Om Shrivastava on 04-12-2024
    // Reason : Add the customized field also 
    logo,
    patches,
    security_batches,
    embroider,
    color,
    // End of addition by Om Shrivastava on 04-12-2024
    // Reason : Add the customized field also
     });
    setIsModalVisible(true);
  };
  /**End of code addition by Unnati on 30-08-2024
   * Reason-To handle delete button
   */
  const confirmDelete = () => {
    if (itemToDelete) {
      handleDelete(itemToDelete.product, itemToDelete.size,
      // Addition by Om Shrivastava on 04-12-2024
      // Reason : Add the customized field also 
      itemToDelete.logo,
      itemToDelete.patches,
      itemToDelete.security_batches,
      itemToDelete.embroider,
      itemToDelete.color,
    // End of addition by Om Shrivastava on 04-12-2024
    // Reason : Add the customized field also
      );
      setItemToDelete(null);
    }
    setIsModalVisible(false);
  };
  /**Code added by Unnati on 30-08-2024
   * Reason-To cancel cancel delete button
   */
  const cancelDelete = () => {
    setItemToDelete(null);
    setIsModalVisible(false);
  };
  /**End of code addition by Unnati on 30-08-2024
   * Reason-To cancel cancel delete button
   */
  /**Code added by Unnati on 22-09-2024
   * Reason-To scroll page to the top
   */
  const scrollDoc = () => {
    window.scrollTo(0, 0);
  };
  /**End of code addition by Unnati on 22-09-2024
   * Reason-To scroll page to the top
   */
  /**Code added by Unnati on 09-10-2024
   * Reason-To get product details
   */
  const fetchCartDetails = async () => {
    try {
      const product = cartData.map((item) => item.product);
      const response = await getCartItem(product);
      setCartItem(response.products);
    } catch (error) {
      console.error("Error fetching cart details:", error);
    }
  };
  useEffect(() => {
    fetchCartDetails();
  }, [cartData]);
  /**End of code addition by Unnati on 09-10-2024
   * Reason-To get product details
   */
  // Addition by Om Shrivastava on 02-12-2024
  // Reason : Show the total price of Customized amount
  const calculateTotalCustomizedPrice = (CartItem) => {
    let total = 0;
    if (CartItem.logo) total += parseFloat(CartItem.logo_price) || 0;
    if (CartItem.patches) total += parseFloat(CartItem.patches_price) || 0;
    if (CartItem.embroider) total += parseFloat(CartItem.embroider_price) || 0;
    if (CartItem.security_batches) total += parseFloat(CartItem.security_batches_price) || 0;
    return total;
  };
  // End of addition by Om Shrivastava on 02-12-2024
  // Reason : Show the total price of Customized amount
  
  const handleQuantityChange = async (
    userId,
    productId,
    currentQuantity,
    size,
    color,
    logo,
    patches,
    security_batches,
    embroider,
    action
  ) => {
    let newQuantity = currentQuantity;
    console.log(newQuantity,currentQuantity,'checkkkk')
    if (userId == null && userId == undefined) {
      const cartData = JSON.parse(localStorage.getItem("cartData")) || [];
      const updatedCartData = cartData?.map((item) => {
        const matchedProduct =
          item.product == productId &&
          item.size == size &&
          item.color == color && 
          item.logo == logo&&
          item.patches == patches&&
          item.security_batches == security_batches&&
          item.embroider == embroider;
        if (matchedProduct) {
          return { ...item, quantity: newQuantity };
        } else {
          return item;
        }
      });
      localStorage.setItem("cartData", JSON.stringify(updatedCartData));
      setCartData(updatedCartData);
    } else {
      if (action == "increase") {
        newQuantity = currentQuantity + 1;
      } else if (action == "decrease" && currentQuantity > 1) {
        newQuantity = currentQuantity - 1;
      }
      // Modification and addition by Om Shrivastava on 04-12-2024
      // Reason : Send the customized details 
    //   const response = await updateCartQuantityAPI(
    //     userId,
    //     productId,
    //     newQuantity,
    //     size,
    //     color,
    //     logo,patches,security_batches,embroider
    //   );
    //   console.log(response);
    //   if (response?.message == "success") {
    //     localStorage.setItem(
    //       "cartData",
    //       JSON.stringify(response?.updated_cart_data)
    //     );
    //     setCartData(response?.updated_cart_data);
    //     // Added by - Ashlekh on 30-11-2024
    //     // Reason - To remove stock validation message if product quantity is available
    //     setStockValidationMessage({});
    //     // End of code - Ashlekh on 30-11-2024
    //     // Reason - To remove stock validation message if product quantity is available
    //   } else if (response?.message == "quantity_exceeds") {
    //     // Added by - Ashlekh on 30-11-2024
    //     // Reason - To display stock validation message if product quantity is not available
    //     const updatedCartData = response?.updated_cart_data;
    //     setStockValidationMessage((prevMessages) => {
    //       if (response?.available_quantity == 0 || response?.available_quantity == "Stock Not available") {
    //         return {
    //           ...prevMessages,
    //           [`${productId}-${size}-${color}`]: `This product is out of stock for ${size}. Please remove this product.`,
    //         };
    //       } else{
    //         localStorage.setItem(
    //           "cartData",
    //           JSON.stringify(response?.updated_cart_data)
    //         );
    //         setCartData(response?.updated_cart_data);
    //         return {
    //           ...prevMessages,
    //           [`${productId}-${size}-${color}`]: `Only ${response.available_quantity} item(s) available in ${size}`,
    //         };
    //       }
    //     });
    //     // End of code - Ashlekh on 30-11-2024
    //     // Reason - To display stock validation message if product quantity is not available
    //     // Commented by - Ashlekh on 30-11-2024
    //     // Reason - No need to display stock validation message in popup
    //     // setErrorMessage("Quantity exceeds");
    //     // setErrorModalVisible(true);
    //     // End of comment - Ashlekh on 30-11-2024
    //     // Reason - No need to display stock validation message in popup
    //   }
    // }
    const response = await updateCartQuantityAPI(
      userId,
      productId,
      newQuantity,
      size,
      color,
      logo,patches,security_batches,embroider
    );
    console.log(response);
    if (response?.message == "success") {
      localStorage.setItem(
        "cartData",
        JSON.stringify(response?.updated_cart_data)
      );
      setCartData(response?.updated_cart_data);
    } else if (response?.message == "quantity_exceeds") {
      setErrorMessage("Quantity exceeds");
      setErrorModalVisible(true);
    }
  }
   // End of modification and addition by Om Shrivastava on 04-12-2024
      // Reason : Send the customized details 
  };
  const handleErrorModal = () => {
    setErrorModalVisible(false);
    setErrorMessage("");
  };

  return (
    /*Code added by Unnati on 13-07-2024
     *Reason-To make structure of the shopping cart
     */
    <div className={styles.pageMain}>
      <div className={styles.pageContent}>
        {/**Code added by Unnati on 28-08-2024
         * Reason-To have navigation path
         */}
        <NavigationPath navigationPathArray={navigationPath} />
        {/**End of code addition by Unnati on 28-08-2024
         * Reason-To have navigation path
         */}
        <div className={styles.title}>
          <h2 className={styles.pageHeading}>Shopping Cart</h2>
        </div>
        {/**Code added by Unnati on 03-10-2024
         *Reason-To show total product count and grandtotal at the top */}

        {/**End of code addition by Unnati on 03-10-2024
         *Reason-To show total product count and grandtotal at the top */}
        {cartData &&
        cartData.length > 0 &&
        cartData.some((item) => getFilteredSizes(item).length > 0) ? (
          <div className={styles.columnMain}>
            <div className={styles.productDetails}>
              <div className={styles.productCount}>
                <p>Total Products:{totalItemCount}</p>
                <p>Grand Total:
                {/* ${subtotal} */}
                ${grand_total}
                </p>
              </div>
              <table className={styles.table}>
                <thead>
                  <tr>
                    {/**Code added by Unnati on 27-10-2024
                     *Reason-To change name */}
                    <th className={styles.itemColumn}>PRODUCT NAME</th>
                    {/* <th className={styles.itemColumn}>ITEM</th> */}
                    {/*End of code addition by Unnati on 27-10-2024
                     *Reason-To change name */}
                     {/* Addition by Om Shrivastava on 02-12-2024
                     Reason : Show the customization field with their amount  */}
                    <th className={styles.customizedColumn}>Customization Amount</th>
                    {/* End of addition by Om Shrivastava on 02-12-2024
                     Reason : Show the customization field with their amount  */}

                    <th className={styles.priceColumn}>PRICE</th>
                    <th className={styles.qtyColumn}>QTY</th>
                    <th className={styles.subtotalColumn}>SUBTOTAL</th>
                  </tr>
                </thead>
                <tbody>
                  {/**Code added by Unnati on 19-07-2024
                   *Reason-To map cart items to their respective columns(First generating separate row for each size) */}

                  <>
                    {cartData.map((item, index) =>
                      getFilteredSizes(item).map(([size, quantity]) => {
                        /**Code added by Unnati on 14-08-2024
                         *Reason-Calculated sales rate and subtotal  */
                        const salesRate = item.sales_rate;
                        const subtotal = salesRate ? salesRate * quantity : 0;
                        calculatedSubtotal += subtotal;
                        /**End of code addition by Unnati on 14-08-2024
                         *Reason-Calculated sales rate and subtotal  */
                         {/* Addition by Om Shrivastava on 02-12-2024
                         Reason : Mapped with their id  */}
                        {/* const CartItem =
                          cartItem.find(
                            (product) => product.id === item.product
                          ) || {}; */}
                          // const CartItem =
                          // cartItem.find(
                          //   (product) => product.id === item.id
                          // ) || {};
                           {/* End of addition by Om Shrivastava on 02-12-2024
                         Reason : Mapped with their id  */}

                        return (
                          <React.Fragment key={`${item.productId}-${size}`}>
                            <tr>
                              <td className={styles.itemColumn}>
                                <div className={styles.imageAndName}>
                                  {/**Code added by Unnati on 25-07-2024
                                   *Reason-This code is not in use  */}
                                  <Link
                                    to={
                                      item.is_active
                                        ? `/productdetail/${
                                            item.product_id
                                              ? parseInt(item.product_id)
                                              : ""
                                          }`
                                        : "#"
                                    }
                                  >
                                    <img
                                      className={styles.img}
                                      // src={`${config.baseURL}${CartItem.image1}`}
                                      src={`${config.baseURL}${item.image1}`}
                                      alt=""
                                    />
                                  </Link>
                                  {/**End of code addition by Unnati on 25-07-2024
                                   *Reason-This code is not in use  */}
                                  <div className={styles.productInfo}>
                                    <p>
                                      <Link
                                        to={
                                          item.is_active
                                            ? `/productdetail/${
                                                item.product_id
                                                  ? parseInt(item.product_id)
                                                  : ""
                                              }`
                                            : "#"
                                        }
                                        className={styles.itemName}
                                      >
                                        {/**Code added by Unnati on 25-07-2024
                                         *Reason-This code is not in use  */}
                                        {item.name}
                                        {/**End of code addition by Unnati on 25-07-2024
                                         *Reason-This code is not in use  */}
                                      </Link>
                                    </p>
                                    {/* Addition by Om Shrivastava on 26-11-2024
                                            Reason : Add text of customized product  */}
                                    {/* Modification and addition by Om Shrivastava on 02-12-2024
                                    Reason : Show the customized field  */}
                                    {/* Commented by Om Shrivastava on 04-12-2024
                                    Reason : No need to show the customized field in this column */}
                                    {/* {CartItem.logo  ? (
                                      <div
                                        style={{
                                          color: "#008000",
                                          fontSize: "10px",
                                        }}
                                      >
                                        Logo 
                                      </div>
                                    ) : null}
                                    {CartItem.patches  ? (
                                      <div
                                        style={{
                                          color: "#008000",
                                          fontSize: "10px",
                                        }}
                                      >
                                        Patches
                                      </div>
                                    ) : null}
                                    {CartItem.embroider ? (
                                      <div
                                        style={{
                                          color: "#008000",
                                          fontSize: "10px",
                                        }}
                                      >
                                        Embroider
                                      </div>
                                    ) : null}
                                    {CartItem.security_batches ? (
                                      <div
                                        style={{
                                          color: "#008000",
                                          fontSize: "10px",
                                        }}
                                      >
                                        Security id
                                      </div>
                                    ) : null} */}
                                    {/* Commented by Om Shrivastava on 04-12-2024
                                    Reason : No need to show the customized field in this column
                                    {/* End of modification and addition by Om Shrivastava on 02-12-2024
                                    Reason : Show the customized field  */}
                                    {/* End of addition by Om Shrivastava on 26-11-2024
                                            Reason : Add text of customized product  */}

                                    <div className={styles.productInfoQuantity}>
                                      <div>Size: {item.size}</div>

                                      {/* Commented by jhamman on 14-10-2024
                                      Reason - quantity is showin two time*/}
                                      {/* <div>Quantity: {quantity}</div> */}
                                      {/* End of commentation by jhamman on 14-10-2024
                                      Reason - quantity is showin two time*/}
                                    </div>
                                     {/* Modification and addition by Om Shrivastava on 05-12-2024
                                     Reason : Add the quantity increment, decrement functionality */}
                                    <div>
                                    <div
                                        className={`${styles.text} ${styles.quantityContainer}`}
                                      >
                                        Quantity:{" "}
                                        <p
                                          className={`${styles.iconContainer}`}
                                          onClick={() =>
                                            handleQuantityChange(
                                              user.id,
                                              item.product,
                                              item.quantity,
                                              item.size,
                                              item.color,
                                              item.logo,
                                              item.patches,
                                              item.security_batches,
                                              item.embroider,
                                              "decrease"
                                            )
                                          }
                                        >
                                          -
                                        </p>
                                        {item.quantity}
                                        <p
                                          className={`${styles.iconContainer}`}
                                          onClick={() =>
                                            handleQuantityChange(
                                              user.id,
                                              item.product,
                                              item.quantity,
                                              item.size,
                                              item.color,
                                              item.logo,
                                              item.patches,
                                              item.security_batches,
                                              item.embroider,
                                              "increase"
                                            )
                                          }
                                        >
                                          +
                                        </p>
                                      </div>
                                      {errorModalVisible && (
                                        <Modal
                                          title=""
                                          open={errorModalVisible}
                                          onCancel={handleErrorModal}
                                          footer={null}
                                        >
                                          <p
                                            className={`${styles.errorModal}`}
                                          >
                                            {errorMessage}
                                          </p>
                                        </Modal>
                                      )}
                                    </div>
                                  </div>
                                  {/* End of modification and addition by Om Shrivastava on 05-12-2024
                                     Reason : Add the quantity increment, decrement functionality */}
                                </div>
                                {item.is_active ? (
                                  ""
                                ) : (
                                  <div className={styles.unavailableMessage}>
                                    Not available
                                  </div>
                                )}
                              </td>
                              {/* Addition by Om Shrivastava on 02-12-2024
                              Reason : Add customized amount  */}
                              <td className={styles.customizedColumn}>
                              {calculateTotalCustomizedPrice(item) > 0 ? (
                                <div className={styles.price}>
                                {/* Addition by Om Shrivastava on 04-12-2024
                                Reason : Show customized amounts  */}
                                <div>
                                {item.logo?<p>Logo : ${item.logo_price}</p>:null}
                                {item.patches?<p>Patches / Batches : ${item.patches_price}</p>:null}
                                {item.embroider?<p>Embroider / Name : ${item.embroider_price}</p>:null}
                                {item.security_batches?<p>Security id : ${item.security_batches_price}</p>:null}

                                </div>
                                {/* End of addition by Om Shrivastava on 04-12-2024
                                Reason : Show customized amounts  */}
                                Total Amount : ${calculateTotalCustomizedPrice(item)}</div>
                              ) : <div>----</div>}
                              </td>
                              {/* End of addition by Om Shrivastava on 02-12-2024
                              Reason : Add customized amount  */}

                              <td className={styles.priceColumn}>
                                <div className={styles.price}>
                                  {/**Code modified by Unnati on 12-09-2024
                                   *Reason-This code is not in use  */}

                                  {/* Modified by Jhamman on 10-10-2024
                                   Reason - calculate discounted price*/}
                                  {/* {CartItem.sales_rate != null &&
                                  !isNaN(Number(CartItem.sales_rate))
                                    ? `$${Number(CartItem.sales_rate).toFixed(2)}`
                                    : "Price not available"} */}
                                  {/**Code commented by Unnati on 25-11-2024
                                   *Reason-This code is not in use */}
                                  {/* {CartItem.sales_rate != null &&
                                  !isNaN(Number(CartItem.sales_rate)) ? (
                                    CartItem.sale_percentage ? (
                                      <div>
                                        {calculateDiscountFromProduct(
                                          CartItem.sales_rate,
                                          CartItem.sale_percentage
                                        )}
                                      </div>
                                    ) : (
                                      <div>
                                        $ */}
                                  {/**End of code comment by Unnati on 25-11-2024
                                   *Reason-This code is not in use */}
                                  {/**Code added by Unnati on 17-10-2024
                                   *Reason-Added fixed to */}
                                  {/* {Math.round(CartItem.sales_rate * 1e2) /
                                          1e2} */}
                                  {/**Code commented by Unnati on 25-11-2024
                                   *Reason-This code is not in use */}
                                  {/* {Number(CartItem.sales_rate)
                                          ? Number(CartItem.sales_rate).toFixed(
                                              2
                                            )
                                          : "0.00"} */}
                                  {/**End of code comment by Unnati on 25-11-2024
                                   *Reason-This code is not in use */}
                                  {/*End of code addition by Unnati on 17-10-2024
                                   *Reason-Added fixed to */}
                                  {/**Code commented by Unnati on 25-11-2024
                                   *Reason-This code is not in use */}
                                  {/* </div>
                                    )
                                  ) : (
                                    "Price not available"
                                  )} */}
                                  {/**End of code comment by Unnati on 25-11-2024
                                   *Reason-This code is not in use */}

                                  {/* End of modification by Jhamman on 10-10-2024
                                   Reason - calculate discounted price*/}

                                  {/**End of code modification by Unnati on 12-09-2024
                                   *Reason-This code is not in use  */}
                                  {/**Code added by Unnati on 25-11-2024
                                   *Reason-To show price before and  after discount*/}

                                  {item.sale_percentage ? (
                                    <div className={styles.discountedPriceText}>
                                      {/* Modification and addition by Om Shrivastava on 21-11-2024
                                                  Reason : Show the customized price  */}
                                      {item.logo ||item.patches ||item.security_batches ||item.embroider ? (
                                        <>
                                          $
                                          {
                                            item?.after_customization_product_price
                                          }
                                        </>
                                      ) : (
                                        <>
                                          $
                                          {calculateDiscountFromProduct(
                                            item.sales_rate,
                                            item.sale_percentage
                                          )}
                                        </>
                                      )}
                                    </div>
                                  ) : (
                                    <div className={styles.discountedPriceText}>
                                      {item.logo ||item.patches ||item.security_batches ||item.embroider ? (
                                        <>
                                          $
                                          {
                                            item?.after_customization_product_price
                                          }
                                        </>
                                      ) : (
                                        <>${item.sales_rate}</>
                                      )}
                                    </div>
                                  )}
                                  {/* End of modification and addition by Om Shrivastava on 21-11-2024
                                                  Reason : Show the customized price  */}

                                  {item.sale_percentage ? (
                                    <div
                                      className={styles.mrpPriceText}
                                      style={
                                        item.sale_percentage
                                          ? {
                                              textDecoration: "line-through",
                                              textDecorationColor: "#000",
                                              color: "red",
                                            }
                                          : { color: "green" }
                                      }
                                    >
                                      ${item.sales_rate}
                                    </div>
                                  ) : null}
                                  {/**End of code addition by Unnati on 25-11-2024
                                   *Reason-To show price before and  after discount*/}
                                  {/* Added by - Ashlekh on 05-12-2024
                                  Reason - To display offer percentage */}
                                   {item.sale_percentage && (
                                    <p className={`${styles.offerPercentageText}`}>
                                      {item.sale_percentage}%off
                                    </p>
                                   )}
                                   {/* End of code - Ashlekh on 05-12-2024
                                   Reason - To display offer percentage */}
                                </div>
                              </td>
                              <td className={styles.qtyColumn}>
                                <div className={styles.quantity}>
                                  {item.quantity}
                                </div>
                              </td>
                              <td className={styles.subtotalColumn}>
                                <div className={styles.subtotal}>
                                  {/** Code commented by Unnati on 25-07-2024
                                   * Reason- This code is not in use
                                   */}
                                  {/**Code added by Unnati on 12-09-2024
                                   *Reason-Adding upto 2 decimal places */}
                                  {/* Modified by Jhamman on 10-10-2024
                                   Reason - calculate price after discount*/}
                                  <div className={styles.subtotal}>
                                    {/* $
                                    {(quantity * CartItem.sales_rate).toFixed(
                                      2
                                    )} */}
                                    {item.sales_rate != null &&
                                    !isNaN(Number(item.sales_rate)) ? (
                                      item.sale_percentage ? (
                                        <div>
                                          {/**Code added by Unnati on 17-10-2024
                                           *Reason-Added fixed to */}
                                          {/* Modification and addition by Om Shrivastava on 26-11-2024
                                           Reason : according to customization price fix calculation  */}

                                          {/* $
                                          {(
                                            quantity *
                                            calculateDiscountFromProduct(
                                              CartItem.sales_rate,
                                              CartItem.sale_percentage
                                            )
                                          ).toFixed(2)} */}
                                          {item.logo ||item.patches ||item.security_batches ||item.embroider ? (
                                            <>
                                              $
                                              {(
                                                item.quantity *item.after_customization_product_price
                                                
                                              ).toFixed(2)}
                                            </>
                                          ) : (
                                            <>
                                              $
                                              {(
                                                item.quantity *
                                                calculateDiscountFromProduct(
                                                  item.sales_rate,
                                                  item.sale_percentage
                                                )
                                              ).toFixed(2)}
                                            </>
                                          )}
                                          {/* End of modification and addition by Om Shrivastava on 26-11-2024
                                           Reason : according to customization price fix calculation  */}
                                        </div>
                                      ) : (
                                        <div>
                                          {/* Modification and addition by Om Shrivastava on 26-11-2024
                                           Reason : according to customization price fix calculation  */}

                                          {/* $
                                          {(
                                            Math.round(
                                              quantity *
                                                CartItem.sales_rate *
                                                1e2
                                            ) / 1e2
                                          ).toFixed(2)} */}
                                          {item.logo ||item.patches ||item.security_batches ||item.embroider ? (
                                            <>
                                              $
                                              {(
                                                Math.round(
                                                  item.quantity *
                                                  item.after_customization_product_price *
                                                    1e2
                                                ) / 1e2
                                              ).toFixed(2)}
                                            </>
                                          ) : (
                                            <>
                                              $
                                              {(
                                                Math.round(
                                                  item.quantity *
                                                  item.sales_rate *
                                                    1e2
                                                ) / 1e2
                                              ).toFixed(2)}
                                            </>
                                          )}

                                          {/* Modification and addition by Om Shrivastava on 26-11-2024
                                           Reason : according to customization price fix calculation  */}

                                          {/*End of code addition by Unnati on 17-10-2024
                                           *Reason-Added fixed to */}
                                        </div>
                                      )
                                    ) : (
                                      "Price not available"
                                    )}
                                  </div>
                                  {/* End of modificatiomn by Jhamman on 10-10-2024
                                   Reason - calculate price after discount*/}
                                  {/**End of code addition by Unnati on 12-09-2024
                                   *Reason-Adding upto 2 decimal places */}
                                  {/** End of code comment by Unnati on 25-07-2024
                                   * Reason- This code is not in use
                                   */}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="4">
                                <div className={styles.icons}>
                                  <div className={`${styles.square}`}>
                                    {/**Code commented by Unnati on 25-07-2024
                                     *Reason-This code is not in use */}
                                    <Link
                                      /**Code added by Unnati on 13-09-2024
                                       *Reason-Modified product_id */
                                      to={`/productdetail/${
                                        item.product_id
                                          ? `${item.product_id}`
                                          : ""
                                      }`}
                                      /*End of code addition by Unnati on 13-09-2024
                                       *Reason-Modified product_id */
                                    >
                                      <p className={`${styles.squareIcon}`}>
                                      {/* Modification and addition by Om Shrivastava on 03-12-2024
                                      Reason : Change the edit icon  */}
                                        {/* <MdEdit color="blue" /> */}
                                        {/* <MdInfo color="blue" /> */}
                                        {/* End of modification and addition by Om Shrivastava on 03-12-2024
                                      Reason : Change the edit icon */}
                                        {/* Code changed by - Ashlekh on 02-12-2024
                                        Reason - To change icon */}
                                        {/* <MdEdit color="blue" /> */}
                                        <FaEye color="green"/>
                                        {/* End of code - Ashlekh on 02-12-2024
                                        Reason - To change icon */}
                                      </p>
                                    </Link>
                                    {/**End of code comment by Unnati on 25-07-2024
                                     *Reason-This code is not in use */}
                                  </div>
                                  <div className={styles.square}>
                                    <p
                                      className={styles.squareIcon}
                                      onClick={() => {
                                        handleDeleteClick(
                                        // Modification and addition by Om Shrivastava on 04-12-2024
                                        // Reason : Add the customized field also
                                        item.product,
                                        item.size,
                                        item.logo,
                                        item.patches,
                                        item.security_batches,
                                        item.embroider,
                                        item.color
                                         // End of modification and addition by Om Shrivastava on 04-12-2024
                                        // Reason : Add the customized field also 
                                         );
                                      }}
                                    >
                                      <RiDeleteBin6Fill color="red" />
                                    </p>
                                  </div>

                                  {isModalVisible && (
                                    <div className={styles.modalOverlay}>
                                      <div className={styles.modalContent}>
                                        <p>
                                          Are you sure you want to remove this
                                          item?
                                        </p>
                                        <button
                                          className={styles.buttonYesOrNo}
                                          onClick={confirmDelete}
                                        >
                                          Yes
                                        </button>
                                        <button
                                          className={styles.buttonYesOrNo}
                                          onClick={cancelDelete}
                                        >
                                          No
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </td>
                            </tr>
                          </React.Fragment>
                        );
                      })
                    )}
                    {/* Code modified by Unnati on 26-08-2024
                     *Adding a new row for buttons */}
                    <tr>
                      <td colSpan="4">
                        <div className={styles.buttonContainer}>
                          <div className={styles.buttonContainerOne}>
                            <Link to="/">
                              <button>CONTINUE SHOPPING</button>
                            </Link>
                            <button onClick={openModal}>
                              CLEAR SHOPPING CART
                            </button>
                            {isModalOpen && (
                              <div className={styles.modalOverlay}>
                                <div className={styles.modalContent}>
                                  <p>Are you sure you want to clear cart?</p>
                                  <button
                                    className={styles.buttonYesOrNo}
                                    onClick={confirmClear}
                                  >
                                    Yes
                                  </button>
                                  <button
                                    className={styles.buttonYesOrNo}
                                    onClick={cancelClear}
                                  >
                                    No
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                          {/**Code commented by Unnati on 11-09-2024
                           *Reason-To remove update shopping cart button
                           */}
                          {/* <div>
                            <button>UPDATE SHOPPING CART</button>
                          </div> */}
                          {/**End of code comment by Unnati on 11-09-2024
                           *Reason-To remove update shopping cart button
                           */}
                        </div>
                      </td>
                    </tr>
                    {/*End of code modification by Unnati on 26-08-2024
                     *Adding a new row for buttons */}
                  </>

                  {/**End of code addition by Unnati on 19-07-2024
                   *Reason-To map cart items to their respective columns(First generating separate row for each size) */}
                </tbody>
              </table>
            </div>
            {/**Code commented by Unnati on 18-09-2024
             *Reason-This code is not in use */}
            {/* <div className={styles.summarySectionContainer}> */}
            {/* <div
              className={`${styles.summarySection} ${
                isOpen || isDropdownOpen ? styles.expanded : ""
              }`}
            > */}
            {/* <div className={styles.summarySectionTitle}>
                <h3>SUMMARY</h3>
              </div> */}
            {/* <div className={styles.shippingContainer}>
                <div
                  className={styles.shippingTitle}
                  onClick={handleShippingDropdown}
                  style={{ cursor: "pointer" }}
                >
                  <div>
                    <h3>ESTIMATE SHIPPING AND TAX</h3>
                  </div>
                  <div>
                    <FaAngleDown />
                  </div>
                </div>
              </div> */}
            {/**Code added by Unnati on 17-07-2024
             *Reason-Adding isOpen to adjust the size of the summary section when drodpown is open  */}
            {/* {isOpen && (
                <div className={styles.shippingContent}>
                  <p className={styles.displayNote}>
                    Enter your destination to get a shipping estimate.
                  </p>
                  <form> */}
            {/**Code commented by Unnati on 17-07-2024
             *Reason -This code is not in use because country will be by default US */}
            {/* <div className={styles.formGroup}> */}
            {/* <h6 className={styles.countryTitle}>Country</h6>
                      <span style={{ color: "red" }}>*</span>

                      <CountrySelect
                        onChange={(e) => {
                          setCountryid(e.id);
                        }}
                        placeHolder="Select Country"
                      />

                      <h6 className={styles.stateTitle}>State</h6>
                      <StateSelect
                        countryid={countryid}
                        onChange={(e) => {
                          setstateid(e.id);
                        }}
                        placeHolder="Select State"
                      /> */}
            {/**Code added by Unnati on 19-07-2024
             *Reason-To add state dropdown */}
            {/* <div className={styles.formGroup}>
                        <div className={styles.Country}>
                          <div className={styles.CountryTitle}>
                            <label>Country</label>
                            <span className={styles.Mandatory}>*</span>
                          </div>
                          <select
                            id="country"
                            className={styles.dropdown}
                            value={selectedCountry}
                            onChange={handleCountryChange}
                          >
                            {countries.map((country) => (
                              <option key={country.code} value={country.code}>
                                {country.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className={styles.stateContainer}>
                          <label htmlFor="country">State/Province</label>
                          <StateDropdown
                            countryCode={selectedCountry}
                            onStateChange={handleStateChange}
                          />
                        </div>
                      </div> */}
            {/**End of code addition by Unnati on 19-07-2024
             *Reason-To add state dropdown */}
            {/* </div> */}
            {/**End of code comment by Unnati on 18-07-2024
             *Reason This code is not in use because country will be by default US*/}
            {/* <div className={styles.formGroup}>
                      <div className={styles.zipCodeContainer}>
                        <h6 className={styles.zipCodeTitle}>Zip/Postal Code</h6>
                        <input
                          type="text"
                          id="zip"
                          className={styles.zipCodeformControl}
                          value={zipCode}
                          onChange={handleZipCodeChange}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              )} */}
            {/**End of code addition by Unnati on 18-07-2024
             *Reason-Adding isOpen to adjust the size of the summary section when drodpown is open  */}
            {/* <div className={styles.cartTotalContainer}>
            <table className={styles.cartTotal}>
              <tbody>
                <tr className={styles.summaryTrTag}>
                  <th>Subtotal</th>
                  <td>{`$${calculatedSubtotal.toFixed(2)}`}</td>
                </tr>
                <tr className={styles.summaryTrTag}>
                  <th>Order Total Incl. Tax</th>
                  <td>{`$${calculatedSubtotal.toFixed(2)}`}</td>
                </tr>
                <tr className={styles.summaryTrTag}>
                  <th>Order Total Excl. Tax</th>
                  <td>{`$${calculatedSubtotal.toFixed(2)}`}</td>
                </tr>
              </tbody>
            </table>
          </div> */}
            {/**Code commented by Unnati on 11-09-2024
             *Reason-Apply discount option is already there in checkout page */}
            {/* <div
                className={styles.discountDropdown}
                onClick={handleDiscountDropdown}
              >
                <div>
                  <h3>APPLY DISCOUNT CODE</h3>
                </div>
                <div>
                  <FaAngleDown />
                </div>
              </div> */}
            {/**End of code comment by Unnati on 11-09-2024
             *Reason-Apply discount option is already there in checkout page */}
            {/**Code added by Unnati on 17-07-2024
             *Reason-Adding isOpen to adjust the size of the summary section when drodpown is open  */}
            {/* {isDropdownOpen && (
            <div className={styles.dropdownContent}>
              <div className={styles.dicountInputBox}>
                <div className={styles.discountFieldTitle}>
                  <h6>Enter discount code</h6>
                </div>
                <div className={styles.dicountInputField}>
                  <input type="text" placeholder="Enter discount code" />
                </div>
              </div>
              <div className={styles.applyDiscountButton}>
                <button>APPLY DISCOUNT</button>
              </div>
            </div>
          )} */}
            {/* </div> */}

            {/**End of code addition by Unnati on 17-07-2024
             *Reason-Adding isOpen to adjust the size of the summary section when drodpown is open  */}
            <div className={styles.buttonCheckOutContainer}>
              <div className={styles.buttonProceedCheckOut}>
                {/**Code added by Unnati on 20-07-2024
                 *Reason-Adding link in checkout button */}

                <button onClick={handleCheckout}>PROCEED TO CHECKOUT</button>

                {/**End of code addition by Unnati on 20-07-2024
                 *Reason-Adding link in checkout button */}
              </div>
            </div>
            {/* </div> */}
            {/**End of code comment by Unnati on 18-09-2024
             *Reason-This code is not in use currently  */}
          </div>
        ) : (
          /**Code added by Unnati on 30-09-2024
           *Reason-Added shopping cart button */
          <div className={styles.noItemCart}>
            <p colSpan="4">No items in the cart.</p>
            <div className={styles.buttonContinueShopping}>
              <Link to="/">
                <button>CONTINUE SHOPPING</button>
              </Link>
            </div>
          </div>
        )}
        {/**End of code addition by Unnati on 30-09-2024
         *Reason-Added shopping cart button */}
      </div>
      {/**Code added by Unnati on 30-09-2024
       *Reason-Called scrollDoc component*/}
      {scrollDoc()}
      {/**End of code addition by Unnati on 30-09-2024
       *Reason-Called scrollDoc component*/}
    </div>
    /*End of code addition by Unnati on 13-07-2024
     *Reason-To make structure of the shopping cart
     */
  );
};

export default ViewCart;
/**End of code addition by Unnati on 13-07-2024
 * Reason-To have view cart page
 */
