/**
 * Created by - Ashish Dewangan on 23-05-2024
 * Reason - Created home page
 */
import React, { useContext, useEffect, useState } from "react";
import {
  addToWishListAPI,
  getHomePageDetails,
  removeProductFromWishListAPI,
} from "../../Api/services";
import homeStyle from "./Home.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import config from "../../Api/config";
import "./carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { calculateDiscountFromProduct } from "../../utils/discountedPrices";
import ReactStars from "react-stars";
import Rating from "../../components/Rating/Rating";
import { MdFavorite, MdFavoriteBorder } from "react-icons/md";
import { GlobalContext } from "../../context/Context";
import notificationObject from "../../components/Widgets/Notification/Notification";
const Home = () => {
  const [homePage, setHomePage] = useState([]);
  const location = useLocation();
  const [selectedWishListIcon, setSelectedWishListIcon] = useState(false);
  // Added by - Ashlekh on 20-11-2024
  // Reason - To import variables from context
  const { user, wishListData, setWishListData } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [isWishListed, setIsWishListed] = useState(false);
  const [homePageBestSellingProducts, setHomePageBestSellingProducts] =
    useState([]);
    const [wishlistStatus, setWishlistStatus] = useState({});
  // End of code - Ashlekh on 20-11-2024
  // Reason - To import variables from context
  /**Code commented by Unnati on 27-05-2024
   * Reson -To handle user logout by removing tokens from local storage
   */
  // const navigate = useNavigate();
  // const handleLogout = () => {
  //   localStorage.removeItem("access");
  //   localStorage.removeItem("refresh");
  //   setUser({});
  //   navigate("/login");
  // };
  /**End of code comment by Unnati on 27-05-2024
   * Reson -To handle user logout by removing tokens from local storage
   */
  /**Code added by Unnati on 05-08-2024
   * Reason-To get home page details when the component gets loaded
   */
  useEffect(() => {
    const fetchHomePageDetails = async () => {
      try {
        const data = await getHomePageDetails();
        setHomePage(data);
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchHomePageDetails();
  }, []);
  /**End of code addition by Unnati on 05-08-2024
   * Reason-To get home page details when the component gets loaded
   */
  /**Code commented by unnati on 07-08-2024
   * Reason-This code is not in use
   */
  // const [w, setW] = useState(window.innerWidth);
  // useLayoutEffect(() => {
  //   function updateSize() {
  //     var style = getComputedStyle(document.body)
  //     console.log(">............",(window.innerWidth))
  //     if(window.innerWidth>1000)
  //     setW(parseInt(window.innerWidth) -parseInt(getPropertyValue());
  //     else
  //     setW(1517)
  //   }
  //   window.addEventListener('resize', updateSize);
  //   updateSize();
  //   return () => window.removeEventListener('resize', updateSize);
  // }, []);
  /**End of code comment by unnati on 07-08-2024
   * Reason-This code is not in use
   */

  /**Code added by Unnati on 19-09-2024
   * Reason-Added window.scrollTo
   */
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }, [location]);
  /**End of code addition by Unnati on 19-09-2024
   * Reason-Added window.scrollTo
   */
  /**
   * Added by - Ashlekh on 20-11-2024
   * Reason - To add/remove products in wishlist
   */
  useEffect(() => {
    const newWishlistStatus = {};
    wishListData?.forEach((item) => {
      const key = `${item.product_id}-${item.color}`;
      newWishlistStatus[key] = true; 
    });
    setWishlistStatus(newWishlistStatus);
  }, [wishListData]);
  const handleToggleWishList = async (user, product) => {

    if (user.id == null || user.id == undefined) {
      navigate("/login");
      return;
    } else {
      const productKey = `${product.product_id}-${product.color}`;
      try {
        if (wishlistStatus[productKey]) {
          const response = await removeProductFromWishListAPI(
            user.id,
            product?.product_id,
            product?.color
          );
          localStorage.setItem(
            "wishListData",
            JSON.stringify(response.wishlist)
          );
          setWishListData(response.wishlist);
          if (response?.message) {
            setWishlistStatus((prev) => ({
              ...prev,
              [productKey]: false,
            }));
            notificationObject.success(
              "Product successfully removed from wishlist"
            );
          }
        } else {
          const response = await addToWishListAPI(
            user.id,
            product?.product_id,
            product?.color
          );
          localStorage.setItem(
            "wishListData",
            JSON.stringify(response?.wishlist_data)
          );
          setWishListData(response?.wishlist_data);
          if (response?.message == "Success") {
            setWishlistStatus((prev) => ({
              ...prev,
              [productKey]: true,
            }));
            notificationObject.success(
              "Product successfully added to wishlist"
            );
          } else if (response?.message == "Product Already in Wishlist") {
            notificationObject.success("Product is already in your wishlist");
          } else {
            notificationObject.error("Failed to add product to wishlist");
          }
        }
      } catch (error) {
        console.error("Error updating wishlist:", error);
      }
    }
  };
  /**
   * End of code - Ashlekh on 20-11-2024
   * Reason - To add/remove products in wishlist
   */
  return (
    <div className={`${homeStyle.pageFrame}`}>
      {/**Code added by Unnati on 05-08-2024
       *Reason-Added carousel in which banner is coming from backend */}
      <div className={`${homeStyle.banners}`}>
        <div className={`${homeStyle.detailBox}`}>
          <Carousel
            showThumbs={false}
            showStatus={false}
            infiniteLoop={true}
            useKeyboardArrows={true}
            autoPlay={true}
            stopOnHover={true}
            swipeable={true}
            dynamicHeight={true}
            emulateTouch={true}
            /**Code added by Unnati on 05-09-2024
             *Reason-To show bubbles and arrow */
            showIndicators={true}
            showArrows={true}
            /**End of code addition by Unnati on 05-09-2024
             *Reason-To show bubbles and arrow */
          >
            {/*Code added by Unnati on 05-08-2024
             *Reason-To map banner in home page */}
            {/**Code modified by Unnati on 06-10-2024
             *Reason-To check banner option and map medium banner */}
            {homePage.banner &&
              homePage.banner
                .filter((item) => item.banner_options === "Medium")
                .map((item) => (
                  /** Code modified by Unnati on 05-10-2024
                   * Reason-To add link to saleproducts page */
                  <Link to="/saleproducts" state={item.id} key={item.id}>
                    <div className={`${homeStyle.banner}`}>
                      <img
                        src={`${config.baseURL}${item.banner_image}`}
                        alt={`Banner ${item.id}`}
                        className={`${homeStyle.bannerImage}`}
                      />
                    </div>
                    {/** End of code modification by Unnati on 05-10-2024
                     * Reason-To add link to saleproducts page */}
                  </Link>
                ))}
            {/**End of code modification by Unnati on 06-10-2024
             *Reason-To check banner option and map medium banner */}
            {/*End of code addition by Unnati on 05-08-2024
             *Reason-To map banner in home page */}
          </Carousel>
          {/**End of code addition by Unnati on 05-08-2024
           *Reason-Added carousel in which banner is coming from backend */}
        </div>
        {/**Code commented by Unnati on 06-10-2024
         *Reason-Added banner */}
        {/* <div className={`${homeStyle.FourBanners}`}>
          <div className={`${homeStyle.bannerContainer}`}>
            <div>
              <img
                src="/bannerHomePage1.PNG"
                alt="ban1"
                className={`${homeStyle.bannerImage}`}
              />
            </div>
            <div>
              <img
                src="/sweaterBanner.PNG"
                alt="ban1"
                className={`${homeStyle.bannerImage}`}
              />
            </div>
          </div>
          <div className={`${homeStyle.bannerContainer}`}>
            <div>
              <img
                src="/homeSale.PNG"
                alt="ban1"
                className={`${homeStyle.bannerImage}`}
              />
            </div>
            <div>
              <img
                src="/kitchenDecor.PNG"
                alt="ban1"
                className={`${homeStyle.bannerImage}`}
              />
            </div>
          </div>
        </div> */}
        {/**End of code comment by Unnati on 06-10-2024
         *Reason-Added banner */}
        {/**Code added by Unnati on 06-10-2024
         *Reason-Added small banner in home page */}
        {homePage.banner &&
          homePage.banner.some((item) => item.banner_options === "Small") && (
            <div className={`${homeStyle.FourBanners}`}>
              {homePage.banner
                .filter((item) => item.banner_options === "Small")
                .map((item) => (
                  <div key={item.id} className={`${homeStyle.bannerContainer}`}>
                    <Link to="/saleproducts" state={item.id} key={item.id}>
                      <div>
                        <img
                          src={`${config.baseURL}${item.banner_image}`}
                          alt={`Banner ${item.id}`}
                          className={`${homeStyle.bannerImage}`}
                        />
                      </div>
                    </Link>
                  </div>
                ))}
            </div>
          )}
        {/**End of code addition by Unnati on 06-10-2024
         *Reason-Added small banner in home page */}
      </div>
      {/**End of code additon by Unnati on 04-10-2024
       *Reason-Added banner  */}
      <div className={`${homeStyle.page}`}>
        <div className={`${homeStyle.coloredBackground}`}>
          {/**Code added by Unnati on 05-08-2024
           *Reason-Added bestseller and featured products */}
          {homePage.best_selling_products?.length > 0 ? (
            <div className={`${homeStyle.title}`}>
              <h3 className={`${homeStyle.heading}`}>Bestsellers</h3>
            </div>
          ) : (
            " "
          )}
          <div className={`${homeStyle.gridContainer}`}>
            {homePage.best_selling_products &&
              homePage.best_selling_products.map((product) => (
                <div key={product.id} className={`${homeStyle.gridCard}`}>
                  {/* Code commented by - Ashlekh on 22-11-2024
                  Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                  {/* <Link
                    to={`/productdetail/${product.product_id}`}
                    className={`${homeStyle.productLink}`}
                  > */}
                  <Link
                    to={`/productdetail/${product.product_id}`}
                    /**Code added by Unnati on 28-11-2024
                    *Reason-Send color through state */
                    state={product.color}
                    /**End of code addition by Unnati on 28-11-2024
                    *Reason-Send color through state */
                    >
                    {/* End of code - Ashlekh on 22-11-2024
                    Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                    <div className={`${homeStyle.gridImageContainer}`}>
                      {/* Added by Jhamman on 10-10-2024
                    Reason - Added offer logo */}
                      <div
                        className={`${homeStyle.imageAndOfferLogoContainer}`}
                      >
                        {product.sale_percentage ? (
                          <div className={homeStyle.offerContainer}>
                            <p className={homeStyle.offerPercentage}>
                              {product.sale_percentage}% off
                            </p>
                          </div>
                        ) : null}
                        {/* End of addition by Jhamman on 10-10-2024
                        Reason - Added offer logo */}
                        <img
                          src={`${config.baseURL}${product.image1}`}
                          alt={product.name}
                          className={`${homeStyle.gridImage}`}
                        />
                      </div>
                      <button className={`${homeStyle.addToCartButton}`}>
                        Add to Cart
                      </button>
                    </div>
                  </Link>
                    <div className={`${homeStyle.gridContent}`}>
                      {/* Added by - Ashlekh on 22-11-2024
                      Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                      <Link
                        to={`/productdetail/${product.product_id}`}
                      >
                        {/* End of code - Ashlekh on 22-11-2024
                        Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                        {/**Code modified by Unnati on 27-11-2024
                        *Reason-Modified h3 to div tag */}
                        <div className={`${homeStyle.brandName}`}>
                        {/* <h3 className={`${homeStyle.brandName}`}> */}
                          {/**Code added by Unnati on 20-09-2024
                           *Reason-Added restrcition for long nam and description */}
                          {product.name.length > 50
                            ? `${product.name.substring(0, 50)}...`
                            : product.name}
                        {/* </h3> */}
                        </div>
                        {/*End of code modification by Unnati on 27-11-2024
                        *Reason-Modified h3 to div tag */}
                      </Link>
                      {/**Code added by Unnati on 27-11-2024
                      *Reason-To have rating */}
                      <div className={`${homeStyle.rating}`}>
                            {product.rating > 0 && (
                            <Rating value={product.rating} />
                          )}
                          </div>
                          {/**End of code addition by Unnati on 27-11-2024
                      *Reason-To have rating */}
                      {/* Added by - Ashlekh on 22-11-2024
                      Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                      {/* <Link
                        to={`/productdetail/${product.product_id}`}
                      > */}
                        {/* End of code - Ashlekh on 22-11-2024
                        Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                        {/**Code commented by Unnati on 27-11-2024
                         *Reason-Description is not required*/}
                        {/* <h2 className={`${homeStyle.cardTitle}`}>
                          {product.description.length > 50
                            ? `${product.description.substring(0, 50)}...`
                            : product.description}
                        </h2> */}
                        {/**End of code addition by Unnati on 27-11-2024
                         *Reason-Description is not required*/}
                      {/* </Link> */}
                      {/**End of code addition by Unnati on 20-09-2024
                       *Reason-Added restrcition for long nam and description */}

                      {/* Modified by Jhamman on 05-10-2024
                              Reason- calculate sale price */}
                      {/* Added by - Ashlekh on 22-11-2024
                      Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                      <Link
                        to={`/productdetail/${product.product_id}`}
                      >
                        {/* End of code - Ashlekh on 22-11-2024
                        Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                        <div className={homeStyle.priceContainer}>
                          {product.sale_percentage ? (
                            <div className={homeStyle.cardPrice}>
                              <p className={homeStyle.discountedPriceText}>
                                $
                                {calculateDiscountFromProduct(
                                  product.sales_rate,
                                  product.sale_percentage
                                )}
                              </p>
                            </div>
                          ) : null}

                          <div className={`${homeStyle.cardPrice}`}>
                            {/* Commented by jhamman on 07-10-2024
                                  Reason - Go through many websites there are not mentioned MRP*/}
                            {/* <p className={homeStyle.mrpPriceText}>M.R.P:</p> */}
                            {/* End of commentation by jhamman on 07-10-2024
                                  Reason - Go through many websites there are not mentioned MRP*/}
                            <p
                              className={homeStyle.mrpPriceText}
                              style={
                                product.sale_percentage
                                  ? {
                                      textDecoration: "line-through",
                                      /**Code modified by Unnati on 27-11-2024
                                    *Reason-Changed color */
                                      textDecorationColor: "#888888",
                                      color: "#888888",
                                      /**End of code modification by Unnati on 27-11-2024
                                    *Reason-Changed color */
                                    }
                                    /**Code modified by Unnati on 27-11-2024
                                    *Reason-Changed color */
                                  : { color: "red" }
                                  /**End of code modification by Unnati on 27-11-2024
                                    *Reason-Changed color */
                              }
                            >
                              ${product.sales_rate}
                            </p>
                          </div>
                        </div>
                      </Link>
                      
                      {/**Code added by Unnati on 17-10-2024
                       *Reason-Added star rating*/}
                      <div className={homeStyle.productDetailContainer}>
                        {/* Added by - Ashlekh on 22-11-2024
                        Reason - To display wishlist icon */}
                        {/* Added by - Ashlekh on 25-11-2024
                        Reason - To display wishlist icon only for login user */}
                        {user.id != undefined && (
                          // End of code - Ashlekh on 25-11-2024
                          // Reason - To display wishlist icon only for login user
                          <div className={`${homeStyle.wishListIconContainer}`}>
                            {wishlistStatus[`${product.product_id}-${product.color}`] ? (
                              <MdFavorite
                                onClick={() => handleToggleWishList(user, product)}
                                className={`${homeStyle.wishListIcon1}`}
                              />
                            ) : (
                              <MdFavoriteBorder
                                onClick={() => handleToggleWishList(user, product)}
                                className={`${homeStyle.wishListIcon2}`}
                              />
                            )}
                          </div>
                        )}
                        
                        {/* End of code - Ashlekh on 22-11-2024
                        Reason - To add wishlist icon */}
                        
                            {/**Code commented by Unnati on 18-10-2024
                             *Reason-Commented star component*/}
                            {/* <ReactStars
                                    className={CategoryStyle.star}
                                    count={5}
                                    value={product.rating}
                                    size={12}
                                    color2={"#ffd700"}
                                    edit={false}
                                  /> */}
                            {/**End of code comment by Unnati on 18-10-2024
                             *Reason-Commented star component*/}
                            {/**Code added by Unnati on 18-10-2024
                             *Reason-Calling component*/}
                            {/* <div>
                            {product.rating > 0 && (
                            <Rating value={product.rating} />
                          )}
                          </div> */}
                            {/**End of code addition by Unnati on 18-10-2024
                             *Reason-Calling component*/}
                         
                      </div>
                      {/**End of code addition by Unnati on 17-10-2024
                       *Reason-Added star rating*/}
                      {/* End of modification by Jhamman on 05-10-2024
                              Reason- calculate sale price */}
                    </div>
                  {/* </Link> */}
                  {/* Added by - Ashlekh on 20-11-2024
                    Reason - To display wishlist icon */}
                  {/* <div className={`${homeStyle.wishListIconContainer}`}>
                    {wishlistStatus[`${product.product_id}-${product.color}`] ? (
                      <MdFavorite
                        onClick={() => handleToggleWishList(user, product)}
                        className={`${homeStyle.wishListIcon1}`}
                      />
                    ) : (
                      <MdFavoriteBorder
                        onClick={() => handleToggleWishList(user, product)}
                        className={`${homeStyle.wishListIcon2}`}
                      />
                    )}
                  </div> */}
                  {/* End of code - Ashlekh on 20-11-2024
                    Reason - To display wishlist icon */}
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className={`${homeStyle.featuredContainer}`}>
      {/**Code added by Unnati on 22-11-2024
      *Reason-Added condition for featured product banner */}
        {(homePage?.banner?.some(
          (banner) => banner.banner_options === "Large"
        ) ||
          homePage?.featured_products?.length > 0) && (
             /**End of code addition by Unnati on 22-11-2024
      *Reason-Added condition for featured product banner */
          <div className={`${homeStyle.title}`}>
            <h3 className={`${homeStyle.heading}`}>Featured Products</h3>
          </div>
        )}

        {/**Code added by Unnati on 04-10-2024
         *Reason-Added featured banner */}
        {homePage.banner &&
          homePage.banner
            .filter((item) => item.banner_options === "Large")
            .map((item) => (
              <Link to="/saleproducts" state={item.id} key={item.id}>
                <div key={item.id} className={`${homeStyle.featuredBanner}`}>
                  <img
                    src={`${config.baseURL}${item.banner_image}`}
                    alt={`Banner ${item.id}`}
                    className={`${homeStyle.featuredBannerImage}`}
                  />
                </div>
              </Link>
            ))}
        {/**End of code addition by Unnati on 04-10-2024
         *Reason-Added featured banner */}
        <div className={`${homeStyle.gridContainer}`}>
          {homePage.featured_products &&
            homePage.featured_products.map((product) => (
              <div key={product.id} className={`${homeStyle.gridCard}`}>
                {/* Code changed by - Ashlekh on 22-11-2024
                Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                {/* <Link
                  to={`/productdetail/${product.product_id}`}
                  className={`${homeStyle.productLink}`}
                > */}
                <Link
                  to={`/productdetail/${product.product_id}`}
                  /**Code added by Unnati on 01-12-2024
                    *Reason-Send color through state */
                  state={product.color}
                    /**End of code addition by Unnati on 01-12-2024
                    *Reason-Send color through state */
                >
                  {/* End of code - Ashlekh on 22-11-2024
                  Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                  <div className={`${homeStyle.gridImageContainer}`}>
                    {/* Added by Jhamman on 10-10-2024
                    Reason - Added offer logo */}
                    <div className={`${homeStyle.imageAndOfferLogoContainer}`}>
                      {product.sale_percentage ? (
                        <div className={homeStyle.offerContainer}>
                          <p className={homeStyle.offerPercentage}>
                            {product.sale_percentage}% off
                          </p>
                        </div>
                      ) : null}
                    </div>
                    {/* End of addition by Jhamman on 10-10-2024
                    Reason - Added offer logo */}
                    <img
                      src={`${config.baseURL}${product.image1}`}
                      alt={product.name}
                      className={`${homeStyle.gridImage}`}
                    />
                    <button className={`${homeStyle.addToCartButton}`}>
                      Add to Cart
                    </button>
                  </div>
                </Link>
                  <div className={`${homeStyle.gridContent}`}>
                    {/* Added by - Ashlekh on 22-11-2024
                    Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                    <Link
                      to={`/productdetail/${product.product_id}`}
                    >
                      {/* End of code - Ashlekh on 22-11-2024
                      Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                      {/**Code modified by Unnati on 27-11-2024
                      *Reason-Modified h3 to div tag */}
                      <div className={`${homeStyle.brandName}`}>
                      {/* <h3 className={`${homeStyle.brandName}`}> */}
                        {/**Code added by Unnati on 20-09-2024
                         *Reason-Added restrcition for long nam and description*/}
                        {product.name.length > 50
                          ? `${product.name.substring(0, 50)}...`
                          : product.name}
                      {/* </h3> */}
                      </div>
                      {/**End of code modification by Unnati on 27-11-2024
                      *Reason-Modified h3 to div tag */}
                    </Link>
                    {/**Code added by Unnati on 27-11-2024
                      *Reason-To have rating */}
                    <div className={`${homeStyle.rating}`}>
                            {product.rating > 0 && (
                            <Rating value={product.rating} />
                          )}
                          </div>
                          {/**End of code addition by Unnati on 27-11-2024
                      *Reason-To have rating */}
                    {/* Added by - Ashlekh on 22-11-2024
                    Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                    {/* <Link
                      to={`/productdetail/${product.product_id}`}
                    > */}
                      {/* End of code - Ashlekh on 22-11-2024
                      Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                      {/**Code commented by Unnati on 27-11-2024
                         *Reason-Description is not required*/}
                      {/* <h2 className={`${homeStyle.cardTitle}`}>
                        {product.description.length > 50
                          ? `${product.description.substring(0, 50)}...`
                          : product.description} */}
                        {/**End of code addition by Unnati on 20-09-2024
                         *Reason-Added restrcition for long nam and description */}
                      {/* </h2> */}
                      {/**End of code addition by Unnati on 27-11-2024
                         *Reason-Description is not required*/}
                    {/* </Link> */}

                    {/* Modified by Jhamman on 11-10-2024
                              Reason- calculate sale price */}
                    {/* <p className={`${homeStyle.cardPrice}`}>
                      ${product.sales_rate}
                    </p> */}
                    {/* Added by - Ashlekh on 22-11-2024
                    Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                    <Link
                      to={`/productdetail/${product.product_id}`}
                    >
                      {/* End of code - Ashlekh on 22-11-2024
                      Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                      <div className={homeStyle.priceContainer}>
                        {product.sale_percentage ? (
                          <div className={homeStyle.cardPrice}>
                            <p className={homeStyle.discountedPriceText}>
                              $
                              {calculateDiscountFromProduct(
                                product.sales_rate,
                                product.sale_percentage
                              )}
                            </p>
                          </div>
                        ) : null}

                        <div className={`${homeStyle.cardPrice}`}>
                          <p
                            className={homeStyle.mrpPriceText}
                            style={
                              product.sale_percentage
                                ? {
                                    textDecoration: "line-through",
                                    /**Code modified by Unnati on 27-11-2024
                                    *Reason-Changed color */
                                    textDecorationColor: "#888888",
                                    color: "#888888",
                                     /**End of code modification by Unnati on 27-11-2024
                                    *Reason-Changed color */
                                  }
                                   /**Code modified by Unnati on 27-11-2024
                                    *Reason-Changed color */
                                : { color: "red" }
                                 /**End of code modification by Unnati on 27-11-2024
                                    *Reason-Changed color */
                            }
                          >
                            ${product.sales_rate}
                          </p>
                        </div>
                      </div>
                    </Link>
                    {/**Code added by Unnati on 17-10-2024
                     *Reason-Added star rating*/}
                    <div className={homeStyle.productDetailContainer}>
                      {/* Added by - Ashlekh on 22-11-2024
                      Reason - To display wishlist icon */}
                      {/* Added by - Ashlekh on 25-11-2024
                        Reason - To display wishlist icon only for login user */}
                      {user.id != undefined && (
                        // End of code - Ashlekh on 25-11-2024
                        // Reason - To display wishlist icon only for login user
                        <div className={`${homeStyle.wishListIconContainer}`}>
                          {wishlistStatus[`${product.product_id}-${product.color}`] ? (
                            <MdFavorite
                              onClick={() => handleToggleWishList(user, product)}
                              className={`${homeStyle.wishListIcon1}`}
                            />
                          ) : (
                            <MdFavoriteBorder
                              onClick={() => handleToggleWishList(user, product)}
                              className={`${homeStyle.wishListIcon2}`}
                            />
                          )}
                        </div>
                      )}
                      {/* End of code - Ashlekh on 22-11-2024
                      Reason - To display wishlist icon */}
                      <dic>
                        
                          {/**Code commented by Unnati on 18-10-2024
                           *Reason-Commented star component*/}
                          {/* <ReactStars
                                    className={CategoryStyle.star}
                                    count={5}
                                    value={product.rating}
                                    size={12}
                                    color2={"#ffd700"}
                                    edit={false}
                                  /> */}
                         { /**End of code comment by Unnati on 18-10-2024
                           *Reason-Commented star component*/}
                          {/**Code added by Unnati on 18-10-2024
                           *Reason-Calling component*/}
                          {/* {product.rating > 0 && (
                          <Rating value={product.rating} />
                        )} */}
                          {/**End of code addition by Unnati on 18-10-2024
                           *Reason-Calling component*/}
                     
                      </dic>
                    </div>
                    {/**End of code addition by Unnati on 17-10-2024
                     *Reason-Added star rating*/}
                    {/* End of modification by Jhamman on 11-10-2024
                              Reason- calculate sale price */}
                  </div>
                {/* </Link> */}
                {/* Added by - Ashlekh on 20-11-2024
                    Reason - To display wishlist icon */}
                  {/* <div className={`${homeStyle.wishListIconContainer}`}>
                    {wishlistStatus[`${product.product_id}-${product.color}`] ? (
                      <MdFavorite
                        onClick={() => handleToggleWishList(user, product)}
                        className={`${homeStyle.wishListIcon1}`}
                      />
                    ) : (
                      <MdFavoriteBorder
                        onClick={() => handleToggleWishList(user, product)}
                        className={`${homeStyle.wishListIcon2}`}
                      />
                    )}
                  </div> */}
                  {/* End of code - Ashlekh on 20-11-2024
                    Reason - To display wishlist icon */}
              </div>
            ))}
        </div>
      </div>
      {/**End of code addition by Unnati on 05-08-2024
       *Reason-Added bestseller and featured products */}
    </div>
  );
};

export default Home;
/**
 * End of code addition by - Ashish Dewangan on 23-05-2024
 * Reason - Created home page
 */
