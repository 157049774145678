/**Code added by Unnati on 20-06-2024
 * Reason-To have category page
 */
import React, { useState, useEffect, useContext } from "react";
import { Button, Modal } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CategoryStyle from "./Category.module.css";
import { getFilterOption, getProduct, getCategory, addToWishListAPI, removeProductFromWishListAPI } from "../../Api/services";
import config from "../../Api/config";
import { Range, getTrackBackground } from "react-range";
import { FaLongArrowAltDown } from "react-icons/fa";
import { FaLongArrowAltUp } from "react-icons/fa";
import { GlobalContext } from "../../context/Context";
import { calculateDiscountFromProduct } from "../../utils/discountedPrices";
import Rating from "../../components/Rating/Rating";
import notificationObject from "../../components/Widgets/Notification/Notification";
import { MdFavorite, MdFavoriteBorder } from "react-icons/md";
const Category = () => {
  const location = useLocation();
  const id = location.state;
  const {
    selectedColors,
    setSelectedColors,
    selectedBrands,
    setSelectedBrands,
    priceRange,
    setPriceRange,
    minPrice,
    maxPrice,
    setMinPrice,
    setMaxPrice,
    // Added by - Ashlekh on 27-11-2024
    // Reason - To import wishlist & user from context
    user,
    setUser,
    wishListData,
    setWishListData,
    // End of code - Ashlekh on 27-11-2024
    // Reason - To import wishlist & user from context
  } = useContext(GlobalContext);
  /**Code added by Unnati on 10-08-2024
   * Reason-Added location
   */

  const params = new URLSearchParams(location.search);
  const selectedItemType = params.get("item_type");
  /**End of code addition by Unnati on 10-08-2024
   * Reason-Added location
   */
  /**Code added by Unnati on 05-10-2024
   *Reason-Added variables for category
   */
  const [directParentCategory, setdirectParentCategory] = useState([]);
  /**End of code addition by Unnati on 05-10-2024
   *Reason-Added variables for category
   */
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [colors, setColors] = useState([]);
  const [brands, setBrands] = useState([]);
  const [sortOrder, setSortOrder] = useState("default");
  const [sortedProducts, setSortedProducts] = useState([]);
  const uniqueProducts = [];
  const productIdSet = new Set();
  const [viewType, setViewType] = useState("grid");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [category, setCategory] = useState([]);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [listPerPage, setListPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const scrollDoc = () => {
    window.scrollTo(0, 0);
  };
  // Added by - Ashlekh on 27-11-2024
  // Reason - useState for wishlist
  const [wishlistStatus, setWishlistStatus] = useState({});
  const navigate = useNavigate();
  // End of code - Ashlekh on 27-11-2024
  // Reason - useState for wishlist
  /**Code added by Unnati on 27-06-2024
   * Reason -To handle list per page change
   */

  /**End of code addition by Unnati on 27-06-2024
   * Reason -To handle list per page change
   */

  /**Code commented by Unnati on 23-06-2024
   * Reason-Since loader is not working currently
   */
  // const [isLoader, setIsLoader] = useState(true);
  // const [page, setPage] = useState(1);
  // const [hasMore, setHasMore] = useState(true);
  // const observer = useRef();
  /**End of code comment by Unnati on 23-06-2024
   * Reason-Since loader is not working currently
   */

  /* Added by jhamman on 30-10-2024
  Reason - added function for model*/
  const showModal = () => {
    setIsModalOpen(true);
  };
  
  /* End of addition by jhamman on 30-10-2024
  Reason - added function for model*/

  /**Code added by Unnati on 23-06-2024
   * Reason-To have unique products(It will not again add the same product)
   */
  if (Array.isArray(sortedProducts)) {
    sortedProducts.forEach((product) => {
      if (!productIdSet.has(product.product_id)) {
        productIdSet.add(product.product_id);
        uniqueProducts.push(product);
      }
    });
  }
  /**End of code addition by Unnati on 23-06-2024
   * Reason-To have unique products(It will not again add the same product)
   */

  /**Code added by Unnati on 21-06-2024
   * Reason-To handle slider change in price range
   */
  const handleRangeChange = (values) => {
    setPriceRange(values);
  };

  /**End of code addition by Unnati on 21-06-2024
   * Reason-To handle slider change in price range
   */

  /**Code modified by Unnati on 05-10-2024
   * Reason-To handle color change
   */
  const handleColorChange = (color) => {
    if (selectedColors.includes(color)) {
      setSelectedColors((prevColors) =>
        prevColors.filter((selectedColor) => selectedColor !== color)
      );
    } else {
      setSelectedColors((prevColors) => [...prevColors, color]);
    }
  };

  /**End of code modification by Unnati on 05-10-2024
   * Reason-To handle color change
   */
  /**Code commented by Unnati on 25-07-2024
   * Reason-To handle brand change
   */
  // const handleBrandChange = (event) => {
  //   const { value, checked } = event.target;
  //   if (checked) {
  //     setSelectedBrands((prevBrands) => [...prevBrands, value]);
  //   } else {
  //     setSelectedBrands((prevBrands) =>
  //       prevBrands.filter((brand) => brand !== value)
  //     );
  //   }
  // };
  /**End of code comment by Unnati on 25-07-2024
   * Reason-To handle brand change
   */
  /**Code added by Unnati on 21-06-2024
   * Reason-To handle apply filter button
   */

  const handleClearFilters = () => {
    setSelectedColors([]);
    setSelectedBrands([]);
    setPriceRange([minPrice, maxPrice]);
    setIsModalOpen(false);
  };

  /**End of code addition by Unnati on 21-06-2024
   * Reason-To handle apply filter button
   */

  /**Code added by Unnati on 21-06-2024
   * Reason-To handle dropdown for applying sort change
   */
  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
  };
  const getIcon = () => {
    switch (sortOrder) {
      case "lowToHigh":
        return <FaLongArrowAltUp />;
      case "highToLow":
        return <FaLongArrowAltDown />;
      default:
        return null;
    }
  };
  /**End of code addition by Unnati on 21-06-2024
   * Reason-To handle dropdown for applying sort change
   */

  /**Code added by Unnati on 21-06-2024
   * Reason-To get default filter option as the page loads
   */
  useEffect(() => {
    const fetchFilterOption = async () => {
      try {
        const response = await getFilterOption(id);
        const filteredColors = response.response.distinct_colors.filter(
          (color) => color !== null
        );
        setColors(filteredColors);
        /**Code added by Unnati on 25-07-2024
         * Reason-To set brands
         */
        setBrands(response.response.distinct_brands);

        /**End of code addition by Unnati on 25-07-2024
         * Reason-To set brands
         */

        if (response.response.min_price >= response.response.max_price) {
          //Commented by jhamman on 16-10-2024
          // Reason - want to use default minPrice that is 0
          // setMinPrice(response.response.min_price - 1);
          // End of commentation by jhamman on 16-10-2024
          // Reason - want to use default minPrice that is 0
          setMaxPrice(response.response.max_price + 1);
        } else {
          //Commented by jhamman on 16-10-2024
          // Reason - want to use default minPrice that is 0
          // setMinPrice(response.response.min_price);
          // End of commentation by jhamman on 16-10-2024
          // Reason - want to use default minPrice that is 0
          setMaxPrice(response.response.max_price);
        }
        setPriceRange([
          //Commented by jhamman on 16-10-2024
          // Reason - want to use default minPrice that is 0
          // (priceRange[0], response.response.min_price),
          (priceRange[0], minPrice),
          // End of commentation by jhamman on 16-10-2024
          // Reason - want to use default minPrice that is 0

          (priceRange[1], response.response.max_price),
        ]);
        /**Code added by Unnati on 05-10-2024
         * Reason-To set Response in directParentCategory
         */
        setdirectParentCategory(response.response.direct_parent_categories);
        /**End of code addition by Unnati on 05-10-2024
         * Reason-To set Response in directParentCategory
         */
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchFilterOption();
  }, [setMaxPrice, setMinPrice, setPriceRange, id]);

  /**End of code addition by Unnati on 21-06-2024
   * Reason-To get default filter option as the page loads
   */

  /**Code added by Unnati on 21-06-2024
   * Reason-To get filtered products as the page loads
   */
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        let data;
        // if (filtersApplied) {
        data = await getProduct(
          id,
          selectedColors,
          /**Code added by Unnati on 25-07-2024
           * Reason-To add selected brands
           */
          selectedBrands,
          /**End of code addition by Unnati on 25-07-2024
           * Reason-To add selected brands
           */
          priceRange[0],
          priceRange[1],
          sortBy,
          sortOrder,
          currentPage,
          listPerPage,
          /**Code added by Unnati on 10-08-2024
           * Reason-Added selected Item type
           */
          selectedItemType
          /**End of code addition by Unnati on 10-08-2024
           * Reason-Added selected Item type
           */
        );
        setSortedProducts(data.product);
        setTotalProducts(data.total);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchProduct();
  }, [
    id,
    selectedColors,
    /**Code added by Unnati on 25-07-2024
     * Reason-To add selected brands
     */
    selectedBrands,
    /**End of code addition by Unnati on 25-07-2024
     * Reason-To add selected brands
     */
    priceRange,
    filtersApplied,
    sortBy,
    sortOrder,
    currentPage,
    listPerPage,
    /**Code added by Unnati on 10-08-2024
     * Reason-Added selected Item type
     */
    selectedItemType,
    /**End of code addition by Unnati on 10-08-2024
     * Reason-Added selected Item type
     */
  ]);

  /*End of code addition by Unnati on 21-06-2024
   * Reason-To get filtered products as the page loads
   */

  /**Code added by Unnati on 21-06-2024
   * Reason-To check the sort order and apply sorting
   */
  useEffect(() => {
    let sort_by = "default";
    let sort_order = "asc";

    if (sortOrder === "lowToHigh") {
      sort_by = "sales_rate";
      sort_order = "asc";
    } else if (sortOrder === "highToLow") {
      sort_by = "sales_rate";
      sort_order = "desc";
    }
  }, [sortOrder]);
  /**End of code addition by Unnati on 21-06-2024
   * Reason-To check the sort order and apply sorting
   */

  /**Code added by Unnati on 21-06-2024
   * Reason-To fetch categories for breadcrumbs
   */
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const data = await getCategory();
        setCategory(data.categoryList);
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchCategories();
  }, []);
  /**End of code addition by Unnati on 21-06-2024
   * Reason-To fetch categories for breadcrumbs
   */

  /**Code added by Unnati on 25-06-2024
   * Reason-To create path navigation
   */

  const findInChildren = (categoryId, children) => {
    for (const child of children) {
      if (child.id === parseInt(categoryId)) {
        return child;
      }
      if (child.children && child.children.length > 0) {
        const foundChild = findInChildren(categoryId, child.children);
        if (foundChild) return foundChild;
      }
    }
    return null;
  };

  const getCategoryPath = (categoryId, categories) => {
    let category = categories.find((cat) => cat.id === parseInt(categoryId));

    if (!category) {
      for (const cat of categories) {
        category = findInChildren(categoryId, cat.children);
        if (category) break;
      }
    }

    if (category && category.parent_id) {
      const parentCategory = findParentCategory(category.parent_id, categories);
      if (parentCategory) {
        const parentPath = getCategoryPath(parentCategory.id, categories);
        return [...parentPath, { id: category.id, name: category.name }];
      }
    }

    return category ? [{ id: category.id, name: category.name }] : [];
  };

  const findParentCategory = (parentId, categories) => {
    let parentCategory = categories.find((cat) => cat.id === parentId);

    if (!parentCategory) {
      for (const cat of categories) {
        parentCategory = findInChildren(parentId, cat.children);
        if (parentCategory) break;
      }
    }

    return parentCategory;
  };
  const createBreadcrumbs = (categoryId, categories) => {
    const path = getCategoryPath(categoryId, categories);

    const breadcrumbPath = path.map((category, index) => (
      <span key={category.id}>
        <Link
          to={`/category/${category.id}`}
          className={CategoryStyle.categoryHeading}>
          {category.name}
        </Link>
        {index < path.length - 1 && " > "}
      </span>
    ));
    const selectedCategory = path.length > 0 ? path[path.length - 1].name : "";

    return { breadcrumbPath, selectedCategory };
  };

  useEffect(() => {
    if (category.length > 0) {
      const { breadcrumbPath, selectedCategory } = createBreadcrumbs(
        id,
        category
      );

      setBreadcrumbs(breadcrumbPath);
      setSelectedCategory(selectedCategory);
    }
  }, [id, category]);

  /**End of code addition by Unnati on 25-06-2024
   * Reason-To create path navigation
   */
  const handleListsPerPageChange = (event) => {
    setListPerPage(event.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  /**
   * Added by - Ashlekh on 27-11-2024
   * Reason - To add/remove products in wishlist
   */
  useEffect(() => {
    const newWishlistStatus = {};
    wishListData?.forEach((item) => {
      const key = `${item.product_id}-${item.color}`;
      newWishlistStatus[key] = true; 
    });
    setWishlistStatus(newWishlistStatus);
  }, [wishListData]);
  const handleToggleWishList = async (user, product) => {

    if (user.id == null || user.id == undefined) {
      navigate("/login");
      return;
    } else {
      const productKey = `${product.product_id}-${product.color}`;
      try {
        if (wishlistStatus[productKey]) {
          const response = await removeProductFromWishListAPI(
            user.id,
            product?.product_id,
            product?.color
          );
          localStorage.setItem(
            "wishListData",
            JSON.stringify(response.wishlist)
          );
          setWishListData(response.wishlist);
          if (response?.message) {
            setWishlistStatus((prev) => ({
              ...prev,
              [productKey]: false,
            }));
            notificationObject.success(
              "Product successfully removed from wishlist"
            );
          }
        } else {
          const response = await addToWishListAPI(
            user.id,
            product?.product_id,
            product?.color
          );
          localStorage.setItem(
            "wishListData",
            JSON.stringify(response?.wishlist_data)
          );
          setWishListData(response?.wishlist_data);
          if (response?.message == "Success") {
            setWishlistStatus((prev) => ({
              ...prev,
              [productKey]: true,
            }));
            notificationObject.success(
              "Product successfully added to wishlist"
            );
          } else if (response?.message == "Product Already in Wishlist") {
            notificationObject.success("Product is already in your wishlist");
          } else {
            notificationObject.error("Failed to add product to wishlist");
          }
        }
      } catch (error) {
        console.error("Error updating wishlist:", error);
      }
    }
  };
  /**
   * End of code - Ashlekh on 27-11-2024
   * Reason - To add/remove products in wishlist
   */
  return (
    /**Code added by Unnati on 20-06-2024
     * Reason-To have filter options
     */

    <div className={`${CategoryStyle.pageFrame}`}>
      <div className={`${CategoryStyle.coloredBackground}`}>
        <div className={`${CategoryStyle.breadCrumbs}`}>{breadcrumbs}</div>
        <div className={`${CategoryStyle.pageContainer}`}>
          <div className={`${CategoryStyle.container}`}>
            {/**Code added by Unnati on 04-09-2024
             *Reason-To show no rpoduct found  */}
            {uniqueProducts.length === 0 ? (
              /* Code added by Unnati on 03-09-2024
               *Reason- To show no products available in the category message */
              
              <div className={CategoryStyle.noProductsFound}>
                <h4>No products found in this category</h4>
              </div>
            ) : (
              /* /**End of code addition by Unnati on 04-09-2024
               *Reason-To show no rpoduct found  */
              /**End of code addition by Unnati on 03-09-2024
               *Reason- To show no products available in the category message */
              <>
                <div className={`${CategoryStyle.filterContainer}`}>
                  <h2>SHOP BY</h2>
                  <div className={`${CategoryStyle.mainContent}`}>
                    {/* Filters */}
                    <div className={CategoryStyle.filterContent}>
                      <div className={CategoryStyle.filterGroup}>
                        <div className={CategoryStyle.subtitle}>
                          <h3>Price Range</h3>
                        </div>

                        <div className={CategoryStyle.sliderContainer}>
                          <div className={CategoryStyle.priceLabels}>
                            <div className={CategoryStyle.priceLabelsMinimum}>
                              <span>{`$${priceRange[0]}`}</span>
                            </div>
                            <div className={CategoryStyle.priceLabelsMaximum}>
                              <span>{`$${priceRange[1]}`}</span>
                            </div>
                          </div>
                          <Range
                            values={priceRange}
                            /* Modified by jhamman on 12-10-2024
                            Reason - decrese the range so slider can move properly*/
                            // step={50}
                            step={1}
                            /* End of modification by jhamman on 12-10-2024
                            Reason - decrese the range so slider can move properly*/
                            min={minPrice}
                            max={maxPrice}
                            onChange={handleRangeChange}
                            renderTrack={({ props, children }) => (
                              <div
                                onMouseDown={props.onMouseDown}
                                onTouchStart={props.onTouchStart}
                                className={CategoryStyle.rangeTrackContainer}>
                                <div
                                  ref={props.ref}
                                  style={{
                                    height: "5px",
                                    width: "100%",
                                    borderRadius: "4px",
                                    background: getTrackBackground({
                                      values: priceRange,
                                      /**Code modified by Unnati on 13-10-2024
                                       *Reason-Changed button color
                                       */
                                      colors: [
                                        "#ccc",
                                        "var(--button-color)",
                                        "#ccc",
                                      ],
                                      /**End of code modification by Unnati on 13-10-2024
                                       *Reason-Changed button color
                                       */
                                      min: minPrice,
                                      max: maxPrice,
                                    }),
                                    alignSelf: "center",
                                  }}>
                                  {children}
                                </div>
                              </div>
                            )}
                            renderThumb={({ props, isDragged, key }) => (
                              <div
                                /**Code added by Unnati on 26-10-2024
                                 *Reason-Added key */
                                key={key}
                                /**End of code addition by Unnati on 26-10-2024
                                 *Reason-Added key */
                                {...props}
                                className={`${CategoryStyle.rangeThumb}${
                                  isDragged ? " dragged" : ""
                                }`}>
                                <div
                                  className={CategoryStyle.rangeThumbInner}
                                />
                              </div>
                            )}
                          />
                        </div>
                      </div>
                      <div className={CategoryStyle.filterGroup}>
                        <h3>Color</h3>
                        <div className={CategoryStyle.colorGrid}>
                          {/**Code modified by Unnati on 05-10-2024
                           *Reason-To show color instead of color name*/}
                          {colors.map((color, index) => (
                            <div
                              key={index}
                              className={CategoryStyle.colorBox}
                              style={{
                                backgroundColor: color,
                                border: selectedColors.includes(color)
                                  ? "2px solid #000"
                                  : "1px solid #ccc",
                              }}
                              onClick={() => handleColorChange(color)}
                            />
                            /**End of code modification by Unnati on 05-10-2024
                             *Reason-To show color instead of color name*/
                          ))}
                        </div>
                      </div>
                      {/**Code commented by Unnati on 06-10-2024
                       *Reason-To remove brand */}
                      {/* <div className={CategoryStyle.filterGroup}>
                        <h3>Brands</h3>
                        <div className={CategoryStyle.colorGrid}>
                          {brands.map((brand, index) => (
                            <label
                              key={index}
                              className={CategoryStyle.colorLabel}
                            >
                              <input
                                type="checkbox"
                                value={brand}
                                onChange={handleBrandChange}
                                checked={selectedBrands.includes(brand)}
                              />
                              {brand}
                            </label>
                          ))}
                        </div>
                      </div> */}
                      {/**End of code comment by Unnati on 06-10-2024
                       *Reason-To remove brand */}
                      <button
                        className={CategoryStyle.button}
                        onClick={handleClearFilters}>
                        Clear
                      </button>
                    </div>
                  </div>
                </div>

                <div
                  className={CategoryStyle.productContainer}
                  style={{ width: "100%" }}>
                  <h2 className={CategoryStyle.categoryMainHeading}>
                    {selectedCategory}
                  </h2>
                  {/**Code added by Unnati on 05-10-2024
                   *Reason-To show subcategories in catgeory page */}
                  <div className={CategoryStyle.subCategories}>
                    {directParentCategory.map((cat) => (
                      <div
                        key={cat.id}
                        className={`${CategoryStyle.categories}`}>
                        <Link
                          to={`/category/`}
                          state={cat.id}
                          className={CategoryStyle.categoryLink}>
                          <div className={`${CategoryStyle.category}`}>
                            <div className={`${CategoryStyle.ImageContainer}`}>
                              <img
                                src={`${config.baseURL}${cat.image}`}
                                alt={cat.name}
                                className={`${CategoryStyle.categoryImage}`}
                              />
                            </div>
                            <div className={`${CategoryStyle.categoryTitle}`}>
                              <span>{cat.name}</span>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                  {/**End of code addition by Unnati on 05-10-2024
                   *Reason- To show subcategories in catgeory page*/}
                  <div className={CategoryStyle.pageTitle}>
                    {/**Code added by Unnati on 03-10-2024
                     *Reason-Modified heading */}

                    {/**End of code addition by Unnati on 03-10-2024
                     *Reason-Modified heading */}
                  </div>
                  {/* <div className={CategoryStyle.categoryImage}>
                    <img
                      src="/sale_banner3.jpeg"
                      alt="E-commerce Banner"
                      className={`${CategoryStyle.bannerImage}`}
                    />
                  </div> */}
                  {/* <div className={CategoryStyle.viewToggleButtons}>
                    <div className={CategoryStyle.viewButtons}>
                      <div
                        className={`${CategoryStyle.square} ${
                          viewType === "grid" ? CategoryStyle.active : ""
                        }`}
                      >
                        <p onClick={() => setViewType("grid")}> */}
                  {/* <BsGrid3X2 /> */}
                  {/* </p>
                      </div> */}
                  {/* <div
                    className={`${CategoryStyle.square} ${
                      viewType === "list" ? CategoryStyle.active : ""
                    }`}
                   >
                    <p
                      className={`${CategoryStyle.squareIcon}`}
                      onClick={() => setViewType("list")}
                    >
                      <FaList />
                    </p>
                    </div> */}
                  {/* </div>
                    <div className={CategoryStyle.showProduct}>
                      <label className={CategoryStyle.labelViews}>Show </label>
                      <select
                        className={`${CategoryStyle.dropdownpage} `}
                        value={listPerPage}
                        onChange={handleListsPerPageChange}
                      >
                        <option value={5}>5</option>
                        <option value={8}>8</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>

                      <label> per page</label>
                    </div>
                    
                    </div> */}
                  {/* Added by jhamman on 30-10-2024
                Reason - open a model to add filter when screen size is 720px*/}
                  <div className={CategoryStyle.filterAndShortRow}>
                    <div className={CategoryStyle.filterButton}>
                      <Button type="primary" onClick={showModal}>
                        Create Filter
                      </Button>
                    </div>
                    <Modal
                      open={isModalOpen}
                      footer={null}
                      className={CategoryStyle.filterModel}>
                      <div className={`${CategoryStyle.popupFilterContainer}`}>
                        <h2>SHOP BY</h2>
                        <div className={`${CategoryStyle.mainContent}`}>
                          <div className={CategoryStyle.filterContent}>
                            <div className={CategoryStyle.filterGroup}>
                              <div className={CategoryStyle.subtitle}>
                                <h3>Price Range</h3>
                              </div>

                              <div className={CategoryStyle.sliderContainer}>
                                <div className={CategoryStyle.priceLabels}>
                                  <div
                                    className={
                                      CategoryStyle.priceLabelsMinimum
                                    }>
                                    <span>{`$${priceRange[0]}`}</span>
                                  </div>
                                  <div
                                    className={
                                      CategoryStyle.priceLabelsMaximum
                                    }>
                                    <span>{`$${priceRange[1]}`}</span>
                                  </div>
                                </div>
                                <Range
                                  values={priceRange}
                                  step={1}
                                  min={minPrice}
                                  max={maxPrice}
                                  onChange={handleRangeChange}
                                  renderTrack={({ props, children }) => (
                                    <div
                                      onMouseDown={props.onMouseDown}
                                      onTouchStart={props.onTouchStart}
                                      className={
                                        CategoryStyle.rangeTrackContainer
                                      }>
                                      <div
                                        ref={props.ref}
                                        style={{
                                          height: "5px",
                                          width: "100%",
                                          borderRadius: "4px",
                                          background: getTrackBackground({
                                            values: priceRange,

                                            colors: [
                                              "#ccc",
                                              "var(--button-color)",
                                              "#ccc",
                                            ],

                                            min: minPrice,
                                            max: maxPrice,
                                          }),
                                          alignSelf: "center",
                                        }}>
                                        {children}
                                      </div>
                                    </div>
                                  )}
                                  renderThumb={({ props, isDragged, key }) => (
                                    <div
                                      key={key}
                                      {...props}
                                      className={`${CategoryStyle.rangeThumb}${
                                        isDragged ? " dragged" : ""
                                      }`}>
                                      <div
                                        className={
                                          CategoryStyle.rangeThumbInner
                                        }
                                      />
                                    </div>
                                  )}
                                />
                              </div>
                            </div>
                            <div className={CategoryStyle.filterGroup}>
                              <h3>Color</h3>
                              <div className={CategoryStyle.colorGrid}>
                                {colors.map((color, index) => (
                                  <div
                                    key={index}
                                    className={CategoryStyle.colorBox}
                                    style={{
                                      backgroundColor: color,
                                      border: selectedColors.includes(color)
                                        ? "2px solid #000"
                                        : "1px solid #ccc",
                                    }}
                                    onClick={() => handleColorChange(color)}
                                  />
                                ))}
                              </div>
                            </div>

                            <button
                              className={CategoryStyle.button}
                              onClick={handleClearFilters}>
                              Clear
                            </button>
                          </div>
                        </div>
                      </div>
                    </Modal>
                    {/* End of addition by jhamman on 30-10-2024
                Reason - open a model to add filter when screen size is 720px*/}
                    {uniqueProducts.length > 1 ? (
                      <div className={CategoryStyle.sortContainer}>
                        <p className={CategoryStyle.sortTitle}>Sort By</p>
                        <select value={sortOrder} onChange={handleSortChange}>
                          <option value="default">Default</option>
                          <option value="lowToHigh">Price: Low to High</option>
                          <option value="highToLow">Price: High to Low</option>
                        </select>
                        <div className={CategoryStyle.iconWrapper}>
                          {" "}
                          {getIcon()}{" "}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className={
                      viewType === "grid"
                        ? CategoryStyle.gridContainer
                        : CategoryStyle.listContainer
                    }>
                    {uniqueProducts.map((product) => (
                      <div
                        key={product.id}
                        className={
                          viewType === "grid"
                            ? CategoryStyle.gridCard
                            : CategoryStyle.listCard
                        }>
                          {/* Code commented by - Ashlekh on 27-11-2024
                          Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                          {/* <Link
                          to={`/productdetail/${product.product_id}`}
                          className={CategoryStyle.productLink}> */}
                          <Link
                            to={`/productdetail/${product.product_id}`}
                            /**Code added by Unnati on 05-12-2024
                    *Reason-Send color through state */
                  state={product.color}
                    /**End of code addition by Unnati on 05-12-2024
                    *Reason-Send color through state */
                          >
                            {/* End of code - Ashlekh on 27-11-2024
                            Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                            <div
                              className={
                                viewType === "grid"
                                  ? CategoryStyle.gridImageContainer
                                  : CategoryStyle.listImageContainer
                              }>
                              {/* Added by Jhamman on 06-10-2024
                              Reason - Added offer logo */}
                              <div
                                className={`${CategoryStyle.imageAndOfferLogoContainer}`}>
                                {product.sale_percentage ? (
                                  <div className={CategoryStyle.offerContainer}>
                                    <p className={CategoryStyle.offerPercentage}>
                                      {product.sale_percentage}% off
                                    </p>
                                  </div>
                                ) : null}
                                {/* End of addition by Jhamman on 06-10-2024
                                  Reason - Added offer logo */}
                                <img
                                  src={`${config.baseURL}${product.image1}`}
                                  alt={product.name}
                                  className={
                                    viewType === "grid"
                                      ? CategoryStyle.gridImage
                                      : CategoryStyle.listImage
                                  }
                                />
                              </div>
                              <button
                                className={
                                  viewType === "grid"
                                    ? CategoryStyle.addToCartButton
                                    : CategoryStyle.listaddToCartButton
                                }>
                                Add to Cart
                              </button>
                            </div>
                          </Link>
                          <div
                            className={
                              viewType === "grid"
                                ? CategoryStyle.gridContent
                                : CategoryStyle.listContent
                            }>
                              {/* Added by - Ashlekh on 27-11-2024
                              Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                            <Link
                              to={`/productdetail/${product.product_id}`}
                              /**Code added by Unnati on 05-12-2024
                    *Reason-Send color through state */
                  state={product.color}
                    /**End of code addition by Unnati on 05-12-2024
                    *Reason-Send color through state */
                            >
                              {/* End of code - Ashlekh on 27-11-2024
                              Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                              {/* Code changed by - Ashlekh on 28-11-2024
                              Reason - To change h3 to div tag */}
                              {/* <h3
                                className={
                                  viewType === "grid"
                                    ? CategoryStyle.brandName
                                    : CategoryStyle.listBrandName
                                }> */}
                              <div
                                className={
                                  viewType === "grid"
                                    ? CategoryStyle.brandName
                                    : CategoryStyle.listBrandName
                                }>
                                {/* End of code - Ashlekh on 28-11-2024
                                Reason - To change h3 to div tag */}
                                {/**Code added by Unnati on 12-09-2024
                                 *Reason-If product name is long then show only 50 characters */}
                                {/**Code added by Unnati on 17-10-2024
                                 *Reason-If product name is long then show only 40 characters */}
                                {product.name.substring(0, 40)}
                                {/**End of code addition Unnati on 17-10-2024
                                 *Reason-If product name is long then show only 40 characters */}
                                {/**End of code addition by Unnati on 12-09-2024
                                 *Reason-If product name is long then show only 50 characters */}
                                {/* Code changed by - Ashlekh on 28-11-2024
                                Reason - To change h3 to div tag */}
                                {/* </h3> */}
                              </div>
                              {/* End of code - Ashlekh on 28-11-2024
                              Reason - To change h3 to div tag */}
                            </Link>
                            {/* Added by - Ashlekh on 28-11-2024
                            Reason - To add rating */}
                            <div className={`${CategoryStyle.rating}`}>
                              {product.rating > 0 && (
                                <Rating value={product.rating} />
                              )}
                            </div>
                            {/* End of code - Ashlekh on 28-11-2024
                            Reason - To add rating */}
                            {/* Added by - Ashlekh on 27-11-2024
                            Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                            {/* Commented by - Ashlekh on 28-11-2024
                            Reason - To remove description */}
                            {/* <Link
                              to={`/productdetail/${product.product_id}`}
                            > */}
                              {/* End of code - Ashlekh on 27-11-2024
                              Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                              {/* <h2
                                className={
                                  viewType === "grid"
                                    ? CategoryStyle.cardTitle
                                    : CategoryStyle.listCardTitle
                                }> */}
                                {/**Code added by Unnati on 17-10-2024
                                 *Reason-If product name is long then show only 40 characters */}
                                {/* {product.description.substring(0, 40)} */}
                                {/**End of code addition Unnati on 17-10-2024
                                 *Reason-If product name is long then show only 40 characters */}
                              {/* </h2>
                            </Link>  */}
                            {/* End of comment - Ashlekh on 28-11-2024
                            Reason - To remove description  */}
                              {/* Added by - Ashlekh on 27-11-2024
                              Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                              <Link
                                to={`/productdetail/${product.product_id}`}
                                /**Code added by Unnati on 05-12-2024
                    *Reason-Send color through state */
                  state={product.color}
                    /**End of code addition by Unnati on 05-12-2024
                    *Reason-Send color through state */
                              >
                                {/* End of code - Ashlekh on 27-11-2024
                                Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                                {/* Modified by Jhamman on 05-10-2024
                                  Reason- calculate sale price */}
                                <div className={CategoryStyle.priceContainer}>
                                  {product.sale_percentage ? (
                                    <div
                                      className={
                                        viewType === "grid"
                                          ? CategoryStyle.cardPrice
                                          : CategoryStyle.listCardPrice
                                      }>
                                      <p
                                        className={
                                          CategoryStyle.discountedPriceText
                                        }>
                                        $
                                        {calculateDiscountFromProduct(
                                          product.sales_rate,
                                          product.sale_percentage
                                        )}
                                      </p>
                                    </div>
                                  ) : null}
                                  <div
                                    className={
                                      viewType === "grid"
                                        ? CategoryStyle.cardPrice
                                        : CategoryStyle.listCardPrice
                                    }>
                                    {/* Commented by jhamman on 07-10-2024
                                    Reason - Go through many websites there are not mentioned MRP*/}
                                    {/* <p className={CategoryStyle.mrpPriceText}>
                                      M.R.P:
                                    </p> */}
                                    {/* End of commentation by jhamman on 07-10-2024
                                    Reason - Go through many websites there are not mentioned MRP*/}
                                    <p
                                      className={CategoryStyle.mrpPriceText}
                                      style={
                                        product.sale_percentage
                                          ? {
                                              textDecoration: "line-through",
                                              // Code changed by - Ashlekh on 28-11-2024
                                              // Reason - To change color
                                              // textDecorationColor: "#000",
                                              // color: "red",
                                              textDecorationColor: "#888888",
                                              color: "#888888",
                                              // End of code - Ashlekh on 28-11-2024
                                              // Reason - To change color
                                            }
                                          : { 
                                            // Code changed by - Ashlekh on 28-11-2024
                                            // Reason - To change color
                                            // color: "green"
                                            color: "red"
                                            // End of code - Ashlekh on 28-11-2024
                                            // Reason - To change color
                                            }
                                      }>
                                      ${product.sales_rate}
                                    </p>
                                  </div>

                                  {/* End of modification by Jhamman on 05-10-2024
                                  Reason- calculate sale price */}
                                </div>
                              </Link>
                            {/**Code added by Unnati on 17-10-2024
                             *Reason-Added product rating  */}
                            <div
                              className={CategoryStyle.productDetailContainer}>
                                {/* Added by - Ashlekh on 27-11-2024
                                Reason - To display wishlist icon */}
                                {user.id != undefined && (
                                  <div className={`${CategoryStyle.wishListIconContainer}`}>
                                    {wishlistStatus[`${product.product_id}-${product.color}`] ? (
                                      <MdFavorite
                                        onClick={() => handleToggleWishList(user, product)}
                                        className={`${CategoryStyle.wishListIcon1}`}
                                      />
                                    ) : (
                                      <MdFavoriteBorder
                                        onClick={() => handleToggleWishList(user, product)}
                                        className={`${CategoryStyle.wishListIcon2}`}
                                      />
                                    )}
                                  </div>
                                )}
                                {/* End of code - Ashlekh on 27-11-2024
                                Reason - To display wishlist icon */}
                                {/* Commented by - Ashlekh on 28-11-2024
                                Reason - To remove rating */}
                                {/* <div>
                                  {product.rating > 0 && (
                                    <Rating value={product.rating} />
                                  )}
                                </div> */}
                                {/**Code commented by Unnati on 18-10-2024
                                     *Reason-Commented star component*/
                                    /* <ReactStars
                                      className={CategoryStyle.star}
                                      count={5}
                                      value={product.rating}
                                      size={12}
                                      color2={"#ffd700"}
                                      edit={false}
                                    /> */
                                    /**End of code comment by Unnati on 18-10-2024
                                     *Reason-Commented star component*/
                                    /**Code added by Unnati on 18-10-2024
                                     *Reason-Calling component*/}
                                     {/**End of code addition by Unnati on 18-10-2024
                                      *Reason-Calling component*/}
                                      {/* End of comment - Ashlekh on 28-11-2024
                                      Reason - To remove rating */}
                            </div>
                            {/**End of code addition by Unnati on 17-10-2024
                             *Reason-Added product rating  */}
                          </div>
                          {/* Code commented by - Ashlekh on 27-11-2024
                          Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                        {/* </Link> */}
                        {/* End of code - Ashlekh on 27-11-2024
                        Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                        {/* <div className={CategoryStyle.ButtonContainer}>
                    <button className={CategoryStyle.wishlistButton}>
                      <FaRegHeart /> Wishlist
                    </button>
                  </div> */}
                      </div>
                    ))}
                  </div>

                  {/* <div className={CategoryStyle.viewToggleButtons}>
                    <div className={CategoryStyle.viewButtons}>
                      <div
                        className={`${CategoryStyle.square} ${
                          viewType === "grid" ? CategoryStyle.active : ""
                        }`}
                      >
                        <p */}
                  {/* // className={`${CategoryStyle.squareIcon}`}
                          onClick={() => setViewType("grid")}
                        > */}
                  {/* <BsGrid3X2 /> */}
                  {/* </p>
                      </div> */}
                  {/* <div
                    className={`${CategoryStyle.square} ${
                      viewType === "list" ? CategoryStyle.active : ""
                    }`}
                    >
                    <p
                      className={`${CategoryStyle.squareIcon}`}
                      onClick={() => setViewType("list")}
                    >
                      <FaList />
                    </p>
                    </div> */}
                  {/* </div> */}
                  {/* <div className={`${CategoryStyle.pagination}`}>
                      <Pagination
                        current={currentPage}
                        pageSize={listPerPage}
                        total={totalProducts}
                        onChange={handlePageChange}
                        showSizeChanger={false}
                      />
                    </div> */}
                  {/* </div> */}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {scrollDoc()}
    </div>
  );
};

export default Category;
/**End of code addition by Unnati on 20-06-2024
 * Reason-To have category page
 */
