/**
 * Added by - Ashish Dewangan on 22-05-2024
 * Reason - To store constants values
 */
const Constants = {
  /**Code added by Unnati on 25-11-2024
   * Reason-Added sales_tax variable
   */
  sales_tax:10.25
  /**End of code addition by Unnati on 25-11-2024
   * Reason-Added sales_tax variable
   */
};
export default Constants;
