/**Code added by Unnati on 01-09-2024
 * Reason-To have search result page
 */

import React, { useEffect, useContext, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import styles from "./SearchResult.module.css";
import config from "../../Api/config";
import { calculateDiscountFromProduct } from "../../utils/discountedPrices";
import Rating from "../../components/Rating/Rating";
import { GlobalContext } from "../../context/Context";
import { addToWishListAPI, removeProductFromWishListAPI } from "../../Api/services";
import notificationObject from "../../components/Widgets/Notification/Notification";
import { MdFavorite, MdFavoriteBorder } from "react-icons/md";
const SearchResult = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();
  const scrollDoc = () => {
    window.scrollTo(0, 0);
  };
  // Added by - Ashlekh on 27-11-2024
  // Reason - To add variable from context
  const {user, setUser, wishListData, setWishListData,} = useContext(GlobalContext);
  // End of code - Ashlekh on 27-11-2024
  // Reason - To add variable from context
  // Added by - Ashlekh on 27-11-2024
  // Reason - useState for wishlist
  const [wishlistStatus, setWishlistStatus] = useState({});
  const navigate = useNavigate();
  // End of code - Ashlekh on 27-11-2024
  // Reason - useState for wishlist
  /**Code added by Unnati on 01-09-2024
   * Reason-To get searched products through location
   */
  const { products, query } = location.state || "";
  /**End of code addition by Unnati on 01-09-2024
   * Reason-To get searched products through location
   */
  /**
   * Added by - Ashlekh on 27-11-2024
   * Reason - To add/remove products in wishlist
   */
  useEffect(() => {
    const newWishlistStatus = {};
    wishListData?.forEach((item) => {
      const key = `${item.product_id}-${item.color}`;
      newWishlistStatus[key] = true; 
    });
    setWishlistStatus(newWishlistStatus);
  }, [wishListData]);
  const handleToggleWishList = async (user, product) => {

    if (user.id == null || user.id == undefined) {
      navigate("/login");
      return;
    } else {
      const productKey = `${product.product_id}-${product.color}`;
      try {
        if (wishlistStatus[productKey]) {
          const response = await removeProductFromWishListAPI(
            user.id,
            product?.product_id,
            product?.color
          );
          localStorage.setItem(
            "wishListData",
            JSON.stringify(response.wishlist)
          );
          setWishListData(response.wishlist);
          if (response?.message) {
            setWishlistStatus((prev) => ({
              ...prev,
              [productKey]: false,
            }));
            notificationObject.success(
              "Product successfully removed from wishlist"
            );
          }
        } else {
          const response = await addToWishListAPI(
            user.id,
            product?.product_id,
            product?.color
          );
          localStorage.setItem(
            "wishListData",
            JSON.stringify(response?.wishlist_data)
          );
          setWishListData(response?.wishlist_data);
          if (response?.message == "Success") {
            setWishlistStatus((prev) => ({
              ...prev,
              [productKey]: true,
            }));
            notificationObject.success(
              "Product successfully added to wishlist"
            );
          } else if (response?.message == "Product Already in Wishlist") {
            notificationObject.success("Product is already in your wishlist");
          } else {
            notificationObject.error("Failed to add product to wishlist");
          }
        }
      } catch (error) {
        console.error("Error updating wishlist:", error);
      }
    }
  };
  /**
   * End of code - Ashlekh on 27-11-2024
   * Reason - To add/remove products in wishlist
   */
  return (
    <div className={styles.pageFrame}>
      <div className={styles.coloredBackground}>
        <div className={styles.pageContainer}>
          {/**Code added by Unnati on 03-10-2024
           *Reason-Added a div tag */}
          <div className={styles.pageTitle}>
            <h2>Search Results for "{query}"</h2>
          </div>
          {/**End of code addition by Unnati on 03-10-2024
           *Reason-Added a div tag */}
          <div className={styles.gridContainer}>
            {/**Code added by Unnati on 01-09-2024
             *Reason-To map product details */}
            {products.map((product) => (
              <div key={product.id} className={styles.gridCard}>
                {/* Code commented by - Ashlekh on 27-11-2024
                Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                {/* <Link
                  to={`/productdetail/${product.product_id}`}
                  className={styles.productLink}
                > */}
                <Link
                  to={`/productdetail/${product.product_id}`}
                  /**Code added by Unnati on 05-12-2024
                    *Reason-Send color through state */
                  state={product.color}
                    /**End of code addition by Unnati on 05-12-2024
                    *Reason-Send color through state */
                >
                  {/* End of code - Ashlekh on 27-11-2024
                  Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                  <div className={styles.gridImageContainer}>
                    {/* Added by Jhamman on 11-10-2024
                    Reason - Added offer logo */}
                    <div className={`${styles.imageAndOfferLogoContainer}`}>
                      {product.sale_percentage ? (
                        <div className={styles.offerContainer}>
                          <p className={styles.offerPercentage}>
                            {product.sale_percentage}% off
                          </p>
                        </div>
                      ) : null}

                      {/* End of addition by Jhamman on 11-10-2024
                        Reason - Added offer logo */}
                      <img
                        src={`${config.baseURL}${product.image1}`}
                        alt={product.name}
                        className={styles.gridImage}
                      />
                    </div>
                    <button className={styles.addToCartButton}>
                      Add to Cart
                    </button>
                  </div>
                </Link>
                  <div className={styles.gridContent}>
                    {/* Added by - Ashlekh on 27-11-2024
                    Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                    <Link
                      to={`/productdetail/${product.product_id}`}
                    >
                      {/* End of code - Ashlekh on 27-11-2024
                      Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                      {/* Code changed by - Ashlekh on 28-11-2024
                      Reason - To change h3 to div tag */}
                      {/* <h3 className={styles.brandName}> */}
                      <div className={styles.brandName}>
                        {/**Code added by Unnati on 16-10-2024
                         */}
                        {product.name.length > 40
                          ? `${product.name.substring(0, 40)}...`
                          : product.name}
                      {/* </h3> */}
                      </div>
                      {/* End of code - Ashlekh on 28-11-2024
                      Reason - To change h3 to div tag */}
                    </Link>
                    {/* Added by - Ashlekh on 28-11-2024
                    Reason - To add rating */}
                    <div className={`${styles.rating}`}>
                      {product.rating > 0 && (
                        <Rating value={product.rating} />
                      )}
                    </div>
                    {/* End of code - Ashlekh on 28-11-2024
                    Reason - To add rating */}
                    {/* Added by - Ashlekh on 27-11-2024
                    Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                    {/* Commented by - Ashlekh on 28-11-2024
                    Reason - To remove description */}
                    {/* <Link
                      to={`/productdetail/${product.product_id}`}
                    > */}
                      {/* End of code - Ashlekh on 27-11-2024
                      Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                      {/* <h4 className={styles.cardTitle}>
                        {product.description.length > 40
                          ? `${product.description.substring(0, 40)}...`
                          : product.description}
                      </h4>
                    </Link> */}
                    {/**End of code addition by Unnati on 16-10-2024
                     */}
                    {/* End of code - Ashlekh on 28-11-2024
                    Reason - To remove description */}
                    {/* Added by - Ashlekh on 27-11-2024
                    Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                    <Link
                      to={`/productdetail/${product.product_id}`}
                    >
                      {/* End of code - Ashlekh on 27-11-2024
                      Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                      {/* Modified by Jhamman on 05-10-2024
                      Reason- calculate sale price */}
                      {/* <p className={styles.cardPrice}>${product.sales_rate}</p> */}

                      <div className={styles.priceContainer}>
                        {product.sale_percentage ? (
                          <div className={styles.cardPrice}>
                            <p className={styles.discountedPriceText}>
                              $
                              {calculateDiscountFromProduct(
                                product.sales_rate,
                                product.sale_percentage
                              )}
                            </p>
                          </div>
                        ) : null}
                        <div className={`${styles.cardPrice}`}>
                          <p
                            className={styles.mrpPriceText}
                            style={
                              product.sale_percentage
                                ? {
                                    textDecoration: "line-through",
                                    // Code changed by - Ashlekh on 28-11-2024
                                    // Reason - To change color
                                    // textDecorationColor: "#000",
                                    // color: "red",
                                    textDecorationColor: "#888888",
                                    color: "#888888",
                                    // End of code - Ashlekh on 28-11-2024
                                    // Reason - To change color
                                  }
                                : { 
                                  // Code changed by - Ashlekh on 28-11-2024
                                  // Reason - To change color
                                  // color: "green"
                                  color: "red"
                                  // End of code - Ashlekh on 28-11-2024
                                  // Reason - To change color
                                 }
                            }
                          >
                            ${product.sales_rate}
                          </p>
                        </div>
                      </div>
                      {/*Code by Unnati on 17-10-2024
                      *Reason-added star rating
                      */}
                    </Link>
                    <div className={styles.productDetailContainer}>
                      {/* Added by - Ashlekh on 27-11-2024
                      Reason - To display wishlist icon */}
                      {user.id != undefined && (
                        <div className={`${styles.wishListIconContainer}`}>
                          {wishlistStatus[`${product.product_id}-${product.color}`] ? (
                            <MdFavorite
                              onClick={() => handleToggleWishList(user, product)}
                              className={`${styles.wishListIcon1}`}
                            />
                          ) : (
                            <MdFavoriteBorder
                              onClick={() => handleToggleWishList(user, product)}
                              className={`${styles.wishListIcon2}`}
                            />
                          )}
                        </div>
                      )}
                      {/* End of code - Ashlekh on 27-11-2024
                      Reason - To display wishlist icon */}
                      {/* Commented by - Ashlekh on 28-11-2024
                      Reason - To remove rating */}
                      {/* <div>
                        {product.rating > 0 && (
                          <Rating value={product.rating} />
                        )}
                      </div> */}
                      {/**Code commented by Unnati on 18-10-2024
                           *Reason-Commented star component*/
                          /* <ReactStars
                                className={styles.star}
                                count={5}
                                value={product.rating}
                                size={15}
                                color2={"#ffd700"}
                                edit={false}
                              /> */
                          /**End of code comment by Unnati on 18-10-2024
                           *Reason-Commented star component*/
                          /**Code added by Unnati on 18-10-2024
                           *Reason-Calling component*/
                          // <Rating value={product.rating} />
                          /**End of code addition by Unnati on 18-10-2024
                           *Reason-Calling component*/}
                           {/* End of code - Ashlekh on 28-11-2024
                           Reason - To remove rating */}
                    </div>
                    {/*End of code addition on 17-10-2024
                     *Reason-added star rating
                     */}
                    {/* End of modification by Jhamman on 05-10-2024
                              Reason- calculate sale price */}
                  </div>
                  {/* Code commented by - Ashlekh on 27-11-2024
                  Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
                {/* </Link> */}
                {/* End of code - Ashlekh on 27-11-2024
                Reason - To add wishlist icon in bottom of card (while clicking on wishlist icon user was navigating in product detail page) */}
              </div>
            ))}
            {/**End of code addition by Unnati on 01-09-2024
             *Reason-To map product details */}
          </div>
        </div>
      </div>
      {scrollDoc()}
    </div>
  );
};

export default SearchResult;
/**End of code addition by Unnati on 01-09-2024
 * Reason-To have search result page
 */
