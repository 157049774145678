/**Code added by Unnati on 03-08-2024
 * Reason-To have order detail page
 */

import React, { useEffect, useState, useContext } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import {
  addTrackingDetailsForItem,
  getOrderDetail,
  performItemReturn,
  performItemExchange,
  UpdateCancellationTime,
  addExchangeTrackingDetailsForItem,
  getProductSizeAndColor
} from "../../Api/services";
import config from "../../Api/config";
import OrderDetailStyle from "./OrderDetail.module.css";
import { GlobalContext } from "../../context/Context";
import { DateFormatter } from "../../utils/DateFormat";
import { useNavigate } from "react-router-dom";
import Constants from "../../constants/Constants";
const OrderDetail = () => {
  const { id } = useParams();
  const location = useLocation();
  const { order } = location.state || {};
  const [orderDetail, setOrderDetail] = useState([]);
  const [orderStatus, setOrderStatus] = useState([]);
  const [Order, setOrder] = useState([]);
  const { user } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [selectedReason, setSelectedReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [itemToCancel, setItemToCancel] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedProduct,setSelectedProduct] =useState(null);
  // Added by - Ashlekh on 21-11-2024
  // Reason - useState for loader
  const [isLoading, setIsLoading] = useState(true);
  // End of code - Ashlekh on 21-11-2024
  // Reason - useState for loader
  /**Code added by Unnati Bajaj on 03-08-2024
   * Reason -To get order details and user address when the component loads
   */

  /**
   * Added by - Ashish Dewangan on 23-11-2024
   * Reason - To store setting details
   */
  const [settings, setSettings] = useState({});
  /**
   * End of addition by - Ashish Dewangan on 23-11-2024
   * Reason - To store setting details
   */

  const [showReturnModal, setShowReturnModal] = useState(false);
  const [selectedReturnReason, setSelectedReturnReason] = useState("");
  const [otherReturnReason, setOtherReturnReason] = useState("");
  const [itemToReturn, setItemToReturn] = useState(null);

  const [itemImage1, setItemImage1] = useState();
  const [itemImage2, setItemImage2] = useState();

  const [showContactPopupAfterReturn, setShowContactPopupAfterReturn] =
    useState(false);

  /**
   * Added by - Ashish Dewangan on 25-11-2024
   * Reason - defined varibles for storing tracking details
   */
  const [returnTrackingId, setReturnTrackingId] = useState("");
  const [returnTrackingIdError, setReturnTrackingIdError] = useState("");

  const [returnCourierProviderName, setReturnCourierProviderName] =
    useState("");
  const [returnCourierProviderNameError, setReturnCourierProviderNameError] =
    useState("");
  /**
   * End of addition by - Ashish Dewangan on 25-11-2024
   * Reason - defined varibles for storing tracking details
   */
  const [showExchangeModal, setShowExchangeModal] = useState(false);
  const [selectedExchangeReason, setSelectedExchangeReason] = useState("");
  const [otherExchangeReason, setOtherExchangeReason] = useState("");
  const [itemToExchange, setItemToExchange] = useState(null);
  const [showContactPopupAfterExchange, setShowContactPopupAfterExchange] =
  useState(false);
   /**
   * Added by - Ashish Dewangan on 25-11-2024
   * Reason - defined varibles for storing tracking details
   */
   const [exchangeTrackingId, setExchangeTrackingId] = useState("");
   const [exchangeTrackingIdError, setExchangeTrackingIdError] = useState("");
 
   const [exchangeCourierProviderName, setExchangeCourierProviderName] =
     useState("");
   const [exchangeCourierProviderNameError, setExchangeCourierProviderNameError] =
     useState("");
     const [availableSizes, setAvailableSizes] = useState({});
     const [selectedColor, setSelectedColor] = useState("");
     const [products, setProducts] = useState([]);
     const [validationMessage, setValidationMessage] = useState("");
     const [productItems, setProductItems] = useState([]);
     const [quantity, setQuantity] = useState(1);
   /**
    * End of addition by - Ashish Dewangan on 25-11-2024
    * Reason - defined varibles for storing tracking details
    */
  /**
   * Modified by - Ashish Dewangan on 24-11-2024
   * Reason - To make fetchOrderDetail available outside useEffect for reusability
   */
  // useEffect(() => {
  //   const fetchOrderDetail = async () => {
  //     try {
  //       const response = await getOrderDetail(id, user.id);
  //       setOrderDetail(response.orderItems);
  //       /**Code added by Unnati on 18-09-2024
  //        * Reason-To set order status
  //        */
  //       setOrderStatus(response.orderSummary);
  //       /**End of code addition by Unnati on 18-09-2024
  //        * Reason-To set order status
  //        */
  //       setOrder(response);
  //       // Added by - Ashlekh on 21-11-2024
  //       // Reason - To set loader false
  //       setIsLoading(false);
  //       // End of code - Ashlekh on 21-11-2024
  //       // Reason - To set loader false

  //       /**
  //        * Added by - Ashish Dewangan on 23-11-2024
  //        * Reason - To store setting details
  //        */
  //       setSettings(response?.settings)
  //       /**
  //        * End of addition by - Ashish Dewangan on 23-11-2024
  //        * Reason - To store setting details
  //        */
  //     } catch (error) {
  //       console.error("Failed to fetch order details:", error);
  //     }
  //   };
  //   fetchOrderDetail();
  // }, [id, user.id]);

  useEffect(() => {
    fetchOrderDetail();
  }, [id, user.id]);
  const fetchOrderDetail = async () => {
    try {
      const response = await getOrderDetail(id, user.id);
      setOrderDetail(response.orderItems);
      /**Code added by Unnati on 18-09-2024
       * Reason-To set order status
       */
      setOrderStatus(response.orderSummary);
      /**End of code addition by Unnati on 18-09-2024
       * Reason-To set order status
       */
      setOrder(response);
      // Added by - Ashlekh on 21-11-2024
      // Reason - To set loader false
      setIsLoading(false);
      // End of code - Ashlekh on 21-11-2024
      // Reason - To set loader false

      /**
       * Added by - Ashish Dewangan on 23-11-2024
       * Reason - To store setting details
       */
      setSettings(response?.settings);
      /**
       * End of addition by - Ashish Dewangan on 23-11-2024
       * Reason - To store setting details
       */
    } catch (error) {
      console.error("Failed to fetch order details:", error);
    }
  };

  /**
   * End of modification by - Ashish Dewangan on 24-11-2024
   * Reason - To make fetchOrderDetail available outside useEffect for reusability
   */

  /**Code added by Unnati Bajaj on 03-08-2024
   * Reason -To get order details and user address when the component loads
   */

  const handleViewInvoice = (Order) => {
    navigate(`/invoice/${Order.id}`, { state: { Order } });
  };
  /**Code added by Unnati on 08-11-2024
   * Reason-Added handle Credit note
   */
  const handleCreditNote = (Order) => {
    navigate(`/creditnote/${Order.id}`, { state: { Order } });
  };
  /**End of code addition by Unnati on 08-11-2024
   * Reason-Added handle Credit note
   */
  const total = orderDetail
    .reduce((accumulator, item) => {
      return accumulator + item.amount * item.quantity;
    }, 0)
    .toFixed(2);
  /**Code added by Unnati on 06-11-2024
   * Reason-To handle cancel button
   */
  const handleCancelButton = async (e, ItemId, order) => {
    e.preventDefault();

    const cancellationReason =
      selectedReason === "Other" && otherReason ? otherReason : selectedReason;

    const OrderItemCancellationTime = new Date().toLocaleString(undefined, {
      timeZone: "Asia/Kolkata",
    });
    if (cancellationReason) {
      /**Code added by Unnati on 24-11-2024
       * Reason-Assigned variable
       */
      const response = await UpdateCancellationTime(
        /*End of code addition by Unnati on 24-11-2024
         * Reason-Assigned variable
         */
        ItemId,
        order,
        OrderItemCancellationTime,
        cancellationReason
      );
      /**Code added by Unnati on 23-11-2024
       * Reason-To get order details after updating cancellation reason
       */
      if (response.message) {
        const response = await getOrderDetail(id, user.id);
        setOrderDetail(response.orderItems);
        setOrderStatus(response.orderSummary);
        setOrder(response);
        setIsLoading(false);
      }
      /*End of code addition by Unnati on 23-11-2024
       * Reason-To get order details after updating cancellation reason
       */
      setShowCancelModal(false);
      setSelectedReason("");
      setOtherReason("");
    } else {
      alert("Please select a cancellation reason.");
    }
  };
  /**End of code addition by Unnati on 06-11-2024
   * Reason-To handle cancel button
   */
  /**Code added by Unnati on 11-11-2024
   * Reason-Added handleCancelModal
   */
  const handleCancelModal = (itemId) => {
    setItemToCancel(itemId);
    setShowCancelModal(true);
  };
  /**End of code addition by Unnati on 11-11-2024
   * Reason-Added handleCancelModal
   */

  /** Added by Ashish Dewangan on 24-11-2024
   * Reason- To call return API once return button of modal is clicked
   */
  const handleReturnButton = async (e, ItemId, orderId) => {
    e.preventDefault();

    const returnReason =
      selectedReturnReason === "Other" && otherReturnReason
        ? otherReturnReason
        : selectedReturnReason;

    const OrderItemReturnTime = new Date().toLocaleString(undefined, {
      timeZone: "Asia/Kolkata",
    });

    if (returnReason) {
      const response = await performItemReturn(
        ItemId,
        orderId,
        OrderItemReturnTime,
        returnReason,
        itemImage1,
        itemImage2
      );

      setShowReturnModal(false);
      setSelectedReturnReason("");
      setOtherReturnReason("");
      setShowContactPopupAfterReturn(true);

      setIsLoading(true);
      fetchOrderDetail();
    } else {
      alert("Please select a return reason.");
    }
  };
  /**End of code addition by Ashish Dewangan on 24-11-2024
   * Reason- To call return API once return button of modal is clicked
   */

  /**
   * Added by - Ashish Dewangan on 23-11-2024
   * Reason - To show return modal
   */
  const handleReturnModal = (itemId) => {
    setItemToReturn(itemId);
    setShowReturnModal(true);
  };
  /**
   * End of addition by - Ashish Dewangan on 23-11-2024
   * Reason - To show return modal
   */
    /**Code added by Unnati on 28-08-2024
   * Reason-To check is size is available or not
   */
    const isSizeAvailable = (size) => {
      return availableSizes[size] > 0;
    };
    /**End of code addition  by Unnati on 28-08-2024
     * Reason-To check is size is available or not
     */
    /** Added by Ashish Dewangan on 24-11-2024
   * Reason- To call return API once return button of modal is clicked
   */
    const handleExchangeButton = async (e, ItemId, orderId,Item) => {
      e.preventDefault();
  
      const exchangeReason =
        selectedExchangeReason === "Other" && otherExchangeReason
          ? otherExchangeReason
          : selectedExchangeReason;
  
      const OrderItemExchangeTime = new Date().toLocaleString(undefined, {
        timeZone: "Asia/Kolkata",
      });
      const {
        size,
        customization_comment,
        logo,
        patches,
        security_batches,
        embroider,
      } = formData;
      console.log("form data",formData)
      // Calculation of customization
      let basePrice = parseFloat(Item?.sales_rate) || 0;
      console.log("..........base price",basePrice)
      console.log(".item",Item)
      if (Item.sale_percentage) {
        basePrice =
        Item?.sales_rate -
          (Item?.sales_rate * Item?.sale_percentage) / 100;
          console.log("..........sale percentage base price",basePrice)
      }
      if (Item.logo) {
        basePrice += parseFloat(Item.logo_price);
        console.log("logo",basePrice)
      }
  
      if (Item.patches) {
        basePrice += parseFloat(Item.patches_price);
        console.log("patches",basePrice)
      }
  
      if (Item.security_batches) {
        basePrice += parseFloat(Item.security_batches_price);
      }
  
      if (Item.embroider) {
        basePrice += parseFloat(Item.embroider_price);
      }
  
      let customizationPrice = basePrice;
      const formattedCustomizationPrice = isNaN(customizationPrice)
        ? "0.00"
        : customizationPrice.toFixed(2);
        console.log(".....................................customised price",formattedCustomizationPrice)
      setFormData((prevState) => ({
        ...prevState,
        after_customization_product_price: formattedCustomizationPrice,
        customization_comment,
        logo,
        patches,
        security_batches,
        embroider,
        logo_price: parseFloat(Item?.logo_price) || "0.00",
        patches_price: parseFloat(Item?.patches_price) || "0.00",
        security_batches_price:
          parseFloat(Item?.security_batches_price) || "0.00",
        embroider_price: parseFloat(Item?.embroider_price) || "0.00",
      }));
      if (exchangeReason) {
        const response = await performItemExchange(
          ItemId,
          orderId,
          OrderItemExchangeTime,
          exchangeReason,
          itemImage1,
          itemImage2,
          selectedColor,
          selectedSize,
          quantity,
          (formData.after_customization_product_price =
            formattedCustomizationPrice),
          formData.customization_comment,
          formData.logo,
          formData.patches,
          formData.security_batches,
          formData.embroider
        
        );
  
        setShowExchangeModal(false);
        setSelectedExchangeReason("");
        setOtherExchangeReason("");
        setShowContactPopupAfterExchange(true);
        setQuantity(1);
        setIsLoading(true);
        fetchOrderDetail();
      } else {
        alert("Please select a exchange reason.");
      }
    };
    /**End of code addition by Ashish Dewangan on 24-11-2024
     * Reason- To call return API once return button of modal is clicked
     */
 /**
   * Added by - Unnati Bajaj on 29-11-2024
   * Reason - To show exchange modal
   */
 const handleExchangeModal = async (itemId) => {
  console.log("id///////////////",itemId)
  const response = await getProductSizeAndColor(itemId);
  setProducts(response.products);
  console.log(".........................",response.products)
  if(response.products.length>0){
  const sizes = {
    XS: response.products[0].XS,
    S: response.products[0].S,
    M: response.products[0].M,
    L: response.products[0].L,
    XL: response.products[0].XL,
    XXL: response.products[0].XXL,
    XXXL: response.products[0].XXXL,
  };
  setAvailableSizes(sizes);
 setSelectedProduct(response.products[0])
}
setItemToExchange(itemId);
setShowExchangeModal(true);
};
{
  /**Code added by Unnati on 23-06-2024
   *Reason-Created a set of unique colors and maps ieach product to its color*/
}
const uniqueColors = [...new Set(products.map((product) => product.color))];
{
  /**End of code addition by Unnati on 23-06-2024
   *Reason-Created a set of unique colors and maps ieach product to its color*/
}
/**
 * End of addition by - Unnati Bajaj on 29-11-2024
 * Reason - To show exchange modal
 */
  /**
   * Added by - Ashish Dewangan on 24-11-2024
   * Reason - to format date string if string is in the format of 2024-11-24T14:36:40+05:30
   */
  function formatDateToMMDDYYYY(dateString) {
    const date = new Date(dateString);

    // Extract date components
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();

    // Format and return the result
    return `${month}/${day}/${year}`;
  }
  /**
   * End of addition by - Ashish Dewangan on 24-11-2024
   * Reason - to format date string if string is in the format of 2024-11-24T14:36:40+05:30
   */

  /**
   * Added by - Ashish Dewangan on 25-11-2024
   * Reason - added a method that will call API to add tracking details for the
   * item which is being returned
   */
  const submitReturnTrackingId = async (e, ItemId, orderId) => {
    e.preventDefault();
    if (returnTrackingId.trim().length < 1) {
      setReturnTrackingIdError("Please enter a tracking id");
    } else {
      setReturnTrackingIdError("");
    }
    if (returnCourierProviderName.trim().length < 1) {
      setReturnCourierProviderNameError(
        "Please enter name of the courier service"
      );
    } else {
      setReturnCourierProviderNameError("");
    }
    if (
      returnTrackingId.trim().length > 1 &&
      returnCourierProviderName.trim().length > 1
    ) {
      const response = await addTrackingDetailsForItem(
        ItemId,
        orderId,
        returnTrackingId,
        returnCourierProviderName
      );
      setIsLoading(true);
      fetchOrderDetail();
    }
  };

  /**
   * End of addition by - Ashish Dewangan on 25-11-2024
   * Reason - added a method that will call API to add tracking details for the
   * item which is being returned
   */
    /**
   * Added by - Ashish Dewangan on 25-11-2024
   * Reason - added a method that will call API to add tracking details for the
   * item which is being returned
   */
    const submitExchangeTrackingId = async (e, ItemId, orderId) => {
      e.preventDefault();
      console.log("..........exchange")
      if (exchangeTrackingId.trim().length < 1) {
        setExchangeTrackingIdError("Please enter a tracking id");
      } else {
        setExchangeTrackingIdError("");
      }
      if (exchangeCourierProviderName.trim().length < 1) {
        setExchangeCourierProviderNameError(
          "Please enter name of the courier service"
        );
      } else {
        setExchangeCourierProviderNameError("");
      }
      if (
        exchangeTrackingId.trim().length > 1 &&
        exchangeCourierProviderName.trim().length > 1
      ) {
        const response = await addExchangeTrackingDetailsForItem(
          ItemId,
          orderId,
          exchangeTrackingId,
          exchangeCourierProviderName
        );
        setIsLoading(true);
        fetchOrderDetail();
      }
    };
  
    /**
     * End of addition by - Ashish Dewangan on 25-11-2024
     * Reason - added a method that will call API to add tracking details for the
     * item which is being returned
     */
    const handleSelectChange = (size) => {
      setSelectedSize(size);
      // setQuantityMessage("");
      // setQuantity(1);
      // const stock = availableSizes[size];
      // updateAvailabilityMessage(size, stock);
      /**Code added by Unnati on 25-10-2024
       * Reason-To set error message as empty on change
       */
      // setSizeError("");
      /*End of code addition by Unnati on 25-10-2024
       * Reason-To set error message as empty on change
       */
    };
      /**Code added by Unnati on 23-06-2024
   *Reason-To select color and show the available sizes
   */
  const handleColorSelect = (color) => {
    setSelectedColor(color);
    const selectedProduct = products.find((product) => product.color === color);
    // setSelectedProductDetails(selectedProduct);
    console.log(selectedProduct,"selected")
    setSelectedProduct(selectedProduct)
    const sizes = {
      XS: selectedProduct.XS,
      S: selectedProduct.S,
      M: selectedProduct.M,
      L: selectedProduct.L,
      XL: selectedProduct.XL,
      XXL: selectedProduct.XXL,
      XXXL: selectedProduct.XXXL,
    };

    setAvailableSizes(sizes);

    if (selectedSize) {
      const stock = sizes[selectedSize];
      // updateAvailabilityMessage(selectedSize, stock);
      /**Code added by Unnati on 24-08-2024
       * Reason-Added quantity message
       */
      // QuantityMessage(selectedSize, stock);
      /**End of code addition by Unnati on 24-08-2024
       * Reason-Added quantity message
       */
    }
  };
  /**End of code addition by Unnati on 23-06-2024
   *Reason-To select color and show the available sizes
   */
    // Added by - Ashlekh on 03-12-2024
  // Reason - useState for storing customized data
  const [formData, setFormData] = useState({
    size: "",
    logo: false,
    patches: false,
    security_batches: false,
    embroider: false,
    customization_comment: "",
    logo_price: "",
    patches_price: "",
    security_batches_price: "",
    embroider_price: "",
    after_customization_product_price: "",
  });
  const initialFormData = {
    size: "",
    logo: false,
    patches: false,
    security_batches: false,
    embroider: false,
    customization_comment: "",
  };
  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
    // if (type === "checkbox" && checked) {
    //   setValidationMessage("");
    // }
  };
  // End of code - Ashlekh on 03-12-2024
  // Reason - useState for storing customized data
  return (
    <div className={OrderDetailStyle.pageFrame}>
      <div className={OrderDetailStyle.pageContainer}>
        <div className={OrderDetailStyle.coloredBackground}>
          <div className={OrderDetailStyle.formContainer}>
            <h2 className={OrderDetailStyle.pageTitle}>Order Details</h2>
            {/* Added by - Ashlekh on 21-11-2024
            Reason - To add loader */}
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "30vh",
                }}
              >
                <img style={{ height: "30vh" }} src="/adyant_loader.gif" />
              </div>
            ) : (
              // End of code - Ashlekh on 21-11-2024
              // Reason - To add loader
              <div>
                <div className={OrderDetailStyle.orderInfo}>
                  <div className={OrderDetailStyle.orderIdContainer}>
                    <p className={OrderDetailStyle.orderIdLabel}>
                      <strong>Order ID:</strong>
                    </p>
                    <p className={OrderDetailStyle.orderId}>
                      {orderStatus.order_id || "N/A"}
                    </p>
                  </div>
                  <div className={OrderDetailStyle.orderDateContainer}>
                    <p className={OrderDetailStyle.orderDateLabel}>
                      <strong>Payment Status:</strong>
                    </p>
                    <p className={OrderDetailStyle.orderDate}>
                      {orderStatus.payment_status}
                    </p>
                  </div>
                  {/* Commented by - Ashish Dewangan on 24-11-2024
                   * Reason - No need to display this details */}
                  {/* <div className={OrderDetailStyle.orderDateContainer}>
                    <p className={OrderDetailStyle.orderDateLabel}>
                      <strong>Order Status:</strong>
                    </p>
                    <p className={OrderDetailStyle.orderDate}>
                      {orderStatus.order_status}
                    </p>
                    
                  </div> */}
                  {/* End of comment by - Ashish Dewangan on 24-11-2024
                   * Reason - No need to display this details */}
                  <div className={OrderDetailStyle.orderDateContainer}>
                    <p className={OrderDetailStyle.orderDateLabel}>
                      <strong>Date:</strong>
                    </p>
                    {/* Modified by jhamman on 19-10-2024
                Reason - changed date format*/}
                    {/* <p className={OrderDetailStyle.orderDate}>
                  {orderStatus.date || "N/A"}
                </p> */}
                    <p className={OrderDetailStyle.orderDate}>
                      {DateFormatter(orderStatus.date) || "N/A"}
                    </p>
                    {/* End of modification by jhamman on 19-10-2024
                Reason - changed date format*/}
                  </div>
                </div>

                {/* Code added by Unnati on 18-09-2024
                 *Reason-To map Order Details */}
                <div className={OrderDetailStyle.orderDetailsContainer}>
                  {orderDetail.map((item) => {
                    /**
                     * Added by - Ashish Dewangan on 23-11-2024
                     * Reason - To count how many days have passed since the order
                     */
                    /**
                     * Modified by - Ashish Dewangan on 24-11-2024
                     * Reason - To count days after delivery rather than count from order date
                     */
                    // var elapsedDate = (new Date() - new Date(item.delivery_date)) / (1000 * 60 * 60 * 24)
                    // var max_return_days = settings.max_return_days ? settings.max_return_days : 30
                    if (item.delivery_date != null) {
                      var elapsedDate =
                        (new Date() - new Date(item.delivery_date)) /
                        (1000 * 60 * 60 * 24);
                      var max_return_days = settings.max_return_days
                        ? settings.max_return_days
                        : 30;
                        /**Code added by Unnati on 29-11-2024
                        *Reason-Added max exchange days */
                        var max_exchange_days = settings.max_exchange_days
                        ? settings.max_exchange_days
                        : 30;
                        /**End of code addition by Unnati on 29-11-2024
                        *Reason-Added max exchange days */
                    }
                    /**
                     * End of modification by - Ashish Dewangan on 24-11-2024
                     * Reason - To count days after delivery rather than count from order date
                     */
                    /**
                     * End of addition by - Ashish Dewangan on 23-11-2024
                     * Reason - To count how many days have passed since the order
                     */
                    return (
                      <div className={OrderDetailStyle.orderCard} key={item.id}>
                        <div className={OrderDetailStyle.statusSection}>
                          <div className={OrderDetailStyle.status}></div>
                          {/* <div className={OrderDetailStyle.date}><span>{item.date || "N/A"}</span></div> */}
                        </div>

                        <div className={OrderDetailStyle.productSection}>
                          <div className={OrderDetailStyle.orderItemImage}>
                            {/**Code added by Unnati on 30-09-2024
                             *Reason-To add link in image section */}
                            {item.is_active ? (
                              <Link
                                to={`/productdetail/${
                                  item.product_id
                                    ? item.product_id
                                    : item.product
                                }`}
                              >
                                <img
                                  src={`${config.baseURL}${item.product_image1}`}
                                  alt={item.product_name}
                                  className={OrderDetailStyle.productImage}
                                />
                              </Link>
                            ) : (
                              <img
                                src={`${config.baseURL}${item.product_image1}`}
                                alt={item.product_name}
                                className={OrderDetailStyle.productImage}
                              />
                            )}

                            {/**End of code addition by Unnati on 30-09-2024
                             *Reason-To add link in image section */}
                          </div>

                          <div className={OrderDetailStyle.productInfo}>
                            {/**Code added by Unnati on 30-09-2024
                             *Reason-To add link in product name */}

                            <h3 className={OrderDetailStyle.productTitle}>
                              {item.is_active ? (
                                <Link
                                  to={`/productdetail/${
                                    item.product_id
                                      ? item.product_id
                                      : item.product
                                  }`}
                                  className={OrderDetailStyle.productTitle}
                                >
                                  {item.product_name}
                                  {/* Addition by Om Shrivastava on 26-11-2024
                                            Reason : Add text of customized product  */}
                                  {item.logo ||
                                    item.patches ||
                                    item.security_batches ||
                                    item.embroider ? (
                                    <div
                                      style={{
                                        color: "#008000",
                                        fontSize: "10px",
                                      }}
                                    >
                                      Customized product
                                    </div>
                                  ) : null}
                                  {/* End of addition by Om Shrivastava on 26-11-2024
                                            Reason : Add text of customized product  */}
                                </Link>
                              ) : (
                                item.product_name
                              )}
                            </h3>

                            {/**End of code addition by Unnati on 30-09-2024
                             *Reason-To add link in product name */}
                            {/**Code added by Unnati on 30-09-2024
                             *Reason-To add link in product description */}

                            {/**End of code addition by Unnati on 30-09-2024
                             *Reason-To add link in product description */}
                            <p className={OrderDetailStyle.productSize}>
                              Size: {item.size}
                            </p>
                            <p className={OrderDetailStyle.productSize}>
                              Quantity: {item.quantity}
                            </p>
                            {/**Code added by Unnati on 08-11-2024
                             *Reason-Added sales rate*/}
                            {item.item_status == "Cancelled" ? (
                              <p className={OrderDetailStyle.productSize}>
                                {/* -${item.sales_rate} */}
                                {/* Modification and addition by Om Shrivastava on 26-11-2024
                    Reason : Show the customization amount also  */}
                                {/* Modification and addition by Om Shrivastava on 04-12-2024
                      Reason : Show the amount  */}
                                {/* {item.sales_rate} */}
                                {/* {item.logo ||
                                    item.patches ||
                                    item.security_batches ||
                                    item.embroider ? (
                                  <>
                                    -${item.after_customization_product_price}
                                  </>
                                ) : (
                                  <>-${item.sales_rate}</>
                                )} */}
                                -${item.after_customization_product_price}
                                {/* End of modification and addition by Om Shrivastava on 04-12-2024
                      Reason : Show the amount  */}
                      
                                {/* End of modification and addition by Om Shrivastava on 26-11-2024
                    Reason : Show the customization amount also  */}
                              </p>
                            ) : (
                              <p className={OrderDetailStyle.productSize}>
                                {/* ${item.sales_rate} */}
                                {/* Modification and addition by Om Shrivastava on 04-12-2024
                      Reason : Show the amount  */}
                                {/* Modification and addition by Om Shrivastava on 26-11-2024
                                Reason : Show the customization amount also  */}
                                {/* {item.sales_rate} */}
                                {/* {item.logo ||
                                    item.patches ||
                                    item.security_batches ||
                                    item.embroider ? (
                                  <>${item.after_customization_product_price}</>
                                ) : (
                                  <>${item.sales_rate}</>
                                )} */}
                                ${item.after_customization_product_price}
                                {/* Emd of modification and addition by Om Shrivastava on 04-12-2024
                                  Reason : Show the amount  */}

                                {/* End of modification and addition by Om Shrivastava on 26-11-2024
                                Reason : Show the customization amount also  */}
                              </p>
                            )}
                            {/**End of code addition by Unnati on 08-11-2024
                             *Reason-Added sales rate*/}
                            {/**Code added by Unnati on 30-09-2024
                             *Reason-To display item status */}
                            {/* Commented by jhamman on 24-10-2024
                       Reason - Temporily removed this for now*/}
                            {/* <p className={OrderDetailStyle.productSize}>
                        Item Status: {item.item_status}
                      </p> */}
                            {/* End of commentation by jhamman on 24-10-2024
                       Reason - Temporily removed this for noe*/}
                            {/**Code added by Unnati on 26-10-2024
                             *Reason-Added classname */}
                            <p className={OrderDetailStyle.notAvailableMessage}>
                              {item.is_active
                                ? ""
                                : "This product is not available"}
                            </p>
                            {/**Code added by Unnati on 11-11-2024
                             *Reason-Added message for cancellation by Admin */}
                            {item.cancelled_by == "Admin" ? (
                              <p className={OrderDetailStyle.cancelled}>
                                Order cancelled by Admin
                              </p>
                            ) : null}
                            {/**End of code addition by Unnati on 11-11-2024
                             *Reason-Added message for cancellation by Admin */}
                            {/**Code added by Unnati on 06-11-2024
                             *Reason-Added cancel button */}
                            {/**Code added by Unnati on 11-11-2024
                             *Reason-Added message for cancellation by Admin */}
                            {item.cancelled_by !== "Admin" &&
                            item.item_status === "Cancelled" ? (
                              <p className={OrderDetailStyle.cancelled}>
                                Cancelled
                              </p>
                            ) : null}
                            {/**End of code addition by Unnati on 11-11-2024
                             *Reason-Added message for cancellation by Admin */}
                            {item.product_is_cancellable &&
                            item.item_status === "Cancelled" ? (
                              <>
                                {/*Code commented by Unnati on 11-11-2024
                        *Reason-This code is not in use}
                          {/* <p className={OrderDetailStyle.cancelled}>
                            Cancelled
                          </p> */}
                                {/*End of code comment by Unnati on 11-11-2024
                                 *Reason-This code is not in use*/}

                                {item.cancel_reason && (
                                  <p
                                    className={
                                      OrderDetailStyle.cancellationReason
                                    }
                                  >
                                    Reason: {item.cancel_reason}
                                  </p>
                                )}
                              </>
                            ) : null}

                            {/* Added by - Ashish Dewangan on 25-11-2024
                             * Reason - TO show shipped and delivered status */}
                            {"Shipped" == item.item_status ? (
                              <p className={OrderDetailStyle.greenStatus}>
                                Shipped
                              </p>
                            ) : null}

                            {"Delivered" == item.item_status ? (
                              <p className={OrderDetailStyle.greenStatus}>
                                Delivered
                              </p>
                            ) : null}
                            {/* End of addition by - Ashish Dewangan on 25-11-2024
                             * Reason - TO show shipped and delivered status */}

                            {/* Added by - Ashish Dewangan on 24-11-2024
                             * Reason - To show return status and reason */}
                            {item.returned_by == "Admin" &&
                            [
                              "Return Initiated",
                              "Return Approved",
                              "Return Rejected",
                              "Parcel Pickup Initiated for Return",
                              "Parcel Picked Up for Return",
                              "Returned",
                            ].includes(item.item_status) ? (
                              <p className={OrderDetailStyle.cancelled}>
                                Item Return Initiated by Admin
                              </p>
                            ) : null}

                            {item.returned_by != "None" &&
                              [
                                "Return Initiated",
                                "Return Approved",
                                "Return Rejected",
                                "Parcel Pickup Initiated for Return",
                                "Parcel Picked Up for Return",
                                "Returned",
                              ].includes(item.item_status) && (
                                <p className={OrderDetailStyle.orangeStatus}>
                                  {item.item_status}
                                </p>
                              )}

                            {item.returned_by != "None" &&
                            [
                              "Parcel Pickup Initiated for Return",
                              "Parcel Picked Up for Return",
                            ].includes(item.item_status) &&
                            item.return_pickup_date != null ? (
                              <>
                                <p
                                  className={
                                    OrderDetailStyle.cancellationReason
                                  }
                                >
                                  Pickup date :{" "}
                                  {formatDateToMMDDYYYY(
                                    item.return_pickup_date
                                  )}
                                </p>
                              </>
                            ) : null}

                            {item.returned_by != "None" &&
                            [
                              "Return Initiated",
                              "Return Approved",
                              "Return Rejected",
                              "Parcel Pickup Initiated for Return",
                              "Parcel Picked Up for Return",
                              "Returned",
                            ].includes(item.item_status) &&
                            item.return_reason != null &&
                            item.return_reason?.trim()?.length > 0 ? (
                              <>
                                <p
                                  className={
                                    OrderDetailStyle.cancellationReason
                                  }
                                >
                                  Reason for Return : {item.return_reason}
                                </p>
                              </>
                            ) : null}

                            {item.returned_by != "None" &&
                            [
                              "Return Initiated",
                              "Return Approved",
                              "Return Rejected",
                              "Parcel Pickup Initiated for Return",
                              "Parcel Picked Up for Return",
                              "Returned",
                            ].includes(item.item_status) &&
                            item.return_approval_reason != null &&
                            item.return_approval_reason?.trim()?.length > 0 ? (
                              <>
                                <p
                                  className={
                                    OrderDetailStyle.cancellationReason
                                  }
                                >
                                  Return Approval Reason :{" "}
                                  {item.return_approval_reason}
                                </p>
                              </>
                            ) : null}

                            {item.returned_by != "None" &&
                            item.return_rejection_reason != null &&
                            item.return_rejection_reason?.trim()?.length > 0 ? (
                              <>
                                <p
                                  className={
                                    OrderDetailStyle.cancellationReason
                                  }
                                >
                                  Return Rejection Reason :{" "}
                                  {item.return_rejection_reason}
                                </p>
                              </>
                            ) : null}

                            {item.returned_by != "None" &&
                            [
                              "Return Initiated",
                              "Return Approved",
                              "Return Rejected",
                              "Parcel Pickup Initiated for Return",
                              "Parcel Picked Up for Return",
                              "Returned",
                            ].includes(item.item_status) &&
                            item.return_authorization_no != null &&
                            item.return_authorization_no?.trim()?.length > 0 ? (
                              <>
                                <p
                                  className={
                                    OrderDetailStyle.cancellationReason
                                  }
                                >
                                  Return Authorization Number :{" "}
                                  {item.return_authorization_no}
                                </p>
                              </>
                            ) : null}

                            {/* End of addition by - Ashish Dewangan on 24-11-2024
                             * Reason - To show return status and reason */}

                            {/* Added by - Ashish Dewangan on 24-11-2024
                             * Reason - To show refund status and transaction id */}

                            {["Refund Initiated", "Refunded"].includes(
                              item.item_status
                            ) && (
                              <p className={OrderDetailStyle.greenStatus}>
                                {item.item_status}
                              </p>
                            )}
                            {/**Code added by Unnati on 02-12-2024
                            *Reason-To show message if item status is refund initiated */}
                            {item.item_status == "Refund Initiated"?
                               <p className={
                                    OrderDetailStyle.cancellationReason
                                  }
                                  style={{letterSpacing:".3px",fontWeight:500}}>
                                  Amount will be refunded to account in between 7-10 business days 
                              </p>:null}
                            {/**End of code addition by Unnati on 02-12-2024
                            *Reason-To show message if item status is refund initiated */}
                            {item.item_status == "Refunded" &&
                            item.refund_transaction_id != null &&
                            item.refund_transaction_id?.trim()?.length > 0 ? (
                              <p
                                className={OrderDetailStyle.cancellationReason}
                              >
                                Return Transaction ID :{" "}
                                {item.refund_transaction_id}
                              </p>
                            ) : null}

                            {/* End of addition by - Ashish Dewangan on 24-11-2024
                             * Reason - To show refund status and reason and transaction id */}

                            {/* Added by - Ashish Dewangan on 25-11-2024
                             * Reason - To courier service provider name */}
                             {/* Modified by - Ashish Dewangan on 30-11-2024
                             * Reason - added more condition for displaying this field */}
                             {/* {item.courier_service_provider_name != null && */}
                            {item.item_status != "Returned" && item.courier_service_provider_name != null &&
                            /* End of modification by - Ashish Dewangan on 30-11-2024
                             * Reason - added more condition for displaying this field */
                            item.courier_service_provider_name?.trim()?.length >
                              0 ? (
                              <>
                                <p
                                  className={
                                    OrderDetailStyle.cancellationReason
                                  }
                                >
                                  Courier Service Provider Name :{" "}
                                  {item.courier_service_provider_name}
                                </p>
                              </>
                            ) : null}
                            {/* End of addition by - Ashish Dewangan on 25-11-2024
                             * Reason - To courier service provider name */}

                            {/* Added by - Ashish Dewangan on 25-11-2024
                             * Reason - To show tracking id */}
                             {/* {item.tracking_id != null && */}
                             {/* Modified by - Ashish Dewangan on 30-11-2024
                             * Reason - added more condition for displaying this field */}
                            {item.item_status != "Returned" && item.tracking_id != null &&
                            /* Modified by - Ashish Dewangan on 30-11-2024
                             * Reason - added more condition for displaying this field */
                            item.tracking_id?.trim()?.length > 0 ? (
                              <>
                                <p
                                  className={
                                    OrderDetailStyle.cancellationReason
                                  }
                                >
                                  Return Tracking ID : {item.tracking_id}
                                </p>
                              </>
                            ) : null}
                            {/* End of addition by - Ashish Dewangan on 25-11-2024
                             * Reason - To show tracking id */}
                            
                            {/* Added by - Unnati Bajaj on 29-11-2024
                             * Reason - To show exchange status and reason */}
                            

                            {
                              [
                                "Exchange Initiated",
                                "Exchange Approved",
                                "Exchange Rejected",
                                "Parcel Pickup Initiated for Exchange",
                                "Parcel Picked Up for Exchange",
                                "Exchanged",
                              ].includes(item.item_status) && (
                                <p className={OrderDetailStyle.orangeStatus}>
                                  {item.item_status}
                                </p>
                              )}

                            {
                            [
                              "Parcel Pickup Initiated for Exchange",
                              "Parcel Picked Up for Exchange",
                            ].includes(item.item_status) &&
                            item.exchange_pickup_date != null ? (
                              <>
                                <p
                                  className={
                                    OrderDetailStyle.cancellationReason
                                  }
                                >
                                  Pickup date :{" "}
                                  {formatDateToMMDDYYYY(
                                    item.exchange_pickup_date
                                  )}
                                </p>
                              </>
                            ) : null}

                            {
                            [
                              "Exchange Initiated",
                              "Exchange Approved",
                              "Exchange Rejected",
                              "Parcel Pickup Initiated for Exchange",
                              "Parcel Picked Up for Exchange",
                              "Exchanged",
                            ].includes(item.item_status) &&
                            item.exchange_reason != null &&
                            item.exchange_reason?.trim()?.length > 0 ? (
                              <>
                                <p
                                  className={
                                    OrderDetailStyle.cancellationReason
                                  }
                                >
                                  Reason for Exchange : {item.exchange_reason}
                                </p>
                              </>
                            ) : null}

                            {
                            [
                              "Exchange Initiated",
                              "Exchange Approved",
                              "Exchange Rejected",
                              "Parcel Pickup Initiated for Exchange",
                              "Parcel Picked Up for Exchange",
                              "Exchanged",
                            ].includes(item.item_status) &&
                            item.exchange_id != null &&
                            item.exchange_id?.trim()?.length > 0 ? (
                              <>
                                <p
                                  className={
                                    OrderDetailStyle.cancellationReason
                                  }
                                >
                                  Exchange ID :{" "}
                                  {item.exchange_id}
                                </p>
                              </>
                            ) : null}
                           
                            {/* End of addition by - Ashish Dewangan on 24-11-2024
                             * Reason - To show return status and reason */}
                             {item.product_is_exchangeable &&
  item.item_status === "Delivered" &&
  item.delivery_date !== null &&
  elapsedDate <= max_exchange_days &&
  !(item.logo || item.patches || item.security_batches || item.embroider) ? (
    <button
      className={OrderDetailStyle.cancelButton}
      onClick={() => handleExchangeModal(item.id)}
    >
      Exchange
    </button>
  ) : null}

                            <div className={OrderDetailStyle.buttons}>
                              {item.product_is_cancellable &&
                              /**
                               * Modified by - Ashish Dewangan on 24-11-2024
                               * Reason - To show cancel button only when status is pending or shipped
                               */
                              // item.item_status !== "Cancelled" &&
                              (item.item_status == "Pending" ||
                                item.item_status == "Shipped") &&
                              /**
                               * End of modification by - Ashish Dewangan on 24-11-2024
                               * Reason - To show cancel button only when status is pending or shipped
                               */

                              /**Code added by Unnati on 11-11-2024
                               *Reason-Added condition
                               */ item.cancelled_by == "None" ? (
                                /*End of code addition by Unnati on 11-11-2024
                                 *Reason-Added condition
                                 */ <button
                                  className={OrderDetailStyle.cancelButton}
                                  /**Code added by Unnati on 11-11-2024
                                   *Reason-Added handle cancel modal */
                                  onClick={() => handleCancelModal(item.id)}
                                  /**End of code addition by Unnati on 11-11-2024
                                   *Reason-Added handle cancel modal */
                                >
                                  Cancel
                                </button>
                              ) : null}

                              {/**End of code addition by Unnati on 06-11-2024
                               *Reason-Added cancel button */}
                              {/**End of code addition by Unnati on 26-10-2024
                               *Reason-Added classname */}
                              {/**End of code addition by Unnati on 30-09-2024
                               *Reason-To display item status */}
                              {/*Code added by Unnati on 16-11-2024
                               *Reason-Added return button */}
                              {
                                /**
                                 * Modified by - Ashish Dewangan on 24-11-2024
                                 * Reason - To show return button only if status is delivered,
                                 * item is returnable and 30 days are not passed since the delivery
                                 */
                                // item.product_is_returnable &&
                                // ![
                                //   "Return Initiated",
                                //   "Cancelled",
                                //   "Returned",
                                //   "Refunded",
                                // ].includes(item.item_status)
                                item.product_is_returnable &&
                                item.item_status == "Delivered" &&
                                /**
                                 * End of modification by - Ashish Dewangan on 24-11-2024
                                 * Reason - To show return button only if status is delivered,
                                 * item is returnable and 30 days are not passed since the delivery
                                 */
                                /**
                                 * Added by - Ashish Dewangan on 24-11-2024
                                 * Reason - Added extra condition to check if return time is elapsed or not
                                 */
                                item.delivery_date != null &&
                                elapsedDate <= max_return_days ? (
                                  /**
                                   * End of addition by - Ashish Dewangan on 24-11-2024
                                   * Reason - Added extra condition to check if return time is elapsed or not
                                   */
                                  
                                  /**
                                   * Modified by - Ashish Dewangan on 29-11-2024
                                   * Reason - To show return note
                                   */
                                  // <button
                                  // style={{margin:"5px 5px 5px 0px"}}
                                  //   className={OrderDetailStyle.cancelButton}
                                  //   onClick={() => handleReturnModal(item.id)}
                                  // >
                                  //   Return
                                  // </button>

                                  <div>
                                  <button
                                  style={{margin:"5px 5px 5px 0px"}}
                                    className={OrderDetailStyle.cancelButton}
                                    onClick={() => handleReturnModal(item.id)}
                                  >
                                    Return
                                  </button>
                                  <p className={
                                    OrderDetailStyle.cancellationReason
                                  }
                                  style={{letterSpacing:".3px",fontWeight:500}}>Return conditions - 
                                  To be eligible for a return, the item must be in its original condition, with tags and packaging intact. The item should not have been used and must be returned within {max_return_days? max_return_days:30} days after the delivery.</p>
                                  
                                  </div>
                                  /**
                                   * End of modification by - Ashish Dewangan on 29-11-2024
                                   * Reason - To show return note
                                   */
                                ) : null
                              }
                              {/**End of code comment by Unnati on 16-11-2024
                               *Reason-Added return button */}
                               
                            </div>
                            

                            {/* Added by - Ashish Dewangan on 25-11-2024
                             * Reason - To user inputbox for entering tracking id
                             * if return reason is not defective */}
                            <div className={OrderDetailStyle.buttons}>
                              {item.item_status == "Return Approved" &&
                              item.return_reason != "Defective" ? (
                                <form>
                                  <div style={{ display: "flex" }}>
                                    <div
                                      style={{ margin: "10px 10px 10px 0px" }}
                                    >
                                      <div
                                        className={
                                          OrderDetailStyle.cancellationReason
                                        }
                                        style={{
                                          marginBottom: "3px",
                                          fontWeight: 600,
                                        }}
                                      >
                                        Please add the tracking details after
                                        you courier the parcel
                                      </div>
                                      <div style={{ display: "flex" }}>
                                        <div>
                                          <input
                                            type="text"
                                            name="courier_name"
                                            placeholder="Courier Service Provider Name"
                                            value={returnCourierProviderName}
                                            onChange={(e) =>
                                              setReturnCourierProviderName(
                                                e.target.value
                                              )
                                            }
                                            style={{ marginBottom: "3px" }}
                                            className={
                                              OrderDetailStyle.textInputBox
                                            }
                                          />
                                          {returnCourierProviderNameError.length >
                                            0 && (
                                            <div
                                              className={
                                                OrderDetailStyle.cancellationReason
                                              }
                                              style={{
                                                color: "red",
                                                marginBottom: "3px",
                                              }}
                                            >
                                              {returnCourierProviderNameError}
                                            </div>
                                          )}
                                        </div>
                                        <div>
                                          <input
                                            type="text"
                                            name="tracking_id"
                                            placeholder="Tracking ID"
                                            value={returnTrackingId}
                                            onChange={(e) =>
                                              setReturnTrackingId(
                                                e.target.value
                                              )
                                            }
                                            style={{ marginBottom: "3px" }}
                                            className={
                                              OrderDetailStyle.textInputBox
                                            }
                                          />
                                          {returnTrackingIdError.length > 0 && (
                                            <div
                                              className={
                                                OrderDetailStyle.cancellationReason
                                              }
                                              style={{
                                                color: "red",
                                                marginBottom: "3px",
                                              }}
                                            >
                                              {returnTrackingIdError}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div>
                                        <button
                                          className={
                                            OrderDetailStyle.cancelButton
                                          }
                                          onClick={(e) =>
                                            submitReturnTrackingId(
                                              e,
                                              item.id,
                                              item.order
                                            )
                                          }
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              ) : null}
                            </div>
                            {/* End of addition by - Ashish Dewangan on 25-11-2024
                             * Reason - To user inputbox for entering tracking id
                             * if return reason is not defective */}
                            {/* Added by - Unnati on 29-11-2024
                             * Reason - To user inputbox for entering tracking id
                             * if return reason is not defective */}
                             <div className={OrderDetailStyle.buttons}>
                              {item.item_status == "Exchange Approved" &&
                              item.return_reason != "Defective or Damaged Item" ? (
                                <form>
                                  <div style={{ display: "flex" }}>
                                    <div
                                      style={{ margin: "10px 10px 10px 0px" }}
                                    >
                                      <div
                                        className={
                                          OrderDetailStyle.cancellationReason
                                        }
                                        style={{
                                          marginBottom: "3px",
                                          fontWeight: 600,
                                        }}
                                      >
                                        Please add the tracking details after
                                        you courier the parcel
                                      </div>
                                      <div style={{ display: "flex" }}>
                                        <div>
                                          <input
                                            type="text"
                                            name="courier_name"
                                            placeholder="Courier Service Provider Name"
                                            value={exchangeCourierProviderName}
                                            onChange={(e) =>
                                              setExchangeCourierProviderName(
                                                e.target.value
                                              )
                                            }
                                            style={{ marginBottom: "3px" }}
                                            className={
                                              OrderDetailStyle.textInputBox
                                            }
                                          />
                                          {exchangeCourierProviderNameError.length >
                                            0 && (
                                            <div
                                              className={
                                                OrderDetailStyle.cancellationReason
                                              }
                                              style={{
                                                color: "red",
                                                marginBottom: "3px",
                                              }}
                                            >
                                              {exchangeCourierProviderNameError}
                                            </div>
                                          )}
                                        </div>
                                        <div>
                                          <input
                                            type="text"
                                            name="tracking_id"
                                            placeholder="Tracking ID"
                                            value={exchangeTrackingId}
                                            onChange={(e) =>
                                              setExchangeTrackingId(
                                                e.target.value
                                              )
                                            }
                                            style={{ marginBottom: "3px" }}
                                            className={
                                              OrderDetailStyle.textInputBox
                                            }
                                          />
                                          {exchangeTrackingIdError.length > 0 && (
                                            <div
                                              className={
                                                OrderDetailStyle.cancellationReason
                                              }
                                              style={{
                                                color: "red",
                                                marginBottom: "3px",
                                              }}
                                            >
                                              {exchangeTrackingIdError}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div>
                                        <button
                                          className={
                                            OrderDetailStyle.cancelButton
                                          }
                                          onClick={(e) =>
                                            submitExchangeTrackingId(
                                              e,
                                              item.id,
                                              item.order
                                            )
                                          }
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              ) : null}
                            </div>
                            {/* End of addition by - Ashish Dewangan on 25-11-2024
                             * Reason - To user inputbox for entering tracking id
                             * if return reason is not defective */}
                            {/**Code added by Unnati on 11-11-2024
                             *Reason-To check for item id */}
                            {/**Code modified by Unnati on 18-11-2024
                             *Reason-Added modification */}
                            {showCancelModal && itemToCancel === item.id && (
                              <>
                                <div
                                  className={OrderDetailStyle.modalOverlay}
                                  onClick={() => setShowCancelModal(false)}
                                />
                                <div className={OrderDetailStyle.cancelModal}>
                                  <form
                                    onSubmit={(e) =>
                                      handleCancelButton(e, item.id, item.order)
                                    }
                                  >
                                    <h3 className={OrderDetailStyle.modalTitle}>
                                      Cancel Order
                                    </h3>

                                    <div
                                      className={OrderDetailStyle.reasonList}
                                    >
                                      <label
                                        className={OrderDetailStyle.reason}
                                      >
                                        <input
                                          type="radio"
                                          value="Wrong Product Ordered"
                                          checked={
                                            selectedReason ===
                                            "Wrong Product Ordered"
                                          }
                                          onChange={(e) =>
                                            setSelectedReason(e.target.value)
                                          }
                                        />
                                        Wrong Product Ordered
                                      </label>

                                      <label
                                        className={OrderDetailStyle.reason}
                                      >
                                        <input
                                          type="radio"
                                          value="Found Cheaper Elsewhere"
                                          checked={
                                            selectedReason ===
                                            "Found Cheaper Elsewhere"
                                          }
                                          onChange={(e) =>
                                            setSelectedReason(e.target.value)
                                          }
                                        />
                                        Found Cheaper Elsewhere
                                      </label>

                                      <label
                                        className={OrderDetailStyle.reason}
                                      >
                                        <input
                                          type="radio"
                                          value="Change of Mind"
                                          checked={
                                            selectedReason === "Change of Mind"
                                          }
                                          onChange={(e) =>
                                            setSelectedReason(e.target.value)
                                          }
                                        />
                                        Change of Mind
                                      </label>

                                      <label
                                        className={OrderDetailStyle.reason}
                                      >
                                        <input
                                          type="radio"
                                          value="Long delivery time"
                                          checked={
                                            selectedReason ===
                                            "Long delivery time"
                                          }
                                          onChange={(e) =>
                                            setSelectedReason(e.target.value)
                                          }
                                        />
                                        Long delivery time
                                      </label>

                                      <label
                                        className={OrderDetailStyle.reason}
                                      >
                                        <input
                                          type="radio"
                                          value="Other"
                                          checked={selectedReason === "Other"}
                                          onChange={(e) =>
                                            setSelectedReason(e.target.value)
                                          }
                                        />
                                        Other
                                      </label>
                                    </div>
                                    {selectedReason === "Other" && (
                                      <textarea
                                        placeholder="Please specify your reason (optional)"
                                        value={otherReason}
                                        onChange={(e) =>
                                          setOtherReason(e.target.value)
                                        }
                                        className={OrderDetailStyle.textArea}
                                      />
                                    )}

                                    <div
                                      className={
                                        OrderDetailStyle.buttonContainer
                                      }
                                    >
                                      <button
                                        type="submit"
                                        className={
                                          OrderDetailStyle.submitButton
                                        }
                                      >
                                        Confirm Cancellation
                                      </button>
                                      <button
                                        type="button"
                                        className={
                                          OrderDetailStyle.cancelModalButton
                                        }
                                        onClick={() => {
                                          setShowCancelModal(false);
                                          setOtherReason("");
                                        }}
                                      >
                                        Close
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              </>
                            )}

                            {/*End of code addition by Unnati on 18-11-2024
                             *Reason-Added modification */}

                            {/* Added by - Ashish Dewangan on 23-11-2024
                             * Reason - To show return modal with return reason option */}
                            {showReturnModal && itemToReturn === item.id && (
                              <>
                                <div
                                  className={OrderDetailStyle.modalOverlay}
                                  onClick={() => setShowReturnModal(false)}
                                />
                                <div className={OrderDetailStyle.cancelModal}>
                                  <form
                                    onSubmit={(e) =>
                                      handleReturnButton(e, item.id, item.order)
                                    }
                                  >
                                    <h3 className={OrderDetailStyle.modalTitle}>
                                      Return Order
                                    </h3>

                                    <div
                                      className={OrderDetailStyle.reasonList}
                                    >
                                      <label
                                        className={OrderDetailStyle.reason}
                                      >
                                        <input
                                          type="radio"
                                          // value="Item arrived damaged"
                                          value="Defective"
                                          checked={
                                            // selectedReturnReason ===
                                            // "Item arrived damaged"
                                            selectedReturnReason === "Defective"
                                          }
                                          onChange={(e) =>
                                            setSelectedReturnReason(
                                              e.target.value
                                            )
                                          }
                                        />
                                        {/* Item arrived damaged */}
                                        Defective
                                      </label>

                                      <label
                                        className={OrderDetailStyle.reason}
                                      >
                                        <input
                                          type="radio"
                                          value="Item does not match the description"
                                          checked={
                                            selectedReturnReason ===
                                            "Item does not match the description"
                                          }
                                          onChange={(e) =>
                                            setSelectedReturnReason(
                                              e.target.value
                                            )
                                          }
                                        />
                                        Item does not match the description
                                      </label>

                                      <label
                                        className={OrderDetailStyle.reason}
                                      >
                                        <input
                                          type="radio"
                                          value="Wrong product received"
                                          checked={
                                            selectedReturnReason ===
                                            "Wrong product received"
                                          }
                                          onChange={(e) =>
                                            setSelectedReturnReason(
                                              e.target.value
                                            )
                                          }
                                        />
                                        Wrong product received
                                      </label>

                                      <label
                                        className={OrderDetailStyle.reason}
                                      >
                                        <input
                                          type="radio"
                                          value="No longer needed"
                                          checked={
                                            selectedReturnReason ===
                                            "No longer needed"
                                          }
                                          onChange={(e) =>
                                            setSelectedReturnReason(
                                              e.target.value
                                            )
                                          }
                                        />
                                        No longer needed
                                      </label>

                                      <label
                                        className={OrderDetailStyle.reason}
                                      >
                                        <input
                                          type="radio"
                                          value="Order arrived too late"
                                          checked={
                                            selectedReturnReason ===
                                            "Order arrived too late"
                                          }
                                          onChange={(e) =>
                                            setSelectedReturnReason(
                                              e.target.value
                                            )
                                          }
                                        />
                                        Order arrived too late
                                      </label>

                                      <label
                                        className={OrderDetailStyle.reason}
                                      >
                                        <input
                                          type="radio"
                                          value="Wardrobing"
                                          checked={
                                            selectedReturnReason ===
                                            "Wardrobing"
                                          }
                                          onChange={(e) =>
                                            setSelectedReturnReason(
                                              e.target.value
                                            )
                                          }
                                        />
                                        Wardrobing
                                      </label>

                                      <label
                                        className={OrderDetailStyle.reason}
                                      >
                                        <input
                                          type="radio"
                                          value="Changed Mind"
                                          checked={
                                            selectedReturnReason ===
                                            "Changed Mind"
                                          }
                                          onChange={(e) =>
                                            setSelectedReturnReason(
                                              e.target.value
                                            )
                                          }
                                        />
                                        Changed Mind
                                      </label>

                                      <label
                                        className={OrderDetailStyle.reason}
                                      >
                                        <input
                                          type="radio"
                                          value="Other"
                                          checked={
                                            selectedReturnReason === "Other"
                                          }
                                          onChange={(e) =>
                                            setSelectedReturnReason(
                                              e.target.value
                                            )
                                          }
                                        />
                                        Other
                                      </label>
                                    </div>
                                    {selectedReturnReason === "Other" && (
                                      <textarea
                                        placeholder="Please specify your reason (optional)"
                                        value={otherReturnReason}
                                        onChange={(e) =>
                                          setOtherReturnReason(e.target.value)
                                        }
                                        className={OrderDetailStyle.textArea}
                                      />
                                    )}
                                    {/* Added by - Ashish Dewangan on 24-11-2024
                                     * Reason - added input boxes to select images of the item that user is returning */}
                                    <div
                                      className={
                                        OrderDetailStyle.itemImagesContainer
                                      }
                                    >
                                      <label
                                        className={OrderDetailStyle.reason}
                                      >
                                        {" "}
                                        Item images (optional)
                                      </label>
                                      <input
                                        type="file"
                                        name="itemImage1"
                                        onChange={(e) =>
                                          setItemImage1(e.target.files[0])
                                        }
                                      ></input>
                                      <input
                                        type="file"
                                        name="itemImage2"
                                        onChange={(e) =>
                                          setItemImage2(e.target.files[0])
                                        }
                                      ></input>
                                    </div>
                                    {/* End of addition by - Ashish Dewangan on 24-11-2024
                                     * Reason - added input boxes to select images of the item that user is returning */}

                                    <div
                                      className={
                                        OrderDetailStyle.buttonContainer
                                      }
                                    >
                                      <button
                                        type="submit"
                                        className={
                                          OrderDetailStyle.submitButton
                                        }
                                      >
                                        Confirm Return
                                      </button>
                                      <button
                                        type="button"
                                        className={
                                          OrderDetailStyle.cancelModalButton
                                        }
                                        onClick={() => {
                                          setShowReturnModal(false);
                                          setOtherReturnReason("");
                                        }}
                                      >
                                        Close
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              </>
                            )}
                            {/* End of addition by - Ashish Dewangan on 23-11-2024
                             * Reason - To show return modal with return reason option */}

                            {/* Added by - Ashish Dewangan on 24-11-2024
                             * Reason - To show contact modal after return is requested */}
                            {showContactPopupAfterReturn && (
                              <>
                                <div
                                  className={OrderDetailStyle.modalOverlay}
                                  onClick={() =>
                                    setShowContactPopupAfterReturn(false)
                                  }
                                />
                                <div className={OrderDetailStyle.cancelModal}>
                                  <div>
                                    <h3 className={OrderDetailStyle.modalTitle}>
                                      Contact Us
                                    </h3>
                                    <div
                                      className={
                                        OrderDetailStyle.itemImagesContainer
                                      }
                                    >
                                      <div className={OrderDetailStyle.reason}>
                                        Please call on our contact number or
                                        email us to complete the return process.
                                        Note : It is mandatory to call to
                                        complete the return process.
                                      </div>
                                      <div>
                                        <span
                                          className={OrderDetailStyle.reason}
                                        >
                                          {settings.contact_number
                                            ? ` Contact Number : ${settings.contact_number}`
                                            : ""}{" "}
                                        </span>
                                        <span
                                          className={OrderDetailStyle.reason}
                                        >
                                          {settings.email
                                            ? ` Email : ${settings.email}`
                                            : ""}
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        OrderDetailStyle.buttonContainer
                                      }
                                    >
                                      <button
                                        type="button"
                                        className={
                                          OrderDetailStyle.cancelModalButton
                                        }
                                        onClick={() => {
                                          setShowContactPopupAfterReturn(false);
                                        }}
                                      >
                                        Close
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                            {/* End of addition by - Ashish Dewangan on 24-11-2024
                             * Reason - To show contact modal after return is requested */}
                             
                            {/* Added by - Unnati Bajaj on 29-11-2024
                             * Reason - To show contact modal after exchange is requested */}
                            {showContactPopupAfterExchange && (
                              <>
                                <div
                                  className={OrderDetailStyle.modalOverlay}
                                  onClick={() =>
                                    setShowContactPopupAfterExchange(false)
                                  }
                                />
                                <div className={OrderDetailStyle.cancelModal}>
                                  <div>
                                    <h3 className={OrderDetailStyle.modalTitle}>
                                      Contact Us
                                    </h3>
                                    <div
                                      className={
                                        OrderDetailStyle.itemImagesContainer
                                      }
                                    >
                                      <div className={OrderDetailStyle.reason}>
                                        Please call on our contact number or
                                        email us to complete the exchange process.
                                        Note : It is mandatory to call to
                                        complete the return process.
                                      </div>
                                      <div>
                                        <span
                                          className={OrderDetailStyle.reason}
                                        >
                                          {settings.contact_number
                                            ? ` Contact Number : ${settings.contact_number}`
                                            : ""}{" "}
                                        </span>
                                        <span
                                          className={OrderDetailStyle.reason}
                                        >
                                          {settings.email
                                            ? ` Email : ${settings.email}`
                                            : ""}
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        OrderDetailStyle.buttonContainer
                                      }
                                    >
                                      <button
                                        type="button"
                                        className={
                                          OrderDetailStyle.cancelModalButton
                                        }
                                        onClick={() => {
                                          setShowContactPopupAfterExchange(false);
                                        }}
                                      >
                                        Close
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                            {/* End of addition by - Unnati on 29-11-2024
                             * Reason - To show contact modal after exchange is requested */}
                             {/* Added by - Unnati Bajaj on 29-11-2024
                             * Reason - To show exchange modal with exchange reason option */}
                            {showExchangeModal && itemToExchange === item.id && (
                            
                              <>
                                <div
                                  className={OrderDetailStyle.modalOverlay}
                                  onClick={() => setShowExchangeModal(false)}
                                />
                                  
                                <div className={OrderDetailStyle.exchangeModal}>
                                  <form
                                    onSubmit={(e) =>
                                      handleExchangeButton(e, item.id, item.order,products)
                                    }
                                  >
                                    <h3 className={OrderDetailStyle.modalTitle}>
                                      Exchange Order
                                    </h3>

                                    <div
                                      className={OrderDetailStyle.reasonList}
                                    >
                                      <label
                                        className={OrderDetailStyle.reason}
                                      >
                                        <input
                                          type="radio"
                                         
                                          value="Wrong Size"
                                          checked={
                                            
                                            selectedExchangeReason === "Wrong Size"
                                          }
                                          onChange={(e) =>
                                            setSelectedExchangeReason(
                                              e.target.value
                                            )
                                          }
                                        />
                                       
                                        Wrong Size
                                      </label>

                                      <label
                                        className={OrderDetailStyle.reason}
                                      >
                                        <input
                                          type="radio"
                                          value="Color Mismatch"
                                          checked={
                                            selectedExchangeReason ===
                                            "Color Mismatch"
                                          }
                                          onChange={(e) =>
                                            setSelectedExchangeReason(
                                              e.target.value
                                            )
                                          }
                                        />
                                        Color Mismatch
                                      </label>

                                      <label
                                        className={OrderDetailStyle.reason}
                                      >
                                        <input
                                          type="radio"
                                          value="Defective or Damaged Item"
                                          checked={
                                            selectedExchangeReason ===
                                            "Defective or Damaged Item"
                                          }
                                          onChange={(e) =>
                                            setSelectedExchangeReason(
                                              e.target.value
                                            )
                                          }
                                        />
                                        Defective or Damaged Item
                                      </label>

                                      <label
                                        className={OrderDetailStyle.reason}
                                      >
                                        <input
                                          type="radio"
                                          value="Change of Mind"
                                          checked={
                                            selectedExchangeReason ===
                                            "Change of Mind"
                                          }
                                          onChange={(e) =>
                                            setSelectedExchangeReason(
                                              e.target.value
                                            )
                                          }
                                        />
                                        Change of Mind
                                      </label>

                                      <label
                                        className={OrderDetailStyle.reason}
                                      >
                                        <input
                                          type="radio"
                                          value="Fit or Comfort Issues"
                                          checked={
                                            selectedExchangeReason ===
                                            "Fit or Comfort Issues"
                                          }
                                          onChange={(e) =>
                                            setSelectedExchangeReason(
                                              e.target.value
                                            )
                                          }
                                        />
                                       Fit or Comfort Issues
                                      </label>

                                      <label
                                        className={OrderDetailStyle.reason}
                                      >
                                        <input
                                          type="radio"
                                          value="Outfit Doesn’t Match Other Items"
                                          checked={
                                            selectedExchangeReason ===
                                            "Outfit Doesn’t Match Other Items"
                                          }
                                          onChange={(e) =>
                                            setSelectedExchangeReason(
                                              e.target.value
                                            )
                                          }
                                        />
                                        Outfit Doesn’t Match Other Items
                                      </label>

                                      <label
                                        className={OrderDetailStyle.reason}
                                      >
                                        <input
                                          type="radio"
                                          value="Seasonal Change"
                                          checked={
                                            selectedExchangeReason ===
                                            "Seasonal Change"
                                          }
                                          onChange={(e) =>
                                            setSelectedExchangeReason(
                                              e.target.value
                                            )
                                          }
                                        />
                                        Seasonal Change
                                      </label>

                                      <label
                                        className={OrderDetailStyle.reason}
                                      >
                                        <input
                                          type="radio"
                                          value="Other"
                                          checked={
                                            selectedExchangeReason === "Other"
                                          }
                                          onChange={(e) =>
                                            setSelectedExchangeReason(
                                              e.target.value
                                            )
                                          }
                                        />
                                        Other
                                      </label>
                                    </div>
                                    {selectedExchangeReason === "Other" && (
                                      <textarea
                                        placeholder="Please specify your reason (optional)"
                                        value={otherExchangeReason}
                                        onChange={(e) =>
                                          setOtherExchangeReason(e.target.value)
                                        }
                                        className={OrderDetailStyle.textArea}
                                      />
                                    )}
                                    {/* Added by - Ashish Dewangan on 24-11-2024
                                     * Reason - added input boxes to select images of the item that user is returning */}
                                    <div
                                      className={
                                        OrderDetailStyle.itemImagesContainer
                                      }
                                    >
                                    
                                      <label
                                        className={OrderDetailStyle.reason}
                                      >
                                        {" "}
                                        Item images (optional)
                                      </label>
                                      <input
                                        type="file"
                                        name="itemImage1"
                                        onChange={(e) =>
                                          setItemImage1(e.target.files[0])
                                        }
                                      ></input>
                                      <input
                                        type="file"
                                        name="itemImage2"
                                        onChange={(e) =>
                                          setItemImage2(e.target.files[0])
                                        }
                                      ></input>
                                    </div>
                                    {/* End of addition by - Ashish Dewangan on 24-11-2024
                                     * Reason - added input boxes to select images of the item that user is returning */}
                                     {/**Code added by Unnati on 28-08-2024
                 * Reason-Changed the UI for size display
                 */}
                 

                <div className={OrderDetailStyle.sizeBoxes}>
                  {["XS", "S", "M", "L", "XL", "XXL", "XXXL"].map((size) => (
                    <div
                      key={size}
                      className={`${OrderDetailStyle.sizeBox} ${
                        selectedSize === size
                          ? OrderDetailStyle.selectedSizeBox
                          : ""
                      } ${
                        !isSizeAvailable(size)
                          ? OrderDetailStyle.unavailableSizeBox
                          : ""
                      }`}
                      onClick={() =>
                        isSizeAvailable(size) && handleSelectChange(size)
                      }
                    >
                      {size}
                    </div>
                  ))}
                </div>
                {/**End of code addition by Unnati on 28-08-2024
                 * Reason-Changed the UI for size display
                 */}
                 <div className={OrderDetailStyle.productColors}>
                {/**Code added by Unnati on 23-05-2024
                 *Reason-To map product to according to its color*/}
                {uniqueColors.map((color) => (
                  <div
                    key={color}
                    className={`${OrderDetailStyle.colorOption} ${
                      selectedColor === color
                        ? OrderDetailStyle.activeColorOption
                        : ""
                    }`}
                    style={{ backgroundColor: color }}
                    onClick={() => handleColorSelect(color)}
                  ></div>
                ))}
                {/**End of code addition by Unnati on 23-05-2024
                 *Reason-To map product to according to its color*/}
              </div>
                              <div className={OrderDetailStyle.enterQuantity}>
                                <div className={OrderDetailStyle.text}>Enter Quantity needs to be exchanged:</div>
                                  <input
                                    type="number"
                                    min="1"
                                    max={Math.max(item.quantity)}
                                    value={quantity} 
                                    onChange={(e) => setQuantity(e.target.value)} 
                                    className={OrderDetailStyle.quantityInput}
                                    placeholder="Enter quantity"
                                  />
                              </div>
                                 {/* Added by - Ashlekh on 03-12-2024
                                  Reason - To display customization options */}
                                  {selectedProduct?.show_patches_and_embroider_on_UI?
                                  <h4
                                    className={`${OrderDetailStyle.popupContent}`}
                                  >
                                    Customization Options
                                  </h4>
                                  :null}
                                  <div className={OrderDetailStyle.checkboxGroup}>
                                    {selectedProduct?.logo_price ? (
                                      <label  className={OrderDetailStyle.reason}>
                                        <input
                                          type="checkbox"
                                          name="logo"
                                          checked={formData.logo}
                                          onChange={handleChange}
                                          
                                          
                                        />{" "}
                                        Logo + ${selectedProduct?.logo_price}
                                      </label>
                                    ) : null}
                                    {selectedProduct?.patches_price ? (
                                      <label  className={OrderDetailStyle.reason}>
                                        <input
                                          type="checkbox"
                                          name="patches"
                                          checked={formData.patches}
                                          onChange={handleChange}
                                        />{" "}
                                        Patches / Batches + $
                                        {selectedProduct?.patches_price}
                                      </label>
                                    ) : null}
                                    {selectedProduct?.security_batches_price ? (
                                      <label  className={OrderDetailStyle.reason}>
                                        <input
                                          type="checkbox"
                                          name="security_batches"
                                          checked={formData.security_batches}
                                          onChange={handleChange}
                                        />{" "}
                                        Security id + $
                                        {
                                          selectedProduct?.security_batches_price
                                        }
                                      </label>
                                    ) : null}
                                    {selectedProduct?.embroider_price ? (
                                      <label  className={OrderDetailStyle.reason}>
                                        <input
                                          type="checkbox"
                                          name="embroider"
                                          checked={formData.embroider}
                                          onChange={handleChange}
                                        />{" "}
                                        Embroider / Name + $
                                        {selectedProduct?.embroider_price}
                                      </label>
                                    ) : null}
                                  </div>
                                  {validationMessage && (
                                    <div
                                      style={{
                                        color: "red",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      {validationMessage}
                                    </div>
                                  )}
                                  {selectedProduct.show_patches_and_embroider_on_UI?
                                  <div className={OrderDetailStyle.commentBox}>
                                    <textarea
                                      name="customization_comment"
                                      placeholder="Comment"
                                      className={OrderDetailStyle.commentInput}
                                      value={formData.customization_comment}
                                      onChange={handleChange}
                                    />
                                  </div>
                                  :null}
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {/* <button
                                      type="button"
                                      onClick={handleCustomizationSubmit}
                                      className={OrderDetailStyle.submitButton}
                                    >
                                      Submit
                                    </button> */}
                                  </div>
                                  {/* End of code - Ashlekh on 03-12-2024
                                    Reason - To display customization options */}
                                    <div
                                      className={
                                        OrderDetailStyle.buttonContainer
                                      }
                                    >
                                      <button
                                        type="submit"
                                        className={
                                          OrderDetailStyle.submitButton
                                        }
                                      >
                                        Confirm Exchange
                                      </button>
                                      <button
                                        type="button"
                                        className={
                                          OrderDetailStyle.cancelModalButton
                                        }
                                        onClick={() => {
                                          setShowExchangeModal(false);
                                          setOtherExchangeReason("");
                                          setQuantity(1);
                                        }}
                                      >
                                        Close
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              </>
                            )}
                            {/* End of addition by - Ashish Dewangan on 23-11-2024
                             * Reason - To show return modal with return reason option */}
                          </div>
                        </div>

                        {/* <div className={OrderDetailStyle.actionSection}>
                      <p className={OrderDetailStyle.exchangeInfo}>&#8226; Exchange/Return closed</p>
                      </div> */}
                      </div>
                    );
                  })}
                  {/* End of code addition by Unnati on 18-09-2024
                   *Reason-To map Order Details */}
                </div>

                {order && (
                  <div className={OrderDetailStyle.orderSummary}>
                    <h3 className={OrderDetailStyle.summaryTitle}>
                      Order Summary
                    </h3>
                    <p className={OrderDetailStyle.summaryItem}>
                      <span className={OrderDetailStyle.summaryLabel}>
                        Subtotal:
                      </span>{" "}
                      ${order.subtotal}
                    </p>
                    {order.discount_amount > 0 && (
                      <p className={OrderDetailStyle.summaryItem}>
                        <span className={OrderDetailStyle.summaryLabel}>
                          Discount Amount:
                        </span>{" "}
                        ${parseFloat(order.discount_amount || 0).toFixed(2)}
                      </p>
                    )}
                    {/* <p className={OrderDetailStyle.summaryItem}>
                  <span className={OrderDetailStyle.summaryLabel}>
                    Taxable Amount:
                  </span>{" "}
                  ${order.taxable_amount || "0.00"}
                </p> */}
                    <p className={OrderDetailStyle.summaryItem}>
                      <span className={OrderDetailStyle.summaryLabel}>
                        Tax(%):
                      </span>
                      {/**Code modified by Unnati on 25-11-2024
                       *Reason-Modified sales tax */}
                      {/* 5% */}
                      {Constants.sales_tax}%
                      {/**End of code addition by Unnati on 25-11-2024
                       *Reason-Modified sales tax */}
                    </p>
                    <p className={OrderDetailStyle.summaryItem}>
                      <span className={OrderDetailStyle.summaryLabel}>
                        Tax:
                      </span>{" "}
                      ${order.tax_amount}
                    </p>
                    {/* <p className={OrderDetailStyle.summaryItem}>
                  <span className={OrderDetailStyle.summaryLabel}>
                    Shipping Amount:
                  </span>{" "}
                  {order.shipping_amount || "0.00"}
                </p> */}
                    <p className={OrderDetailStyle.summaryTotal}>
                      <span className={OrderDetailStyle.totalLabel}>
                        Grand Total:
                      </span>{" "}
                      {/* $
                  {((total - Number(order.discount_amount)) * 1.05).toFixed(
                    2
                  ) || "0.00"} */}
                      {/* Modified by Jhamman on 11-10-2024
                  Reason - Added $ sign */}
                      {/* {order.grand_total} */}${order.grand_total}
                      {/* Modified by Jhamman on 11-10-2024
                  Reason - Added $ sign */}
                    </p>
                  </div>
                )}
                {/* Code changed by - Ashlekh on 23-10-2024
            Reason - To display invoice button when payment is completed */}

                {/* Commented by jhamman on 23-10-2024
            Reason - we are sending order id with url in below */}
                {/* {orderStatus?.payment_status == "Completed" && (
              <Link to="/invoice" state={{ Order }}>
                <button className={OrderDetailStyle.invoiceButton}>
                  View Invoice
                </button>
              </Link>
            )} */}
                {/* End of commentation by jhamman on 23-10-2024
            Reason - we are sending order id with url in below */}
                {/* End of code - Ashlekh on 23-10-2024
            Reason - To display invoice button when payment is completed */}
                {/* Modified by jhamman on 23-10-2024
            Reason - Changed path because we are calling api in invoice page */}
                {/* <Link to="/invoice" state={{ Order }}>
              <button className={OrderDetailStyle.invoiceButton}>
                View Invoice
              </button>
            </Link> */}
                {/**Code added by Unnati on 07-11-2024
                 *Reason-Added a div*/}
                <div className={OrderDetailStyle.buttons}>
                  {orderStatus?.payment_status == "Completed" && (
                    <div>
                      <button
                        className={OrderDetailStyle.invoiceButton}
                        onClick={() => handleViewInvoice(order)}
                      >
                        {/* view invoice */}
                        View Invoice
                      </button>
                    </div>
                  )}
                  {/**Code added by Unnati on 07-11-2024
                   *Reason-Added view credit note button */}
                  {orderDetail.some(
                    (item) => item.item_status === "Refunded"
                  ) && (
                    <button
                      className={OrderDetailStyle.invoiceButton}
                      onClick={() => handleCreditNote(order)}
                    >
                      View Credit Note
                    </button>
                  )}
                  {/**End of code addition by Unnati on 07-11-2024
                   *Reason-Added view credit note button */}
                </div>
                {/**End of code addition by Unnati on 07-11-2024
                 *Reason-Added a div*/}
                {}
                {/* End of modification by jhamman on 23-10-2024
            Reason - Changed path because we are calling api in invoice page */}
                {/* Added by - Ashlekh on - 25-10-2024
            Reason - To display Tracking Id & link */}
                {orderStatus?.tracking_id != null &&
                  orderStatus?.tracking_id?.trim()?.length > 0 && (
                    <div className={`${OrderDetailStyle.trackingIdContainer}`}>
                      Tracking Id: <div>{orderStatus?.tracking_id}</div>
                    </div>
                  )}
                {orderStatus?.tracking_link != null &&
                  orderStatus?.tracking_link?.trim()?.length > 0 && (
                    <div
                      className={`${OrderDetailStyle.trackingLinkContainer}`}
                    >
                      You can track order using{" "}
                      <Link
                        className={`${OrderDetailStyle.navigationLink}`}
                        to={orderStatus?.tracking_link}
                        target="_blank"
                      >
                        {orderStatus?.tracking_link}
                      </Link>
                    </div>
                  )}

                {/* End of code - Ashlekh on 25-10-2024
            Reason - To display Tracking Id & link */}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;
/**End of code addition by Unnati on 03-08-2024
 * Reason-To have order detail page
 */
