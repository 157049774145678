/**Code added by Unnati on 23-06-2024
 * Reason-To have Product detail page
 */
import React, { useEffect, useState, useRef, useContext } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import ProductDetailStyle from "./ProductDetail.module.css";
import {
  addToCart,
  getProductDetail,
  checkIfProductExistsInCart,
  updateCart,
  addToWishListAPI,
  updateCustomizationCommentAPI,
  removeProductFromWishListAPI,
} from "../../Api/services";
import config from "../../Api/config";
import parse from "html-react-parser";
import { Swiper, SwiperSlide } from "swiper/react";
import "./swiper.css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import Context, { GlobalContext } from "../../context/Context";
import { checkIsEmpty } from "../../utils/validations";
import notificationObject from "../../components/Widgets/Notification/Notification";
import { FaShippingFast, FaWindowClose } from "react-icons/fa";
import InnerImageZoom from "react-inner-image-zoom";
import "./styles.css";
import { calculateDiscountFromProduct } from "../../utils/discountedPrices";
import { Modal } from "antd";
import { Pagination } from "swiper/modules";
import ReactStars from "react-stars";
import Rating from "../../components/Rating/Rating";
import { MdFavorite, MdFavoriteBorder } from "react-icons/md";
// import { Autoplay } from "swiper/modules";

const ProductDetail = () => {
  /**Code added by Unnati Bajaj on 23-06-2024
   * Reason -To scroll to the top when component loads
   */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  /**End of code addition by Unnati Bajaj on 23-06-2024
   * Reason -To scroll to the top when component loads
   */
  const swiperRef = useRef(null);
  const { identifier } = useParams();

  const [products, setProducts] = useState([]);
  const [selectedColor, setSelectedColor] = useState("");
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [availableSizes, setAvailableSizes] = useState({});
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedProductDetails, setSelectedProductDetails] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [availabilityMessage, setAvailabilityMessage] = useState("");
  const [quantityMessage, setQuantityMessage] = useState("");
  const [colorError, setColorError] = useState("");
  const [sizeError, setSizeError] = useState("");
  const [patchSelection, setPatchSelection] = useState("");
  const [embroiderSelection, setEmbroiderSelection] = useState("");
  // Addition by Om Shrivastava on 02-12-2024
  // Reason : Create useState of all customization checkboxes
  const [validationMessage, setValidationMessage] = useState("");
  // End of addition by Om Shrivastava on 02-12-2024
  // Reason : Create useState of all customization checkboxes

  const {
    refreshCartData,
    setCartData,
    // Added by - Ashlekh on 05-10-2024
    // Reason - To use sizeChart variable from context
    sizeChart,
    setSizeChart,
    // End of code - Ashlekh on 05-10-2024
    // Reason - To use sizeChart variable from context
    // Added by - Ashlekh on 04-11-2024
    // Reason - To add wishlist variable from Context
    wishListData,
    setWishListData,
    // End of code - Ashlekh on 04-11-2024
    // Reason - To add wishlist variable from Context
  } = useContext(GlobalContext);
  const { user } = useContext(GlobalContext);
  const navigate = useNavigate();
  const images = [
    selectedProductDetails.image1,
    selectedProductDetails.image2,
    selectedProductDetails.image3,
    selectedProductDetails.image4,
    selectedProductDetails.image5,
  ].filter(Boolean);
  // Added by - Ashlekh on 05-10-2024
  // Reason - To display size chart in antd modal and to have useState for similar product
  const [similarProduct, setSimilarProduct] = useState([]);
  const [sizeChartVisible, setSizeChartVisible] = useState(false);
  const showSizeChart = () => {
    setSizeChartVisible(true);
  };
  const handleCancel = () => {
    setSizeChartVisible(false);
  };
  // End of code - Ashlekh on 05-10-2024
  // Reason - To display size chart in antd modal and to have useState for similar product
  // Addition by Om Shrivastava on 18-11-2024
  // Reason : Apply the customization popup functinality
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => setIsPopupOpen(true);
  const closePopup = () => setIsPopupOpen(false);
  /**Code added by Unnati on 28-11-2024
   * Reason-To get color through location
   */
  const location = useLocation();
  const color = location.state;
  /**End of code addition by Unnati on 28-11-2024
   * Reason-To get color through location
   */
  // Addition by Om Shrivastava on 20-11-2024
  // Reason : Add the logic for posting the comment and customised price
  const [formData, setFormData] = useState({
    logo: false,
    patches: false,
    security_batches: false,
    embroider: false,
    customization_comment: "",
    logo_price: "",
    patches_price: "",
    security_batches_price: "",
    embroider_price: "",
    after_customization_product_price: "",
  });
  // const { comment } = formData;

  // Handle checkbox and input changes
  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
    // Addition by Om Shrivastava on 02-12-2024
    // Reason : Remove validation message if any checkbox is selected
    if (type === "checkbox" && checked) {
      setValidationMessage("");
    }
    // End of addition by Om Shrivastava on 02-12-2024
    // Reason : Remove validation message if any checkbox is selected

  };

  const handleCustomizationSubmit = async () => {
    const {
      customization_comment,
      logo,
      patches,
      security_batches,
      embroider,
    } = formData;

    if (!logo && !patches && !security_batches && !embroider) {
      setValidationMessage("Please select checkbox to customize");
      return;
    }
    setValidationMessage("");
    // Modification and addition by Om Shrivastava on 21-11-2024
    // Reason : Save the value
    const calculateDiscountFromProduct = (salesRate, salePercentage) => {
      return salesRate - (salesRate * salePercentage) / 100;
    };
    // Addition by Om Shrivastava on 01-12-2024
    // Reason : Add the customization calculation
    let basePrice = parseFloat(selectedProductDetails?.sales_rate) || 0;
    console.log("basePrice========",basePrice)
    if(selectedProductDetails.sale_percentage){
      basePrice = selectedProductDetails?.sales_rate - ((selectedProductDetails?.sales_rate * selectedProductDetails?.sale_percentage)/100)
      console.log("discountedprice========",basePrice)
    }
    if (logo) {
      basePrice += parseFloat(selectedProductDetails.logo_price);
    }

    if (patches) {
      basePrice += parseFloat(selectedProductDetails.patches_price);
    }

    if (security_batches) {
      basePrice += parseFloat(selectedProductDetails.security_batches_price);
    }

    if (embroider) {
      basePrice += parseFloat(selectedProductDetails.embroider_price);
    }

    console.log(basePrice, "Base price after adding customization prices");

    // const salesRate = selectedProductDetails?.sale_percentage
    //   ? calculateDiscountFromProduct(
    //       parseFloat(selectedProductDetails.sales_rate),
    //       parseFloat(selectedProductDetails.sale_percentage)
    //     )
    //   : parseFloat(selectedProductDetails?.sales_rate) || 0;

    // const salesRate = selectedProductDetails?.sale_percentage
    //   ? calculateDiscountFromProduct(
    //       basePrice,
    //       parseFloat(selectedProductDetails.sale_percentage)
    //     )
    //   : basePrice;
    // End of addition by Om Shrivastava on 01-12-2024
    // Reason : Add the customization calculation

    let customizationPrice = basePrice;
    console.log(customizationPrice, "check customization price");

    // if (logo) {
    //   customizationPrice += parseFloat(selectedProductDetails?.logo_price) || 0;
    // }
    // if (patches) {
    //   customizationPrice += parseFloat(selectedProductDetails?.patches_price) || 0;
    //   console.log(customizationPrice,'patchess')
    // }
    // if (security_batches) {
    //   customizationPrice += parseFloat(selectedProductDetails?.security_batches_price) || 0;
    //   console.log(customizationPrice,'sequirty')

    // }
    // if (embroider) {
    //   customizationPrice += parseFloat(selectedProductDetails?.embroider_price) || 0;
    //   console.log(customizationPrice,'embridere')

    // }

    // customizationPrice = parseFloat(customizationPrice);
    console.log(customizationPrice, "checkkkkkkkkk"); // Force it to be a number

    const formattedCustomizationPrice = isNaN(customizationPrice)
      ? "0.00"
      : customizationPrice.toFixed(2);

    console.log(formattedCustomizationPrice, "Formatted customization price");
    // Modification and addition by Om Shrivastava on 01-12-2024
    // Reason : Remove the API save only state
    // // Prepare the data to be sent
    // const postData = {
    //   id: selectedProductDetails?.id,
    //   customization_comment: comment,
    //   logo,
    //   patches,
    //   security_batches: security_batches,
    //   embroider,
    //   after_customization_product_price: formattedCustomizationPrice,
    // };

    // console.log(postData);

    // try {
    //   const response = await updateCustomizationCommentAPI(postData);
    //   console.log("Customization saved successfully:", response);
    //   notificationObject.success("Customization options saved!");
    //   closePopup();
    // } catch (error) {
    //   console.error("Error saving customization:", error.message);
    //   // alert("Failed to save customization. Please try again.");
    // }
    setFormData((prevState) => ({
      ...prevState,
      after_customization_product_price: formattedCustomizationPrice,
      customization_comment,
      logo,
      patches,
      security_batches,
      embroider,
      logo_price: parseFloat(selectedProductDetails?.logo_price) || "0.00",
      patches_price:
        parseFloat(selectedProductDetails?.patches_price) || "0.00",
      security_batches_price:
        parseFloat(selectedProductDetails?.security_batches_price) || "0.00",
      embroider_price:
        parseFloat(selectedProductDetails?.embroider_price) || "0.00",
    }));

    console.log("Updated formData:", {
      ...formData,
      after_customization_product_price: formattedCustomizationPrice,
      logo_price: parseFloat(selectedProductDetails?.logo_price) || "0.00",
      patches_price:
        parseFloat(selectedProductDetails?.patches_price) || "0.00",
      security_batches_price:
        parseFloat(selectedProductDetails?.security_batches_price) || "0.00",
      embroider_price:
        parseFloat(selectedProductDetails?.embroider_price) || "0.00",
    });

    notificationObject.success("Customization details are saved successfully.");
    closePopup();
    // Added by Om Shrivastava on 03-12-2024
    // Reason : Change the selected size 
    /**
     * Commented by - Ashish Dewangan on 06-12-2024
     * Reason - size should not be reset on customization submit
     */
    // setSelectedSize("")
    /**
     * End of comment by - Ashish Dewangan on 06-12-2024
     * Reason - size should not be reset on customization submit
     */
    // End of addition by Om Shrivastava on 03-12-2024
    // Reason : Change the selected size 
    // End of modification and addition by Om Shrivastava on 01-12-2024
    // Reason : Remove the API save only state
  };
  // End of modification and addition by Om Shrivastava on 21-11-2024
  // Reason : Save the value
  // End of addition by Om Shrivastava on 20-11-2024
  // Reason : Add the logic for posting the comment and customised price
  // End of addition by Om Shrivastava on 18-11-2024
  // Reason : Apply the customization popup functinality

  /**Code added by Unnati on 03-04-2024
   * Reason-To handle change when size is selected
   */
  const handleSelectChange = (size) => {
    setSelectedSize(size);
    setQuantityMessage("");
    setQuantity(1);
    const stock = availableSizes[size];
    updateAvailabilityMessage(size, stock);
    /**Code added by Unnati on 25-10-2024
     * Reason-To set error message as empty on change
     */
    setSizeError("");
    /*End of code addition by Unnati on 25-10-2024
     * Reason-To set error message as empty on change
     */

    /**
     * Added by - Ashish Dewangan on 06-12-2024
     * Reason - To reset customization on size change
     */
    setFormData((prev) => ({
      ...prev,
      logo: false,
      patches: false,
      security_batches : false,
      embroider : false,
      logo_price: "",
      patches_price: "",
      security_batches_price: "",
      embroider_price: "",
      after_customization_product_price: "",
      customization_comment: "",
    }));
    /**
     * End of addition by - Ashish Dewangan on 06-12-2024
     * Reason - To reset customization on size change
     */
  };

  /**End of code addition by Unnati on 03-04-2024
   * Reason-To handle change when size is selected
   */

  /**Code commented by Unnati on 01-07-2024
   * Reason-To handle previous click in image slider(It is not used currently)
   */
  // const handlePreviousClick = () => {
  //   setCurrentImageIndex(
  //     (prevIndex) => (prevIndex - 1 + images.length) % images.length
  //   );
  // };
  /**End of code addition by Unnati on 01-07-2024
   * Reason-To handle previous click in image slider(It is not used currently)
   */
  /**Code commented by Unnati on 01-07-2024
   * Reason-To handle previous click in image slider(It is not used currently)
   */
  // const handleNextClick = () => {
  //   setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  // };
  /**End of code comment by Unnati on 01-07-2024
   * ReasonTo handle previous click in image slider(It is not used currently)
   */
  /**Code added by Unnati on 01-07-2024
   * Reason-To handle increment while entering quantity
   */
  const handleIncrement = () => {
    if (!selectedSize) {
      setQuantityMessage("Please select a size before adjusting the quantity.");
      return;
    }
    setAvailabilityMessage("");
    const stock = availableSizes[selectedSize];
    if (quantity < stock) {
      setQuantity(quantity + 1);
      setQuantityMessage(" ");
    } else {
      setQuantityMessage(`Only ${stock} items available in ${selectedSize}.`);
    }
  };
  /**End of code addition by Unnati on 01-07-2024
   * Reason-To handle increment while entering quantity
   */
  /**Code added by Unnati on 01-07-2024
   * Reason-To handle decrement while entering quantity
   */
  const handleDecrement = () => {
    if (!selectedSize) {
      setQuantityMessage("Please select a size before selecting the quantity.");
      return;
    }
    setAvailabilityMessage("");
    if (quantity > 1) {
      setQuantity(quantity - 1);
      QuantityMessage(selectedSize, availableSizes[selectedSize]);
    }
  };
  /**End of code addition by Unnati on 01-07-2024
   * Reason-To handle decrement while entering quantity
   */
  /**Code commented by Unnati on 23-06-2024
   * Reason-Previously used to handle thumbnail picture click
   */
  // const handleThumbnailClick = (image) => {
  //   setLargeImage(image);
  //   setActiveThumbnail(image);
  // };
  /**End of code comment by Unnati on 23-06-2024
   * Reason-Previously used to handle thumbnail picture click
   */
  /**Code added by Unnati on 03-04-2024
   * Reason-To add slider functionality
   */
  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(currentImageIndex);
    }
  }, [currentImageIndex]);
  /**End of code addition by Unnati on 03-04-2024
   * Reason-To add slider functionality
   */

  /**Code added by Unnati Bajaj on 23-06-2024
   * Reason -To get products when the component loads
   */
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        let fetchedProducts;
        const response = await getProductDetail(
          identifier,
          /**Code added by Unnati on 28-11-2024
           * Reason-Added color
           */
          color
        );
        /**End of code addition by Unnati on 28-11-2024
         * Reason-Added color
         */
        /**Code modified by Unnati on 28-11-2024
         * Reason-Modified fetched products
         */
        // fetchedProducts = response.product;
        fetchedProducts = response.product.product_list;
        /**End of code modification by Unnati on 28-11-2024
         * Reason-Modified fetched products
         */

        setProducts(fetchedProducts);
        // Added by - Ashlekh on 05-10-2024
        // Reason - To set similar product
        setSimilarProduct(response?.similar_product);
        // End of code - Ashlekh on 05-10-2024
        // Reason - To set similar product
        /**Code added by Unnati on 23-06-2024
         * Reason-To initialise the fields when redirected to this product detail page
         */
        if (fetchedProducts.length > 0) {
          setSelectedColor(fetchedProducts[0].color);
          setSelectedProductDetails(fetchedProducts[0]);
          const sizes = {
            XS: fetchedProducts[0].XS,
            S: fetchedProducts[0].S,
            M: fetchedProducts[0].M,
            L: fetchedProducts[0].L,
            XL: fetchedProducts[0].XL,
            XXL: fetchedProducts[0].XXL,
            XXXL: fetchedProducts[0].XXXL,
          };
          setAvailableSizes(sizes);
          /*Code commented  by Unnati on 23-06-2024
           *Reason-This code is not in use currently
           */
          // setLargeImage(fetchedProducts[0].image1);
          // setActiveThumbnail(fetchedProducts[0].image1);

          // const productThumbnails = fetchedProducts.map((product) => ({
          //   image: product.image1,
          //   color: product.color,
          // }));
          // setThumbnails(productThumbnails);
          /*End of code commented  by Unnati on 23-06-2024
           *Reason-This code is not in use currently
           */
        }
        /**End of code addition by Unnati on 23-06-2024
         * Reason-To initialise the product when redirected to this product detail page
         */
      } catch (error) {
        console.error("Error fetching product:", error.message);
      }
    };

    fetchProduct();
  }, [
    identifier,
    /**Code added by Unnati on 28-11-2024
     * Reason-To have color
     */
    color,
  ]);
  /**End of code addition by Unnati on 28-11-2024
   * Reason-To have color
   */
  /**End of code addition by Unnati Bajaj on 23-06-2024
   * Reason -To get products when the component loads
   */
  // useEffect(() => {
  //   const fetchProduct = async () => {
  //     try {
  //       // Check if the user came from the View Cart page
  //       if (location.state?.from === 'viewCart') {
  //         const response = await editProductDetail(identifier);
  //         setProducts(response.product)
  //       }
  //     } catch (error) {
  //       console.error("Error fetching product:", error.message);
  //     }
  //   };

  //   fetchProduct();
  // }, [identifier, location.state]);
  /**Code added by Unnati on 23-06-2024
   *Reason-To select color and show the available sizes
   */
  const handleColorSelect = (color) => {
    setSelectedColor(color);
    const selectedProduct = products.find((product) => product.color === color);
    setSelectedProductDetails(selectedProduct);
    const sizes = {
      XS: selectedProduct.XS,
      S: selectedProduct.S,
      M: selectedProduct.M,
      L: selectedProduct.L,
      XL: selectedProduct.XL,
      XXL: selectedProduct.XXL,
      XXXL: selectedProduct.XXXL,
    };

    setAvailableSizes(sizes);

    if (selectedSize) {
      const stock = sizes[selectedSize];
      updateAvailabilityMessage(selectedSize, stock);
      /**Code added by Unnati on 24-08-2024
       * Reason-Added quantity message
       */
      QuantityMessage(selectedSize, stock);
      /**End of code addition by Unnati on 24-08-2024
       * Reason-Added quantity message
       */
    }

    /**
     * Added by - Ashish Dewangan on 06-12-2024
     * Reason - To unselect size and customization on color change
     */
    setSelectedSize("");
    setFormData((prev) => ({
      ...prev,
      logo: false,
      patches: false,
      security_batches : false,
      embroider : false,
      logo_price: "",
      patches_price: "",
      security_batches_price: "",
      embroider_price: "",
      after_customization_product_price: "",
      customization_comment: "",
    }));
    /**
     * End of addition by - Ashish Dewangan on 06-12-2024
     * Reason - To unselect size and customization on color change
     */
  };
  /**End of code addition by Unnati on 23-06-2024
   *Reason-To select color and show the available sizes
   */

  /**Code added by Unnati on 04-07-2024
   * Reason -To check the stock and display the message accordingly
   */
  const updateAvailabilityMessage = (size, stock) => {
    if (stock === 0) {
      setAvailabilityMessage(`${size} is not available`);
    } else if (stock < 10) {
      /**Code modified by Unnati on 30-10-2024
       * Reason-Changed the message
       */
      setAvailabilityMessage(`Only a few quantity left in ${size}`);
      /*End of code modification by Unnati on 30-10-2024
       * Reason-Changed the message
       */
    } else {
      setAvailabilityMessage("");
    }
  };
  /**End of code addition by Unnati on 04-07-2024
   * Reason -To check the stock and display the message accordingly
   */
  const QuantityMessage = (size, stock) => {
    if (stock === 0) {
      setQuantityMessage(" ");
    } else if (stock < 10) {
      setQuantityMessage(`Only ${stock} left in ${size}`);
    }
  };
  /**Code added by Unnati on 28-08-2024
   * Reason-To check is size is available or not
   */
  const isSizeAvailable = (size) => {
    return availableSizes[size] > 0;
  };
  /**End of code addition  by Unnati on 28-08-2024
   * Reason-To check is size is available or not
   */

  {
    /**Code added by Unnati on 23-06-2024
     *Reason-Created a set of unique colors and maps ieach product to its color*/
  }
  const uniqueColors = [...new Set(products.map((product) => product.color))];
  {
    /**End of code addition by Unnati on 23-06-2024
     *Reason-Created a set of unique colors and maps ieach product to its color*/
  }
  {
    /**Code added by Unnati on 03-07-2024
     *Reason -To validate input on submit */
  }
  const isValidOnSubmit = () => {
    if (checkIsEmpty(selectedColor)) {
      setColorError("Please select a color.");
      return false;
    }
    if (checkIsEmpty(selectedSize)) {
      setSizeError("Please select a size.");
      return false;
    }
    return true;
  };

  /**End of code addition by Unnati on 03-07-2024
   *Reason -To validate input on submit */

  /**Code added by Unnati on 03-07-2024
   *Reason -To post add to cart details */
  /**Code added by Unnati on 30-07-2024
   * Reason-To store current date and time in variable
   */
  let currentTimeAndDate = Date.now();
  /**End of code addition by Unnati on 30-07-2024
   * Reason-To store current date and time in variable
   */
  const addToCartDetails = async (e) => {
    e.preventDefault();
  
    if (isValidOnSubmit()) {
      const data = {
        product: selectedProductDetails.id,
        XS: selectedSize === "XS" ? quantity : null,
        S: selectedSize === "S" ? quantity : null,
        M: selectedSize === "M" ? quantity : null,
        L: selectedSize === "L" ? quantity : null,
        XL: selectedSize === "XL" ? quantity : null,
        XXL: selectedSize === "XXL" ? quantity : null,
        XXXL: selectedSize === "XXXL" ? quantity : null,
        user: user && user.id ? user.id : null,
        color: selectedProductDetails.color,

        /* Modified by Jhamman on 08-10-2024
        Reason - Added sale rate that we get after discount*/
        // sales_rate: selectedProductDetails.sales_rate,
        // sales_rate: selectedProductDetails.sale_percentage
        //   ? calculateDiscountFromProduct(
        //       selectedProductDetails.sales_rate,
        //       selectedProductDetails.sale_percentage
        //     )
        //   : selectedProductDetails.sales_rate,
        sales_rate: selectedProductDetails.sales_rate,
        /*End of modification by Jhamman on 08-10-2024
        Reason - Added sale rate that we get after discount*/

        image1: selectedProductDetails.image1,
        name: selectedProductDetails.name,
        /**Code added by Unnati on 30-07-2024
         * Reason-Added updated and created at
         */
        created_at: currentTimeAndDate,
        updated_at: currentTimeAndDate,
        /**End of code addition by Unnati on 30-07-2024
         * Reason-Added updated and created at
         */
        /**Code added by Unnati on 11-09-2024
         * Reason-Added patches and embroider for each sizes
         */
        xs_patches:
          selectedSize === "XS"
            ? patchSelection === "Yes"
              ? true
              : false
            : false,
        s_patches:
          selectedSize === "S"
            ? patchSelection === "Yes"
              ? true
              : false
            : false,
        m_patches:
          selectedSize === "M"
            ? patchSelection === "Yes"
              ? true
              : false
            : false,
        l_patches:
          selectedSize === "L"
            ? patchSelection === "Yes"
              ? true
              : false
            : false,
        xl_patches:
          selectedSize === "XL"
            ? patchSelection === "Yes"
              ? true
              : false
            : false,
        xxl_patches:
          selectedSize === "XXL"
            ? patchSelection === "Yes"
              ? true
              : false
            : false,
        xxxl_patches:
          selectedSize === "XXXL"
            ? patchSelection === "Yes"
              ? true
              : false
            : false,

        xs_embroider:
          selectedSize === "XS"
            ? embroiderSelection === "Yes"
              ? true
              : false
            : false,
        s_embroider:
          selectedSize === "S"
            ? embroiderSelection === "Yes"
              ? true
              : false
            : false,
        m_embroider:
          selectedSize === "M"
            ? embroiderSelection === "Yes"
              ? true
              : false
            : false,
        l_embroider:
          selectedSize === "L"
            ? embroiderSelection === "Yes"
              ? true
              : false
            : false,
        xl_embroider:
          selectedSize === "XL"
            ? embroiderSelection === "Yes"
              ? true
              : false
            : false,
        xxl_embroider:
          selectedSize === "XXL"
            ? embroiderSelection === "Yes"
              ? true
              : false
            : false,
        xxxl_embroider:
          selectedSize === "XXXL"
            ? embroiderSelection === "Yes"
              ? true
              : false
            : false,

        /**End of code addition by Unnati on 12-09-2024
         * Reason-Added patches and embroider
         */
        // Addition by Om Shrivastava on 01-12-2024
        // Reason : Send the customization keys
        after_customization_product_price:
          formData.after_customization_product_price,
        customization_comment: formData.customization_comment,
        logo: formData.logo,
        patches: formData.patches,
        security_batches: formData.security_batches,
        embroider: formData.embroider,
        logo_price: formData.logo_price,
        patches_price: formData.patches_price,
        security_batches_price: formData.security_batches_price,
        embroider_price: formData.embroider_price,
        size: selectedSize,
        quantity: quantity,
        // is_active : selectedProductDetails.is_active,
      };
      // console.log("checkkkkkkkk",data)
      setFormData((prevState) => ({
        ...prevState,
        after_customization_product_price:
          formData.after_customization_product_price,
        customization_comment: formData.customization_comment,
        logo: formData.logo,
        patches: formData.patches,
        security_batches: formData.security_batches,
        embroider: formData.embroider,
        logo_price: formData.logo_price,
        patches_price: formData.patches_price,
        security_batches_price: formData.security_batches_price,
        embroider_price: formData.embroider_price,
      }));
      // End of addition by Om Shrivastava on 01-12-2024
      // Reason : Send the customization keys

      /**Code added by Unnati on 05-07-2024
       *Reason-To check whether user is logged in or not*/

      if (user && user.id) {
        data.user = user.id;
        try {
          /**Code added by Unnati on 10-07-2024
           *Reason-To check if the product exist already or not
           */

          const response = await checkIfProductExistsInCart(
            selectedProductDetails.id,
            user.id,
            data
          );

          /**End of code addition by Unnati on 10-07-2024
           *Reason-To check if the product exist already or not
           */

          /**Code added by Unnati on 10-07-2024
           *Reason-If product exists then update the cart
           */

          if (response.exists === false) {
            console.log("checkkkkkkkk beforeeeeee", data);

            const addResponse = await addToCart(data);
            /**Code added by Unnati on 26-07-2024
             * Reason-To store cartdata in local storage
             */
            localStorage.setItem("cartData", JSON.stringify(addResponse));
            /**End of code addition by Unnati on 26-07-2024
             * Reason-To store cartdata in local storage
             */
            /**Code added by Unnati on 26-07-2024
             * Reason-To set cartdata in context
             */
            setCartData(addResponse);
            /**End of code addition by Unnati on 26-07-2024
             * Reason-To set cartdata in context
             */
            if (addResponse.success) {
              notificationObject.success("Product added to cart successfully");
              navigate("/checkout");
            } else if (addResponse.error) {
              notificationObject.error("Failed to add product to cart");
            }

            /**End of code addition by Unnati on 10-07-2024
             *Reason-If product exists then update the cart
             */
          } else {
            /**Code added by Unnati on 04-07-2024
             *Reason-To add products in the cart if it does not exists */

            const updateResponse = await updateCart(data);
            /**Code added by Unnati on 27-07-2024
             * Reason-To update cartdata in local storage
             */
            localStorage.setItem(
              "cartData",
              JSON.stringify(updateResponse.cartData)
            );
            /**End of code addition by Unnati on 27-07-2024
             * Reason-To update cartdata in local storage
             */
            /**Code added by Unnati on 27-07-2024
             * Reason-To set updated cartdata in context
             */
            setCartData(updateResponse.cartData);
            /**End of code addition by Unnati on 27-07-2024
             * Reason-To set updated cartdata in context
             */
            if (updateResponse.success) {
              notificationObject.success("Cart updated successfully");
              navigate("/checkout");
            } else if (updateResponse.error) {
              notificationObject.error("Failed to update cart");
            }
          }
          {
            /**End of Code addition by Unnati on 04-07-2024
             *Reason-To add products in the cart if it does not exists */
          }

          {
            /**End of Code addition by Unnati on 04-07-2024
             *Reason-To add products in the cart if it does not exists */
          }
        } catch (error) {
          notificationObject.error("An error occurred while updating the cart");
        }

        /**End of code addition by Unnati on 05-07-2024
         *Reason-To check whether user is logged in or not*/
      } else {
        /**Code added by Unnati on 05-05-2024
         * Reason-To store details in local storage if user is not logged in
         */
        const productDetail = {
          sale_percentage: selectedProductDetails.sale_percentage,
          is_active:selectedProductDetails.is_active,
          product_id:selectedProductDetails.product_id,
          product: selectedProductDetails.id,
          XS: selectedSize === "XS" ? quantity : null,
          S: selectedSize === "S" ? quantity : null,
          M: selectedSize === "M" ? quantity : null,
          L: selectedSize === "L" ? quantity : null,
          XL: selectedSize === "XL" ? quantity : null,
          XXL: selectedSize === "XXL" ? quantity : null,
          XXXL: selectedSize === "XXXL" ? quantity : null,
          user: user && user.id ? user.id : null,
          color: selectedProductDetails.color,
          sales_rate: selectedProductDetails.sales_rate,
          image1: selectedProductDetails.image1,
          name: selectedProductDetails.name,
          /**Code added by Unnati on 30-07-2024
           * Reason-Added updated and created at
           */
          created_at: currentTimeAndDate,
          updated_at: currentTimeAndDate,
          /**End of code addition by Unnati on 30-07-2024
           * Reason-Added updated and created at
           */
          /**Code added by Unnati on 11-09-2024
           * Reason-Added patches and embroider for each sizes
           */
          xs_patches:
            selectedSize === "XS"
              ? patchSelection === "Yes"
                ? true
                : false
              : false,
          s_patches:
            selectedSize === "S"
              ? patchSelection === "Yes"
                ? true
                : false
              : false,
          m_patches:
            selectedSize === "M"
              ? patchSelection === "Yes"
                ? true
                : false
              : false,
          l_patches:
            selectedSize === "L"
              ? patchSelection === "Yes"
                ? true
                : false
              : false,
          xl_patches:
            selectedSize === "XL"
              ? patchSelection === "Yes"
                ? true
                : false
              : false,
          xxl_patches:
            selectedSize === "XXL"
              ? patchSelection === "Yes"
                ? true
                : false
              : false,
          xxxl_patches:
            selectedSize === "XXXL"
              ? patchSelection === "Yes"
                ? true
                : false
              : false,

          xs_embroider:
            selectedSize === "XS"
              ? embroiderSelection === "Yes"
                ? true
                : false
              : false,
          s_embroider:
            selectedSize === "S"
              ? embroiderSelection === "Yes"
                ? true
                : false
              : false,
          m_embroider:
            selectedSize === "M"
              ? embroiderSelection === "Yes"
                ? true
                : false
              : false,
          l_embroider:
            selectedSize === "L"
              ? embroiderSelection === "Yes"
                ? true
                : false
              : null,
          xl_embroider:
            selectedSize === "XL"
              ? embroiderSelection === "Yes"
                ? true
                : false
              : false,
          xxl_embroider:
            selectedSize === "XXL"
              ? embroiderSelection === "Yes"
                ? true
                : false
              : false,
          xxxl_embroider:
            selectedSize === "XXXL"
              ? embroiderSelection === "Yes"
                ? true
                : false
              : false,
          /**End of code addition by Unnati on 12-09-2024
           * Reason-Added patches and embroider
           */

          after_customization_product_price: formData.after_customization_product_price,
          customization_comment: formData.customization_comment,
          logo: formData.logo,
          patches: formData.patches,
          security_batches: formData.security_batches,
          embroider: formData.embroider,
          logo_price: formData.logo_price,
          patches_price: formData.patches_price,
          security_batches_price: formData.security_batches_price,
          embroider_price: formData.embroider_price,
          size: selectedSize,
          quantity: quantity,
        };
        const cartData = localStorage.getItem("cartData")
          ? JSON.parse(localStorage.getItem("cartData"))
          : [];

        // const updateCartData = (cartData, productDetail) => {
        //   let doesItemExist = false;
        //   const updatedCartData = cartData.map((cartItem) => {
        //     console.log(
        //       "*******************",
        //       cartItem.product,
        //       "=====",
        //       productDetail.product
        //     );
        //     if (
        //       cartItem.product === productDetail.product &&
        //       cartItem.size === productDetail.size &&
        //       cartItem.logo === productDetail.logo &&
        //       cartItem.patches === productDetail.patches &&
        //       cartItem.security_batches === productDetail.security_batches &&
        //       cartItem.embroider === productDetail.embroider
        //     ) {
        //       doesItemExist = true;
        //       {
        //         /**Code added by Unnati on 28-08-2024
        //          * Reason-To update sizes only not all other fields
        //          */
        //       }
        //       return {
        //         ...cartItem,
        //         ...Object.keys(productDetail).reduce((acc, key) => {
        //           if (
        //             key !== "product" &&
        //             key !== "color" &&
        //             key !== "created_at" &&
        //             key !== "embroider_text" &&
        //             key !== "image1" &&
        //             key !== "name" &&
        //             key !== "patches" &&
        //             key !== "sales_rate" &&
        //             key !== "updated_at" &&
        //             key !== "user" &&
        //             data[key] !== null
        //           ) {
        //             acc[key] = (cartItem[key] || 0) + productDetail[key];
        //           }
        //           return acc;
        //         }, {}),
        //       };
        //     }

        //     return cartItem;
        //   });
        //   if (!doesItemExist) {
        //     return [...cartData, productDetail];
        //   }

        //   return updatedCartData;
        // };

        const updateCartData = (cartData, productDetail) => {
          let doesItemExist = false;
          var updatedCartData = cartData.map((cartItem) => {
            console.log("Checking cartItem:", cartItem);
            console.log("Against productDetail:", productDetail);
          
            if (
              cartItem.product === productDetail.product &&
              cartItem.size === productDetail.size &&
              cartItem.logo === productDetail.logo &&
              cartItem.patches === productDetail.patches &&
              cartItem.security_batches === productDetail.security_batches &&
              cartItem.embroider === productDetail.embroider&&
              cartItem.color === productDetail.color

            ) {
              doesItemExist=true;
              console.log("=======exists, updating cartItem");
              return {
                ...cartItem,
                logo: productDetail.logo,
                patches: productDetail.patches,
                security_batches: productDetail.security_batches,
                embroider: productDetail.embroider,
                size: productDetail.size,
                quantity: cartItem.quantity+productDetail.quantity,
                logo_price: productDetail.logo_price,
                patches_price: productDetail.patches_price,
                security_batches_price: productDetail.security_batches_price,
                embroider_price: productDetail.embroider_price,
                after_customization_product_price: parseFloat(cartItem.after_customization_product_price),
                customization_comment: productDetail.customization_comment,
              };
            }
            return cartItem
            
            // If no match, return the item as is
            
          });
          
          // Log the result for confirmation
          // console.log("Updated cart data:", updatedCartData);
          
          if (!doesItemExist) {
            console.log("_______________",productDetail)
            return [...cartData, productDetail];
          }
          console.log(updatedCartData)
          return updatedCartData;
        };

        const updatedCartData = updateCartData(cartData, productDetail);
        localStorage.setItem("cartData", JSON.stringify(updatedCartData));
        setCartData(updatedCartData);
        refreshCartData();
        notificationObject.success("Product added to cart successfully");
      }
      /**End of code addition by Unnati on 05-05-2024
       * Reason-To store details in local storage if user is not logged in
       */

      /**
       * Added by - Ashish Dewangan on 06-12-2024
       * Reason - TO reset size and customization after add to cart is clicked
       */
      setSelectedSize("")
      setFormData((prev) => ({
        ...prev,
        logo: false,
        patches: false,
        security_batches : false,
        embroider : false,
        logo_price: "",
        patches_price: "",
        security_batches_price: "",
        embroider_price: "",
        after_customization_product_price: "",
        customization_comment: "",
      }));
      /**
       * End of addition by - Ashish Dewangan on 06-12-2024
       * Reason - TO reset size and customization after add to cart is clicked
       */
    }
    /**Code added by Unnati on 25-08-2024
     * Reason-Clear form after add to cart button
     */
    setPatchSelection("");
    setEmbroiderSelection("");
    setQuantity(1);
    setSelectedSize("");
    setAvailabilityMessage("");
    setQuantityMessage("");
    /**End of code additon  by Unnati on 25-08-2024
     * Reason-Clear form after add to cart button
     */
  };
  {
    /**End of code addition by Unnati on 03-07-2024
     *Reason -To post add to cart details */
  }
  {
    /**Code added by Unnati on 03-07-2024
     *Reason-To handle submit when clicked on add to cart button */
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    addToCartDetails(e);
  };
  {
    /**End of code addition by Unnati on 03-07-2024
     *Reason-To handle submit when clicked on add to cart button */
  }
  const handleQuantityChange = (e) => {
    const value = parseInt(e.target.value, 10);
    const stock = availableSizes[selectedSize];
    // Code changed by - Ashlekh on 27-11-2024
    // Reason - To check no negative value are set
    // if (value <= availableSizes[selectedSize]) {
    //   setQuantity(value);
    // } else {
    //   setAvailabilityMessage(`Only ${stock} items left in ${selectedSize}.`);
    // }
    // Added by - Ashlekh on 02-12-2024
    // Reason - To add validation message if size is not selected
    if (!selectedSize) {
      setAvailabilityMessage('Please select a size first.');
      setQuantity(1);
      return;
    }
    // End of code - Ashlekh on 02-12-2024
    // Reason - To add validation message if size is not selected
    if (!isNaN(value) && value > 0) {
      if (value <= stock) {
        setQuantity(value);
        setAvailabilityMessage('');
      } else {
        setAvailabilityMessage(`Only ${stock} items left in ${selectedSize}.`);
      }
    } else {
      setQuantity(1);
      setAvailabilityMessage('Quantity cannot be less than 1.');
    }
    // End of code - Ashlekh on 27-11-2024
    // Reason - To check no negative value are set
  };

  // Added by - Ashlekh on 30-10-2024
  // Reason - To add products in wishlist
  const handleWishList = async (user, selectedProductDetails) => {
    if (user.id != null || user.id != undefined) {
      const response = await addToWishListAPI(
        user.id,
        selectedProductDetails?.product_id,
        selectedProductDetails?.color
      );
      localStorage.setItem(
        "wishListData",
        JSON.stringify(response?.wishlist_data)
      );
      setWishListData(response?.wishlist_data);
      if (response?.message == "Success") {
        notificationObject.success("Product successfully added in wishlist");
      } else if (response?.message == "Product Already in Wishlist") {
        // Code changed by - Ashlekh on 19-11-2024
        // Reason - To change notification message
        // notificationObject.success("Product already is in wishlist");
        notificationObject.success("Product is already in your wishlist");
        // End of code - Ashlekh on 19-11-2024
        // Reason - To change notification message
      } else {
        notificationObject.error("Failed to add product to cart");
      }
    } else {
      // Code commented by - Ashlekh on 16-11-2024
      // Reason - To navigate in login page if guest user clicks on Add to wishlist
      // const wishListItems = {
      //   user_id: user?.id || null,
      //   product_id: selectedProductDetails?.product_id || null,
      //   color: selectedProductDetails?.color || null,
      // }
      // const existingWishList = JSON.parse(localStorage.getItem("wishListData")) || [];
      // const isItemInWishlist = existingWishList.some(
      //   (item) =>
      //       item.user_id == wishListItems.user_id &&
      //       item.product_id == wishListItems.product_id &&
      //       item.color == wishListItems.color
      // );
      // if (!isItemInWishlist) {
      //   const updatedWishList = [...existingWishList, wishListItems];
      //   localStorage.setItem("wishListData", JSON.stringify(updatedWishList));
      //   setWishListData(updatedWishList);
      // }
      // notificationObject.success("Product successfully added in wishlist");
      navigate("/login");
      // End of code - Ashlekh on 16-11-2024
      // Reason - To navigate in login page if guest user clicks on Add to wishlist
    }
  };
  // End of code - Ashlekh on 30-10-2024
  // Reason - To add products in wishlist

  // Added by - Ashlekh on 19-11-2024
  // Reason - When wishlist icon is clicked then to add/remove product in Wishlist
  const [isWishListed, setIsWishListed] = useState(false);
  useEffect(() => {
    let productInWishlist = false;
    wishListData?.map((item) => {
      if (
        item.product_id == selectedProductDetails?.product_id &&
        item.color == selectedProductDetails?.color
      ) {
        productInWishlist = true;
      }
      return item;
    });

    setIsWishListed(productInWishlist);
  }, [wishListData, selectedProductDetails]);
  const handleToggleWishList = async (user, selectedProductDetails) => {
    if (user.id == null || user.id == undefined) {
      navigate("/login");
      return;
    } else {
      try {
        if (isWishListed) {
          const response = await removeProductFromWishListAPI(
            user.id,
            selectedProductDetails?.product_id,
            selectedProductDetails?.color
          );
          localStorage.setItem(
            "wishListData",
            JSON.stringify(response.wishlist)
          );
          setWishListData(response.wishlist);
          if (response?.message) {
            setIsWishListed(false);
            notificationObject.success(
              "Product successfully removed from wishlist"
            );
          }
        } else {
          const response = await addToWishListAPI(
            user.id,
            selectedProductDetails?.product_id,
            selectedProductDetails?.color
          );
          localStorage.setItem(
            "wishListData",
            JSON.stringify(response?.wishlist_data)
          );
          setWishListData(response?.wishlist_data);
          if (response?.message == "Success") {
            setIsWishListed(true);
            notificationObject.success(
              "Product successfully added to wishlist"
            );
          } else if (response?.message == "Product Already in Wishlist") {
            notificationObject.success("Product is already in your wishlist");
          } else {
            notificationObject.error("Failed to add product to wishlist");
          }
        }
      } catch (error) {
        console.error("Error updating wishlist:", error);
      }
    }
  };
  // End of code - Ashlekh on 19-11-2024
  // Reason - When wishlist icon is clicked then to add/remove product in Wishlist
  return (
    <div className={ProductDetailStyle.pageFrame}>
      <div className={ProductDetailStyle.pageContainer}>
        <div className={ProductDetailStyle.product}>
          <div className={ProductDetailStyle.imageContainer}>
            {/**Code added by Unnati on 01-07-2024
             *Reason To display product images by sliding */}
            <Swiper
              navigation={true}
              modules={[Navigation]}
              className={ProductDetailStyle.mySwiper}
              onSwiper={(swiper) => {
                swiperRef.current = swiper;
              }}
              onSlideChange={(swiper) =>
                setCurrentImageIndex(swiper.activeIndex)
              }
            >
              {images.map((image, index) => (
                <SwiperSlide key={index}>
                  <InnerImageZoom
                    src={
                      image
                        ? `${config.baseURL}${image}`
                        : "https://via.placeholder.com/400"
                    }
                    /**Code added by Unnati on 13-09-2024
                     *Reason-To have zoom functionality in images */
                    /**Code modifed by Unnati on 20-09-2024
                     Reason-Need to change the image section designing */
                    zoomSrc={
                      image
                        ? `${config.baseURL}${image}`
                        : "https://via.placeholder.com/400"
                    }
                    zoomType="hover"
                    /**End of code addition by Unnati on 13-09-2024
                     *Reason-To have zoom functionality in images */
                    className={ProductDetailStyle.largeImage}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            {/**End of code addition by Unnati on 01-07-2024
             *Reason To display product images by sliding */}
            <div className={ProductDetailStyle.thumbnailContainer}>
              {images.map((image, index) => (
                <img
                  key={index}
                  src={
                    image
                      ? `${config.baseURL}${image}`
                      : "https://via.placeholder.com/100"
                  }
                  alt={`Thumbnail ${index + 1}`}
                  className={`${ProductDetailStyle.thumbnailImage} ${
                    index === currentImageIndex
                      ? ProductDetailStyle.activeThumbnail
                      : ""
                  }`}
                  onClick={() => setCurrentImageIndex(index)}
                />
              ))}
            </div>
          </div>
          {/**End of code modification by Unnati on 20-09-2024
           Reason-Need to change the image section designing */}
          <div className={ProductDetailStyle.productInfo}>
            <h1 className={ProductDetailStyle.productName}>
              {selectedProductDetails.name}
            </h1>

            <p className={ProductDetailStyle.productDescription}>
              {selectedProductDetails.description}
            </p>
            {/**Code added by Unnati on 17-10-2024
             *Reason-Added star rating */}
            <div className={ProductDetailStyle.productRating}>
              {selectedProductDetails.rating > 0 && (
                /**Code commented by Unnati on 18-10-2024
                 *Reason-Commented star component*/
                /* <ReactStars
                                  className={CategoryStyle.star}
                                  count={5}
                                  value={product.rating}
                                  size={12}
                                  color2={"#ffd700"}
                                  edit={false}
                                /> */
                /**End of code comment by Unnati on 18-10-2024
                 *Reason-Commented star component*/
                /**Code added by Unnati on 18-10-2024
                 *Reason-Calling component*/
                <Rating value={selectedProductDetails.rating} />
                /**End of code addition by Unnati on 18-10-2024
                 *Reason-Calling component*/
              )}
            </div>
            {/**End of code addition by Unnati on 17-10-2024
             *Reason-Added star rating */}
            {/**Code added by Unnati on 12-09-2024
             *Reason-Added category and brand */}

            {/* Modified by jhamman on 20-10-2024
             Reason - we have to change the design of heading*/}
            {/* <p className={ProductDetailStyle.CategoryName}>
              Category:{selectedProductDetails.category}
            </p> */}
            <div className={ProductDetailStyle.categoryContainer}>
              <p className={ProductDetailStyle.CategoryName}>Category:</p>
              <p className={ProductDetailStyle.CategoryType}>
                {selectedProductDetails.category}
              </p>
            </div>
            {/* End of modification by jhamman on 20-10-2024
             Reason - we have to change the design of heading*/}
            {/**Code commented by Unnati on 06-10-2024
             *Reason-To remove brand  */}
            {/* <p className={ProductDetailStyle.BrandName}>
              Brand:{selectedProductDetails.brand}
            </p> */}
            {/*End of code comment by Unnati on 06-10-2024
             *Reason-To remove brand  */}
            {/**End of code addition by Unnati on 12-09-2024
             *Reason-Added category and brand */}
            {/*<div className={ProductDetailStyle.title}>
              <p className={ProductDetailStyle.productPrice}>
                ${selectedProductDetails.sales_rate}
              </p>
            </div> */}
            {/* Modified by Jhamman on 06-10-2024
            Reason- calculate sale price */}
            <div className={ProductDetailStyle.title}>
              {selectedProductDetails.sale_percentage ? (
                <div className={ProductDetailStyle.productPrice}>
                  $
                  {calculateDiscountFromProduct(
                    selectedProductDetails.sales_rate,
                    selectedProductDetails.sale_percentage
                  )}
                </div>
              ) : (
                <div className={ProductDetailStyle.productPrice}>
                  ${selectedProductDetails.sales_rate}
                </div>
              )}
              {selectedProductDetails.sale_percentage ? (
                <div className={`${ProductDetailStyle.cardPrice}`}>
                  <p
                    className={ProductDetailStyle.mrpPriceText}
                    style={{
                      textDecoration: "line-through",
                      textDecorationColor: "#000",
                      color: "red",
                    }}
                  >
                    ${selectedProductDetails.sales_rate}
                  </p>
                </div>
              ) : null}
            </div>
            {/* Added by jhamman on 14-10-2024
              Reason - Added offer percentage*/}
            {selectedProductDetails.sale_percentage ? (
              <div className={ProductDetailStyle.productOfferContainer}>
                <p className={ProductDetailStyle.productOfferPercentage}>
                  {selectedProductDetails.sale_percentage}% off
                </p>
              </div>
            ) : null}
            {/* End of addition by jhamman on 14-10-2024
              Reason - Added offer percentage*/}
            {/* End of modification by Jhamman on 06-10-2024
                      Reason- calculate sale price */}
            <div className={ProductDetailStyle.productOptions}>
              <div className={ProductDetailStyle.productColors}>
                {/**Code added by Unnati on 23-05-2024
                 *Reason-To map product to according to its color*/}
                {uniqueColors.map((color) => (
                  <div
                    key={color}
                    className={`${ProductDetailStyle.colorOption} ${
                      selectedColor === color
                        ? ProductDetailStyle.activeColorOption
                        : ""
                    }`}
                    style={{ backgroundColor: color }}
                    onClick={() => handleColorSelect(color)}
                  ></div>
                ))}
                {/**End of code addition by Unnati on 23-05-2024
                 *Reason-To map product to according to its color*/}
              </div>
              {colorError && (
                <p className={ProductDetailStyle.formInputError}>
                  {colorError}
                </p>
              )}
              {sizeError && (
                <p className={ProductDetailStyle.formInputError}>{sizeError}</p>
              )}
              <div className={ProductDetailStyle.productSizes}>
                <div className={ProductDetailStyle.sizeMessage}>
                  <label htmlFor="sizeBoxes">Size</label>

                  <p className={ProductDetailStyle.availabilityMessage}>
                    {availabilityMessage}
                  </p>
                </div>

                {/**Code added by Unnati on 28-08-2024
                 * Reason-Changed the UI for size display
                 */}
                <div className={ProductDetailStyle.sizeBoxes}>
                  {["XS", "S", "M", "L", "XL", "XXL", "XXXL"].map((size) => (
                    <div
                      key={size}
                      className={`${ProductDetailStyle.sizeBox} ${
                        selectedSize === size
                          ? ProductDetailStyle.selectedSizeBox
                          : ""
                      } ${
                        !isSizeAvailable(size)
                          ? ProductDetailStyle.unavailableSizeBox
                          : ""
                      }`}
                      onClick={() =>
                        isSizeAvailable(size) && handleSelectChange(size)
                      }
                    >
                      {size}
                    </div>
                  ))}
                </div>
                {/**End of code addition by Unnati on 28-08-2024
                 * Reason-Changed the UI for size display
                 */}
                {/* Added by - Ashlekh on 05-10-2024
                 Reason - To add size chart */}
                <div
                  className={`${ProductDetailStyle.sizeChartBox}`}
                  onClick={showSizeChart}
                >
                  Size chart
                </div>
                <Modal
                  title=""
                  // visible={sizeChartVisible}
                  open={sizeChartVisible}
                  onCancel={handleCancel}
                  footer={null}
                  className={`${ProductDetailStyle.sizeChartModal}`}
                >
                  <img
                    className={`${ProductDetailStyle.sizeChartImage}`}
                    src={config.baseURL + sizeChart}
                    alt=""
                  />
                </Modal>
                {/* End of code - Ashlekh on 05-10-2024
                 Reason - To add size chart */}
              </div>

              {/**Code added by Unnati on 12-09-2024
               *Reason-Modified the sequence */}
              <div className={ProductDetailStyle.quantityContainer}>
                <div className={ProductDetailStyle.quantity}>
                  <label>Qty</label>
                </div>
                <div
                  className={ProductDetailStyle.quantityInput}
                  onClick={QuantityMessage}
                >
                  <p
                    className={ProductDetailStyle.quantityButton}
                    onClick={handleDecrement}
                  >
                    -
                  </p>
                  <input
                    type="number"
                    inputMode="numeric"
                    className={ProductDetailStyle.quantityNumber}
                    value={quantity}
                    onChange={handleQuantityChange}
                    onPaste={(e) => e.preventDefault()}
                    onCopy={(e) => e.preventDefault()}
                    // Added by - Ashlekh on 27-11-2024
                    // Reason - To prevent '-'/'e'/'+' from quantity
                    onKeyDown={(e) => {
                      if (e.key == "+" || e.key == "e" || e.key == "-") {
                        e.preventDefault();
                      }
                    }}
                    // End of code - Ashlekh on 27-11-2024
                    // Reason - To prevent '-'/'e'/'+' from quantity
                  />
                  <p
                    className={ProductDetailStyle.quantityButton}
                    onClick={handleIncrement}
                  >
                    +
                  </p>
                </div>
              </div>
              {/**Code added by Unnati on 25-08-2024
               * Reason-To display quantity message
               */}
              <p className={ProductDetailStyle.availabilityMessage}>
                {quantityMessage}
              </p>
              {/**End of code addition by Unnati on 25-08-2024
               * Reason-To display quantity message
               */}
              {/**Code added by Unnati on 12-09-2024
               *Reason-To add shipping days*/}
              {selectedProductDetails.shipping_days &&
                selectedProductDetails.shipping_days.length > 0 && (
                  <div className={ProductDetailStyle.shippingDays}>
                    <div>
                      <FaShippingFast />
                    </div>
                    <div>
                      Get it within {selectedProductDetails.shipping_days} days
                    </div>
                  </div>
                )}

              {/**End of code addition by Unnati on 12-09-2024
               *Reason-To add shipping days*/}
              {/**End of code addition by Unnati on 12-09-2024
               *Reason-Modified the sequence */}

              {/* Added by jhamman on 14-10-2024
               Reason - added a condition to render patches */}
              {selectedProductDetails?.show_patches_and_embroider_on_UI ? (
                <>
                  {/* Modification and addition by Om Shrivastava on 18-11-2024
                 Reason : Show the customization text and show the popup */}
                  {/* <div className={ProductDetailStyle.productSizes}>
                 
                    <label>Do you want to Add Patches?</label>
                    
                    <select
                      className={ProductDetailStyle.sizeDropdown}
                      value={patchSelection}
                      onChange={(e) => setPatchSelection(e.target.value)}
                    >
                     
                      <option value={"No"}>No</option>
                      <option value={"Yes"}>Yes</option>
                    </select>
                  </div>
                  <div className={ProductDetailStyle.productSizes}>
                 
                    <label>Do you want Embroider Text?</label>
                   
                    <select
                      className={ProductDetailStyle.sizeDropdown}
                      value={embroiderSelection}
                     
                      onChange={(e) => setEmbroiderSelection(e.target.value)}
                      
                    >
                     
                      <option value={"No"}>No</option>
                      <option value={"Yes"}>Yes</option>
                    </select>
                  </div> */}
                  <>
                    <span
                      className={`${ProductDetailStyle.sizeChartBox}`}
                      onClick={openPopup}
                    >
                      Customization
                    </span>
                    <p
                      style={{ paddingTop: "1%" }}
                      className={ProductDetailStyle.productDescription}
                    >
                      <span style={{ color: "red" }}>*</span> On Customization
                      price will be updated and updated price will be reflected
                      in cart
                    </p>

                    {isPopupOpen && (
                      <div
                        onClick={(e) => {
                          if (e.target === e.currentTarget) closePopup();
                        }}
                        className={ProductDetailStyle.popupOverlay}
                      >
                        <div className={ProductDetailStyle.popupContent}>
                          <button
                            className={ProductDetailStyle.closeButton}
                            onClick={closePopup}
                          >
                            <FaWindowClose />
                          </button>
                          <h2>Customization Options</h2>
                          <form>
                            <div className={ProductDetailStyle.checkboxGroup}>
                              {selectedProductDetails?.logo_price ? (
                                <label>
                                  <input
                                    type="checkbox"
                                    name="logo"
                                    checked={formData.logo}
                                    onChange={handleChange}
                                  />{" "}
                                  {/* Modification and addition by Om Shrivastava on 28-11-2024
                                Reason : Change the name  */}
                                  Logo + $
                                  {/* End of modification and addition by Om Shrivastava on 28-11-2024
                                Reason : Change the name  */}
                                  {selectedProductDetails?.logo_price}
                                </label>
                              ) : null}
                              {selectedProductDetails?.patches_price ? (
                                <label>
                                  <input
                                    type="checkbox"
                                    name="patches"
                                    checked={formData.patches}
                                    onChange={handleChange}
                                  />{" "}
                                  {/* Modification and addition by Om Shrivastava on 28-11-2024
                                Reason : Change the name  */}
                                  Patches / Batches + $
                                  {/* Modification and addition by Om Shrivastava on 28-11-2024
                                Reason : Change the name  */}
                                  {selectedProductDetails?.patches_price}
                                </label>
                              ) : null}
                              {selectedProductDetails?.security_batches_price ? (
                                <label>
                                  <input
                                    type="checkbox"
                                    name="security_batches"
                                    checked={formData.security_batches}
                                    onChange={handleChange}
                                  />{" "}
                                  {/* Modification and addition by Om Shrivastava on 28-11-2024
                                Reason : Change the name  */}
                                  Security id + $
                                  {/* Modification and addition by Om Shrivastava on 28-11-2024
                                Reason : Change the name  */}
                                  {
                                    selectedProductDetails?.security_batches_price
                                  }
                                </label>
                              ) : null}
                              {selectedProductDetails?.embroider_price ? (
                                <label>
                                  <input
                                    type="checkbox"
                                    name="embroider"
                                    checked={formData.embroider}
                                    onChange={handleChange}
                                  />{" "}
                                  {/* Modification and addition by Om Shrivastava on 28-11-2024
                                Reason : Change the name  */}
                                  Embroider / Name + $
                                  {/* Modification and addition by Om Shrivastava on 28-11-2024
                                Reason : Change the name  */}
                                  {selectedProductDetails?.embroider_price}
                                </label>
                              ) : null}
                            </div>
                            {validationMessage && (
                              <div style={{ color: "red", marginBottom: "10px" }}>{validationMessage}</div>
                            )}
                            <div className={ProductDetailStyle.commentBox}>
                              <textarea
                                name="customization_comment"
                                placeholder="Comment"
                                className={ProductDetailStyle.commentInput}
                                value={formData.customization_comment}
                                onChange={handleChange}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <button
                                type="button"
                                onClick={handleCustomizationSubmit}
                                className={ProductDetailStyle.submitButton}
                              >
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    )}
                  </>
                  {/* End of modification and addition by Om Shrivastava on 18-11-2024
                 Reason : Show the customization text and show the popup */}
                </>
              ) : null}
            </div>
            <div className={ProductDetailStyle.addToCart}>
              <button
                className={ProductDetailStyle.addToCartButton}
                onClick={handleSubmit}
              >
                Add to Cart
              </button>
              {/* End of code - Ashlekh on 30-10-2024
            Reason - To add WishList button */}
              {/* Added by - Ashlekh on 20-11-2024
                    Reason - To display wishlist icon */}
              {/* Added by - Ashlekh on 28-11-2024
                    Reason - To hide wishlist icon for guest user */}
              {user.id != undefined && (
                // End of code - Ashlekh on 28-11-2024
                // Reason - To hide wishlist icon for guest user
                <div className={`${ProductDetailStyle.wishListIconContainer}`}>
                  {isWishListed ? (
                    <MdFavorite
                      onClick={() =>
                        handleToggleWishList(user, selectedProductDetails)
                      }
                      className={`${ProductDetailStyle.wishListIcon1}`}
                      title="Remove From WishList"
                    />
                  ) : (
                    <MdFavoriteBorder
                      onClick={() =>
                        handleToggleWishList(user, selectedProductDetails)
                      }
                      className={`${ProductDetailStyle.wishListIcon2}`}
                      title="Add to WishList"
                    />
                  )}
                </div>
              )}
              {/* End of code - Ashlekh on 20-11-2024
                    Reason - To display wishlist icon */}
            </div>
            {/* Added by - Ashlekh on 30-10-2024
            Reason - To add WishList button */}
            {/* <div className={ProductDetailStyle.addToWishList}>
              <button
                className={ProductDetailStyle.addToWishListButton}
                onClick={() => handleWishList(user, selectedProductDetails)}
              >
                Add to WishList
              </button>
            </div> */}
            {/* End of code - Ashlekh on 30-10-2024
            Reason - To add WishList button */}
          </div>
        </div>
        {/**Code added by Unnati on 01-07-2024
         *Reason-To show product description like features,product specifications */}
        {selectedProductDetails.details && (
          <div className={ProductDetailStyle.productInfoDetails}>
            <div className={ProductDetailStyle.subHeadingContainer}>
              <h2 className={ProductDetailStyle.subHeading}>Details</h2>
              <div className={ProductDetailStyle.borderDescription}>
                <div className={ProductDetailStyle.description}>
                  {parse(selectedProductDetails.details)}
                </div>
              </div>
            </div>
          </div>
        )}
        {/**End of code addition by Unnati on 01-07-2024
         *Reason-To show product description like features,product specifications */}
        {/* Added by - Ashlekh on 05-10-2024
         Reason - To display similar product */}
        <div className={`${ProductDetailStyle.similarProductContainer}`}>
          {/* Added by - Ashlekh on 07-10-2024
          Reason - To add Similar Product heading */}
          {similarProduct.length > 0 ? (
            <h2 className={ProductDetailStyle.similarProductSubHeading}>
              Similar Product
            </h2>
          ) : (
            <div></div>
          )}
          {/* End of code - Ashlekh on 07-10-2024
          Reason - To add Similar Product heading */}
          <div className={`${ProductDetailStyle.swiperContainer}`}>
            <Swiper
              slidesPerView={4}
              spaceBetween={50}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination, Navigation]}
              // autoplay={{
              //   delay: 1000,
              //   disableOnInteraction: false,
              // }}
              navigation={true}
              // className="mySwiper"
              className={`${ProductDetailStyle.similarProductSwiperContainer}`}
              // Added by - Ashlekh on 27-11-2024
              // Reason - To add breakpoints of swiper for mobile view
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                721: {
                  slidesPerView: 4,
                  spaceBetween: 50,
                },
              }}
              // End of code - Ashlekh on 27-11-2024
              // Reason - To add breakpoints of swiper for mobile view
            >
              {similarProduct?.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div
                      key={item.id}
                      className={`${ProductDetailStyle.gridCard}`}
                    >
                      <Link
                        to={`/productdetail/${item.product_id}`}
                        className={`${ProductDetailStyle.similarProductSubContainer}`}
                        // Added by - Ashlekh on 07-10-2024
                        // Reason - To scroll on top
                        // onClick={() => window.scrollTo(0, 0)}
                        onClick={() =>
                          window.scrollTo({ top: 0, behavior: "smooth" })
                        }
                        // End of code - Ashlekh on 07-10-2024
                        // Reason - To scroll on top
                      >
                        {/* Commented by jhamman on 14-10-2024
                    Reason - Added full detail below*/}
                        {/* <img
                        src={config.baseURL + item.image1}
                        className={`${ProductDetailStyle.similarProductImage}`}
                        alt=""
                      />
                      <div
                        className={`${ProductDetailStyle.similarProductName}`}>
                        {item.name}
                      </div>
                      <div
                        className={`${ProductDetailStyle.similarProductDescription}`}>
                        {item.description
                          ? item.description.substring(0, 50) + "..."
                          : ""}
                      </div> */}
                        {/* End of commentation by jhamman on 14-10-2024
                    Reason - Added full detail below*/}

                        {/* Added by jhamman on 14-10-2024
                        Reason - added card to display similer product*/}
                        <div
                          className={`${ProductDetailStyle.gridImageContainer}`}
                        >
                          <div
                            className={`${ProductDetailStyle.imageAndOfferLogoContainer}`}
                          >
                            {item.sale_percentage ? (
                              <div
                                className={ProductDetailStyle.offerContainer}
                              >
                                <p
                                  className={ProductDetailStyle.offerPercentage}
                                >
                                  {item.sale_percentage}% off
                                </p>
                              </div>
                            ) : null}
                          </div>
                          <img
                            src={`${config.baseURL}${item.image1}`}
                            alt={item.name}
                            className={`${ProductDetailStyle.gridImage}`}
                          />
                          <button
                            className={`${ProductDetailStyle.similerProductAddToCartButton}`}
                          >
                            Add to Cart
                          </button>
                        </div>
                        <div className={`${ProductDetailStyle.gridContent}`}>
                          <h3 className={`${ProductDetailStyle.brandName}`}>
                            {item.name.length > 50
                              ? `${item.name.substring(0, 50)}...`
                              : item.name}
                          </h3>
                          {/* Code commented by - Ashlekh on 28-11-2024
                          Reason - To remove description */}
                          {/* <h2 className={`${ProductDetailStyle.cardTitle}`}>
                            {item.description.length > 50
                              ? `${item.description.substring(0, 50)}...`
                              : item.description}
                          </h2> */}
                          {/* End of comment - Ashlekh on 28-11-2024
                          Reason - To remove description */}
                          {/* Added by - Ashlekh on 28-11-2024
                          Reason - To display rating */}
                          <div
                            className={
                              ProductDetailStyle.productDetailContainer
                            }
                          >
                            {item.rating > 0 && <Rating value={item.rating} />}
                          </div>
                          {/* End of code - Ashlekh on 28-11-2024
                          Reason - To display rating */}
                          <div className={ProductDetailStyle.priceContainer}>
                            {item.sale_percentage ? (
                              <div
                                className={
                                  ProductDetailStyle.similerProductCardPrice
                                }
                              >
                                <p
                                  className={
                                    ProductDetailStyle.similarProductDiscountedPriceText
                                  }
                                >
                                  $
                                  {calculateDiscountFromProduct(
                                    item.sales_rate,
                                    item.sale_percentage
                                  )}
                                </p>
                              </div>
                            ) : null}
                            <div
                              className={`${ProductDetailStyle.similerProductCardPrice}`}
                            >
                              <p
                                className={
                                  ProductDetailStyle.similerProductMrpPriceText
                                }
                                style={
                                  item.sale_percentage
                                    ? {
                                        textDecoration: "line-through",
                                        // Code changed by - Ashlekh on 28-11-2024
                                        // Reason - To change color
                                        // textDecorationColor: "#000",
                                        // color: "red",
                                        textDecorationColor: "#888888",
                                        color: "#888888",
                                        // End of code - Ashlekh on 28-11-2024
                                        // Reason - To change color
                                      }
                                    : {
                                        // Code changed by - Ashlekh on 28-11-2024
                                        // Reason - To change color
                                        // color: "green"
                                        color: "red",
                                        // End of code - Ashlekh on 28-11-2024
                                        // Reason - To change color
                                      }
                                }
                              >
                                ${item.sales_rate}
                              </p>
                            </div>
                          </div>
                          {/**Code added by Unnati on 17-10-2024
                           *Reason-Added star rating */}
                          {/* Commented by - Ashlekh on 28-11-2024
                           Reason - To remove rating  */}
                          {/* <div
                            className={
                              ProductDetailStyle.productDetailContainer
                            }
                          >
                            {item.rating > 0 && (
                              <Rating value={item.rating} />
                            )}
                          </div> */}
                          {/**Code commented by Unnati on 18-10-2024
                           *Reason-Commented star component*/
                          /* <ReactStars
                                  className={CategoryStyle.star}
                                  count={5}
                                  value={product.rating}
                                  size={12}
                                  color2={"#ffd700"}
                                  edit={false}
                                /> */
                          /**End of code comment by Unnati on 18-10-2024
                           *Reason-Commented star component*/
                          /**Code added by Unnati on 18-10-2024
                           *Reason-Calling component*/}
                          {/**End of code addition by Unnati on 18-10-2024
                           *Reason-Calling component*/}
                          {/**End of code addition by Unnati on 17-10-2024
                           *Reason-Added star rating */}
                          {/* End of comment - Ashlekh on 28-11-2024
                          Reason - To remove rating */}
                        </div>
                      </Link>
                      {/* End of addition by jhamman on 14-10-2024
                        Reason - added card to display similer product*/}
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
        {/* End of code - Ashlekh on 05-10-2024
         Reason - To display similar product */}
      </div>
    </div>
  );
};

export default ProductDetail;
/**End of code addition by Unnati on 23-06-2024
 * Reason-To have Product detail page
 */
