import React from 'react'
import helpStyle from "./Help.module.css";

const Help = () => {
  return (
    <div>
      <div className={`${helpStyle.pageFrame}`}>
      <div className={`${helpStyle.pageContainer}`}>
      help
        </div>
      </div>
    </div>
  )
}

export default Help
